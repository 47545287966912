import SharedFieldTypes from 'norbr-shared-lib/constants/order/fields/fieldTypes';
import { fieldTypes } from '../../Brainpower/Common/constants/fields';
import { ModeInternal, ModeProgramManager } from '../../../../constants/mode';
import FieldTypes from '../../Brainpower/Common/constants/fieldTypes';
import { merchantAccountStatuses, merchantAccountStatusList } from './constants';

export const Fields = {
  PROGRAM_MANAGER: 'program_manager',
  PROGRAM_MANAGER_NAME: 'program_manager_name',
  COMPANY: 'company',
  COMPANY_NAME: 'company_name',
  MERCHANT_ACCOUNT: 'merchant_account',
  MERCHANT_ACCOUNT_NAME: 'merchant_account_name',
  CMS_NAME: 'cms_name',
  CREATION_DATE: 'creation_date',
  MERCHANT_CONTRACT_COUNT: 'merchant_contract_count',
  REFERENCE_CURRENCY: 'reference_currency',
  INTEGRATION_TYPE: 'integration_type',
  SOURCE: 'source',
  STATUS: 'status',
  USER_COUNT: 'user_count',
};

const fields = {
  ...(ModeInternal
    ? {
        [Fields.PROGRAM_MANAGER]: {
          key: Fields.PROGRAM_MANAGER,
          label: 'Program manager',
          type: SharedFieldTypes.PROGRAM_MANAGER,
          entity: 'merchant_account',
          path: 'programManager.id',
          description: 'The program manager of the merchant account.',
          sortField: Fields.PROGRAM_MANAGER_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.PROGRAM_MANAGER],
        },
        [Fields.PROGRAM_MANAGER_NAME]: {
          key: Fields.PROGRAM_MANAGER_NAME,
          label: 'Program manager name',
          type: SharedFieldTypes.TEXT,
          entity: 'merchant_account',
          path: 'programManager.name',
          description: 'The name of the program manager.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  ...(ModeInternal || ModeProgramManager
    ? {
        [Fields.COMPANY]: {
          key: Fields.COMPANY,
          label: 'Company',
          type: SharedFieldTypes.COMPANY,
          entity: 'merchant_account',
          path: 'company.id',
          description: 'The company of the merchant account.',
          sortField: Fields.COMPANY_NAME,
          filter: true,
          ...fieldTypes[SharedFieldTypes.COMPANY],
        },
        [Fields.COMPANY_NAME]: {
          key: Fields.COMPANY_NAME,
          label: 'Company name',
          type: SharedFieldTypes.TEXT,
          entity: 'merchant_account',
          path: 'company.name',
          description: 'The name of the company.',
          filter: false,
          ...fieldTypes[SharedFieldTypes.TEXT],
        },
      }
    : {}),
  [Fields.MERCHANT_ACCOUNT]: {
    key: Fields.MERCHANT_ACCOUNT,
    label: 'Merchant account',
    type: SharedFieldTypes.MERCHANT_ACCOUNT,
    entity: 'merchant_account',
    path: 'id',
    description: 'The name of the merchant account.',
    sortField: Fields.MERCHANT_ACCOUNT_NAME,
    filter: true,
    ...fieldTypes[SharedFieldTypes.MERCHANT_ACCOUNT],
  },
  [Fields.MERCHANT_ACCOUNT_NAME]: {
    key: Fields.MERCHANT_ACCOUNT_NAME,
    label: 'Merchant account name',
    type: SharedFieldTypes.TEXT,
    entity: 'merchant_account',
    path: 'name',
    description: 'The name of the merchant account.',
    filter: false,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.CMS_NAME]: {
    key: Fields.CMS_NAME,
    label: 'CMS',
    type: SharedFieldTypes.TEXT,
    entity: 'merchant_account',
    path: 'cms_name',
    description: 'The CMS (Content Management System) of the merchant account.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.TEXT],
  },
  [Fields.CREATION_DATE]: {
    key: Fields.CREATION_DATE,
    label: 'Creation date',
    type: SharedFieldTypes.DATE,
    entity: 'merchant_account',
    path: 'createdAt',
    description: 'The creation date of the merchant account.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.DATE],
  },
  [Fields.REFERENCE_CURRENCY]: {
    key: Fields.REFERENCE_CURRENCY,
    label: 'Currency',
    type: SharedFieldTypes.CURRENCY,
    entity: 'merchant_account',
    path: 'reference_currency',
    description: 'The reference currency of the merchant account.',
    filter: false, // Not filterable because only one available reference currency for now
    ...fieldTypes[SharedFieldTypes.CURRENCY],
  },
  [Fields.SOURCE]: {
    key: Fields.SOURCE,
    label: 'Source',
    type: SharedFieldTypes.SOURCE,
    entity: 'merchant_account',
    path: 'source',
    description: 'The source of the order (stack / insights).',
    filter: true,
    ...fieldTypes[SharedFieldTypes.SOURCE],
  },
  [Fields.USER_COUNT]: {
    key: Fields.USER_COUNT,
    label: 'User count',
    type: SharedFieldTypes.COUNT,
    entity: 'merchant_account',
    path: 'userCount',
    description: 'The count of users authorized on the merchant account.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.COUNT],
  },
  [Fields.INTEGRATION_TYPE]: {
    key: Fields.INTEGRATION_TYPE,
    label: 'Integration type',
    type: SharedFieldTypes.INTEGRATION_TYPE,
    entity: 'merchant_account',
    path: 'integration_type',
    description: 'The type of integration on the merchant account.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.INTEGRATION_TYPE],
  },
  [Fields.MERCHANT_CONTRACT_COUNT]: {
    key: Fields.MERCHANT_CONTRACT_COUNT,
    label: 'Contract count',
    type: SharedFieldTypes.COUNT,
    entity: 'merchant_account',
    path: 'merchantContractCount',
    description: 'The count of contracts on the merchant account.',
    filter: true,
    ...fieldTypes[SharedFieldTypes.COUNT],
  },
  [Fields.STATUS]: {
    key: Fields.STATUS,
    label: 'Status',
    entity: 'merchant_account',
    path: 'status',
    description: 'The status of the merchant account.',
    filter: true,
    type: FieldTypes.LIST,
    filterOptions: {
      options: merchantAccountStatusList,
      defaultValue: [],
    },
    displayOptions: {
      getOption: (value) => merchantAccountStatuses[value],
      tag: true,
    },
  },
};
export default fields;
