import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AndMe from './AndMe/AndMe';
import Brainpower from './Brainpower/Brainpower';
import RestrictedPage from './RestrictedPage';
import Teams from './Teams/Teams';
import { isModeAdmin } from '../../constants/mode';
import LogList from './LogList/LogList';
import Sso from './Sso/Sso';

let Admin;
if (process.env.REACT_APP_MODE === 'admin') {
  Admin = require('./Admin/Admin').default;
}

const _ = () => (
  <>
    {isModeAdmin ? (
      <Admin />
    ) : (
      <Routes>
        <Route
          path="brainpower/*"
          element={
            <RestrictedPage action="read" subject="brainpower">
              <Brainpower />
            </RestrictedPage>
          }
        />
        <Route path="router/*" element={<AndMe />} />
        <Route
          path="teams/*"
          element={
            <RestrictedPage action="read" subject="user">
              <Teams />
            </RestrictedPage>
          }
        />
        <Route
          path="sso/*"
          element={
            <RestrictedPage action="read" subject="company-login">
              <Sso />
            </RestrictedPage>
          }
        />
        <Route
          path="logs/*"
          element={
            <RestrictedPage action="read" subject="log">
              <LogList />
            </RestrictedPage>
          }
        />
        <Route path="*" element={<Navigate to="brainpower" />} />
      </Routes>
    )}
  </>
);

export default _;
