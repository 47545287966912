import {gql} from '@apollo/client';

export const registersQuery = gql`
    query Registers($offset: Int, $limit: Int, $filters: [MatcherSearchFilter], $sort: [MatcherSort]) {
        registers(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
            data {
                _id {
                    matcher_order_date
                    merchantAccount
                }
                matcher_order_date
                matcher_store
                matcher_program_manager
                matcher_company
                matcher_notification_date
                matcher_matched_transactions
                matcher_unmatched_transactions
                matcher_unclosable_transactions
                matcher_register_status
                matcher_matched_amount
                matcher_unmatched_amount
                matcher_amount_gap
                matcher_total_transactions
                matcher_total_amount
                matcher_cashier
                currency
                matcher_orders
                matcher_matched_orders
                matcher_unmatched_orders
            }
            total
        }
    }
`
export const registerDetailsQuery = gql`
    query RegisterDetails($date: String!, $merchantAccount: ID!, $sort: [MatcherSort]) {
        register(date: $date, merchantAccount: $merchantAccount, sort: $sort) {
            _id {
                matcher_order_date
                merchantAccount
            }
            details {
                matcher_order_date
                matcher_store
                matcher_notification_date
                matcher_matched_transactions
                matcher_unmatched_transactions
                matcher_matched_amount
                matcher_unmatched_amount
                matcher_amount_gap
                matcher_total_transactions
                matcher_total_amount
                matcher_reconciliation_type
                matcher_withdraw_reason
                matcher_reason
                currency
            }
        }
    }
`;

export const matcherOverviewQuery = gql`
    query MatcherOverviewQuery($dimensions: [MatcherDimension], $filters: [MatcherSearchFilter], $sort: [MatcherSort], $limit: Int, $offset: Int) {
        matcherOverview(dimensions: $dimensions, filters: $filters, sort: $sort, limit: $limit, offset: $offset)
    }  
`;

export const closeRegisterMutation = gql`
    mutation CloseRegisterMutation($date: String!, $merchantAccount: ID!) {
        closeRegister(date: $date, merchantAccount: $merchantAccount)
    }  
`;