import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Layout, message, PageHeader, Row, Space, Typography } from 'antd';
import { PauseCircleFilled, PlayCircleFilled, RedoOutlined, WarningFilled } from '@ant-design/icons';
import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import PencilBoxIcon from '@2fd/ant-design-icons/lib/PencilBox';
import moment from 'moment-timezone';
import { DATETIME2_FORMAT } from '../../../../constants/DATES';
import { useMessages } from '../../../../contexts/messages/messages.context';
import { useLayoutMenu } from '../../../layout.context';
import useNavigateWithSearch, { Link } from '../../../../util/navigate';
import { formatEndUnderline } from '../Common/utils';
import { redirectToEntityList } from '../ExportList/utils';
import ExportStatuses from '../ExportList/constants/exportStatuses';
import { DrawerContext } from '../Common/useDrawer';
import brainpowerStyles from '../Brainpower.module.scss';
import HeaderButton from '../Common/HeaderButton/HeaderButton';
import styles from './ExportDetail.module.scss';
import ReportTable from './ReportTable/ReportTable';
import ConfigurationDrawer from './ConfigurationDrawer/ConfigurationDrawer';
import { exportQuery, pauseExportMutation, runExportMutation, unpauseExportMutation } from './query';
import TargetEntities from '../Common/constants/targetEntities';

const ExportDetail = () => {
  const navigate = useNavigateWithSearch();

  const { exportId } = useParams();
  const { ack, allMessages } = useMessages();

  const { data } = useQuery(exportQuery, { variables: { exportId } });

  // If the export is in the new messages, set it as read
  useEffect(() => {
    const messageElem = allMessages.find(
      (mess) => mess.content.exportId === exportId && mess.type === 'report_completed',
    );
    if (messageElem) {
      ack([messageElem.id]);
    }
  }, [exportId]);

  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  const [drawerState, setDrawerState] = useState();
  const handleToggleDrawer = (key) => () => setDrawerState(drawerState !== key ? key : undefined);
  const handleCloseDrawer = () => setDrawerState(undefined);

  const [runExport, runExportState] = useMutation(runExportMutation, {
    variables: { exportId },
    onCompleted: (res) => {
      if (!res.runExport) return;
      message.loading(
        <Typography.Text>
          Export <strong>{res.runExport.name}</strong> is running.
        </Typography.Text>,
      );
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const [pauseExport, pauseExportState] = useMutation(pauseExportMutation, {
    variables: { exportId },
    onCompleted: (res) => {
      message.success(
        <Typography.Text>
          Export <strong>{res.pauseExport.name}</strong> is paused.
        </Typography.Text>,
      );
    },
  });

  const [unpauseExport, unpauseExportState] = useMutation(unpauseExportMutation, {
    variables: { exportId },
    onCompleted: (res) => {
      message.success(
        <Typography.Text>
          Export <strong>{res.unpauseExport.name}</strong> is resumed.
        </Typography.Text>,
      );
    },
  });

  if (!data) return null;

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <Layout className={brainpowerStyles.root}>
        <PageHeader
          className={brainpowerStyles.pageHeader}
          backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
          onBack={toggleMenu}
          title={
            <Space>
              <InboxArrowDownIcon />
              <Link style={{ color: 'inherit' }} to="/brainpower/exports">
                {formatEndUnderline('Exports')}
              </Link>
              /{data ? data.export.name ?? moment(data.export.createdAt).format(DATETIME2_FORMAT) : '...'}
            </Space>
          }
          extra={[
            <HeaderButton
              drawerState={drawerState}
              key="configuration"
              id="configuration"
              label="CONFIGURATION"
              icon={PencilBoxIcon}
              onClick={handleToggleDrawer('configuration')}
            />,
          ]}
        />
        <Layout>
          {data.export.status === ExportStatuses.PAUSED && (
            <Alert
              banner
              icon={<WarningFilled />}
              message={`The export ${data.export.name} is currently paused.`}
              style={{ fontSize: 12 }}
            />
          )}
          {data.export.scheduleCron?.failCount > 0 && (
            <Alert
              banner
              type="error"
              message="A report generation has failed, please contact support."
              style={{ fontSize: 12 }}
              closable
            />
          )}
          <Layout.Header className={styles.layoutHeader}>
            <Row gutter={24}>
              <Col flex="1" className={styles.ellipsis}>
                <span>{data.export.description}</span>
              </Col>
              <Col>
                <Space>
                  <Button
                    className={styles.actionButton}
                    icon={<RedoOutlined />}
                    onClick={() => runExport()}
                    loading={runExportState.loading}
                  >
                    GENERATE REPORT
                  </Button>
                  <Button
                    className={styles.actionButton}
                    icon={<ShoppingIcon />}
                    onClick={() => redirectToEntityList(navigate, data.export)}
                  >
                    SHOW IN&nbsp;
                    {TargetEntities[data.export?.view?.entity]?.label.toUpperCase()}{TargetEntities[data.export?.view?.entity]?.label !== 'Finance' && 'S'}
                  </Button>
                  {data.export.schedule && data.export.status !== ExportStatuses.PAUSED && (
                    <Button
                      className={styles.actionButton}
                      icon={<PauseCircleFilled />}
                      onClick={() => pauseExport()}
                      loading={pauseExportState.loading}
                    >
                      PAUSE
                    </Button>
                  )}
                  {data.export.schedule && data.export.status === ExportStatuses.PAUSED && (
                    <Button
                      className={styles.primaryActionButton}
                      icon={<PlayCircleFilled />}
                      onClick={() => unpauseExport()}
                      loading={unpauseExportState.loading}
                      type="primary"
                    >
                      RESUME
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          </Layout.Header>
          <ReportTable />
        </Layout>
        <ConfigurationDrawer
          open={drawerState === 'configuration'}
          onClose={handleCloseDrawer}
          containerId="report-table"
        />
      </Layout>
    </DrawerContext.Provider>
  );
};

export default ExportDetail;
