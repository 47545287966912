import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as JSURL from 'jsurl';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { useMe } from '../../../../contexts/me.context';
import fields, { checkFieldMode } from '../Common/constants/fields';
import { updatePaginationInSearchParams } from './usePagination';
import usePage from './usePage';
import { PageType } from '../Common/constants/pageTypes';
import useNavigateWithSearch from '../../../../util/navigate';
import { DATETIME_FORMAT } from '../../../../constants/DATES';
import periods from '../../../../constants/periods';
import { MatcherCashierFields } from '../Matcher/MatcherCashier/fields';
import MerchantAccountFields from '../../Admin/MerchantAccounts/fields';
import { useCan } from '../../../../contexts/ability.context';

const period = 'current_month';
const { from, to } = periods()[period];

/**
 * Encode / decode Filter - from object to string
 * TODO - choose which functions to use ( optimize length, readability, ... )
 * @param value
 * @returns {*}
 */
export const encodeFilter = JSURL.stringify;
export const decodeFilter = JSURL.parse;
// export const encodeFilter = (value) => compressToEncodedURIComponent(JSON.stringify(value));
// export const decodeFilter = (value) => (value ? JSON.parse(decompressFromEncodedURIComponent(value)) : {});
// export const encodeFilter = value => JSON.stringify(value);
// export const decodeFilter = value => JSON.parse(value);

/**
 * Update searchFilters in SearchParams (react-router)
 * @param searchParams
 * @param value
 * @param key
 * @returns {Object}
 */
export const updateSearchFiltersInSearchParams = (searchParams, value, key) =>
  searchParams.set(key, encodeFilter(value));

export const useDefaultSearch = () => {
  const me = useMe();
  const page = usePage();
  return {
    dateFilter: {
      key: me.companies[0]?.company?.useCheckout ? Fields.CHECKOUT_CREATION_DATE : Fields.ORDER_CREATION_DATE,
      value: { period, from: from.format(DATETIME_FORMAT), to: to.format(DATETIME_FORMAT) },
    },
    ...page.search.defaultSearch,
  };
};

const useFavoriteSearch = () => {
  const me = useMe();
  const page = usePage();
  const defaultSearch = useDefaultSearch();
  if (
    !me.brainpower?.[page.search.id] || // no search settings
    me.brainpower?.[page.search.id].favorite === page.search.defaultSearch._id // favorite is default
  ) {
    return defaultSearch;
  }
  const { favorite, searches } = me.brainpower[page.search.id];
  return searches.find((s) => s._id === favorite) ?? defaultSearch;
};

const useSearchFilters = () => {
  const page = usePage();
  const navigate = useNavigateWithSearch();
  const can = useCan();

  const { searchFilters = {}, dateFilter } = useFavoriteSearch();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(page.search.key);
  const search = useMemo(
    () =>
      decodeFilter(paramValue) ?? {
        dateFilter,
        searchFilters,
      },
    [paramValue],
  );

  // List of effective searchFilters (remove empty)
  const listAll = useMemo(
    () =>
      Object.keys(search.searchFilters ?? {})
        .reduce((memo, key) => [...memo, { key, ...search.searchFilters[key] }], [])
        .filter(({ key }) => checkFieldMode(key) && !page.fields[key]?.isForbidden?.(can))
        // remove empty filters
        .filter(({ value }) => value !== undefined && value !== null && (!Array.isArray(value) || value.length > 0))
        .filter(
          (filter) =>
            !(page.rawSearch ?? []).some((searchElem) => JSON.stringify(searchElem) === JSON.stringify(filter)),
        ),
    [search],
  );

  // List of effective searchFilters + dateFilter, filtered by current page
  const list = useMemo(
    () => [
      ...(page.rawSearch ?? []),
      ...listAll.filter(({ key }) => page.searchEntities?.includes(page.fields[key]?.entity)),
      ...(search.dateFilter?.key ? [search.dateFilter] : []),
    ],
    [listAll, page],
  );

  const get = useCallback((key) => search.searchFilters[key], [search]);

  const set = useCallback(
    (key, value) => {
      const newSearchParams = new URLSearchParams(searchParams);
      updateSearchFiltersInSearchParams(
        newSearchParams,
        {
          ...search,
          searchFilters: {
            ...search.searchFilters,
            [key]: value,
          },
        },
        page.search.key,
      );
      if ([PageType.LIST, PageType.OVERVIEW].includes(page.type))
        updatePaginationInSearchParams(newSearchParams, 1, null, page.prefix);
      setSearchParams(newSearchParams);
    },
    [search, searchParams, setSearchParams],
  );

  const setAll = useCallback(
    (value) => {
      const newSearchParams = new URLSearchParams(searchParams);
      updateSearchFiltersInSearchParams(newSearchParams, value, page.search.key);
      if ([PageType.LIST, PageType.OVERVIEW].includes(page.type))
        updatePaginationInSearchParams(newSearchParams, 1, null, page.prefix);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const unset = useCallback((key) => set(key, undefined), [set]);

  const unsetAll = useCallback(
    () =>
      setAll({
        dateFilter: search.dateFilter,
        searchFilters: {},
      }),
    [setAll, search],
  );

  useEffect(() => {
    if (paramValue === null)
      setAll({
        dateFilter,
        searchFilters,
      });
  }, []);

  // sfc : search filters collapsed
  const collapsed = decodeFilter(searchParams.get('sfc'));
  const toggleCollapsed = (value = !collapsed) => {
    searchParams.set('sfc', encodeFilter(value));
    setSearchParams(searchParams);
  };

  // To avoid useless renders between the update of searchParams in url and the redirection to another page
  const setAllAndRedirect = (value, path, nextPageKey = page.search.key) => {
    const newSearchParams = new URLSearchParams(searchParams);
    updateSearchFiltersInSearchParams(newSearchParams, value, nextPageKey);
    navigate(path, newSearchParams);
  };

  return {
    dateFilter: search.dateFilter,
    searchFilters: search.searchFilters,
    list,
    listAll,
    get,
    set,
    setAll,
    unset,
    unsetAll,
    collapsed,
    toggleCollapsed,
    setAllAndRedirect,
  };
};

export const useSearchFilter = (key) => {
  const { get, set, unset } = useSearchFilters();
  return {
    value: get(key),
    set: (value) => set(key, value),
    unset: () => unset(key),
  };
};

export default useSearchFilters;
