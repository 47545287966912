import React, { useMemo, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import classnames from 'classnames';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import { FunctionOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import RECONCILIATION_TYPES from 'norbr-shared-lib/constants/transaction/reconciliationTypes';
import RECONCILIATION_REASONS from 'norbr-shared-lib/constants/transaction/reconciliationReasons';
import MATCH_LATER_REASONS from 'norbr-shared-lib/constants/transaction/matchLaterReasons';
import WITHDRAW_REASONS from 'norbr-shared-lib/constants/transaction/withdrawReasons';
import PAYMENT_CHANNELS from 'norbr-shared-lib/constants/paymentChannels';
import moment from 'moment-timezone';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import { useGdpr } from '../../../../../../../contexts/me.context';
import useDrawer from '../../../../Common/useDrawer';
import Drawers from '../../../../Common/constants/drawers';
import usePagination from '../../../../hooks/usePagination';
import SearchFilterTags from '../../SearchFilterTags/SearchFilterTags';
import { buildColumnProps } from '../../../../Common/AdvancedTable/buildColumns';
import useDimensions from '../../../../hooks/useDimensions';
import useSort from '../../../../hooks/useSort';
import { fieldTypes } from '../../../../Common/constants/fields';
import { matcherOverviewQuery } from '../../query';
import { DataRoles, MatcherCashierFields, MatcherCashierFieldsEnum } from '../../fields';
import { amountFormatter } from '../../../../../../../util/formatter';
import usePage from '../../../../hooks/usePage';
import useNavigateWithSearch from '../../../../../../../util/navigate';
import { DATETIME2_FORMAT } from '../../../../../../../constants/DATES';
import { iconByChannel } from '../../../../../../../constants/PAYMENT_CHANNELS';
import useColumnSet from '../../../../hooks/useColumnSet';
import useTableDisplay from '../../../../hooks/useTableDisplay';
import { list as indicatorList } from '../../../../Dashboard/constants/indicators';
import { TableDisplay } from '../../../../Common/constants/tableDisplays';
import styles from './MatcherCashierTable.module.scss';

/**
 * Build AntD Table columns from columnSet and context
 */
const useColumns = () => {
  const page = usePage();
  const [columnSet] = useColumnSet();
  const [sort] = useSort();
  const [tableDisplay] = useTableDisplay();
  const isGdpr = useGdpr();

  return columnSet.map((c, index, { length }) => {
    const sortIndex = sort.findIndex((s) => s.field === c.field);

    const field = {
      ...page.fields[c.field],
      ...fieldTypes[page.fields[c.field].type],
    };
    return {
      key: c.field,
      dataIndex: c.field,
      title: field.dataRole === DataRoles.INDICATOR ? (
        <Space>
          <FunctionOutlined />
          {c.label}
        </Space>
      ) : (
        c.label
      ),
      format: c.format,
      ellipsis: true,
      width: Math.max(c.label.length) * 14 + 30,
      sorter: { multiple: sortIndex === -1 ? length : sortIndex },
      sortOrder: sort[sortIndex]?.sortOrder,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      fixed: index === 0 ? 'left' : false,
      ...buildColumnProps(field, c, isGdpr, tableDisplay === TableDisplay.TEXT),
    };
  });
};

const MatcherCashierDetailsTable = (props) => {
  const { searchFilters, onSearch } = props;
  const currentPage = usePage();
  const navigate = useNavigateWithSearch();

  const [, setDrawer] = useDrawer();

  const [height, setHeight] = useState();

  const [dimensions] = useDimensions();

  const { page, pageSize, setPage } = usePagination();
  const [sort, setSort] = useSort();

  const searchFilterList = useMemo(
    () => Object.keys(searchFilters).reduce((memo, key) => [...memo, { key, value: searchFilters[key] }], []),
    [searchFilters],
  );

  const {
    data: {
      matcherOverview: { data: overviewData, total: overviewTotal } = {
        data: [],
        total: 0,
      },
    } = {
      registers: {},
      matcherOverview: {},
    },
    loading,
    error,
  } = useQuery(matcherOverviewQuery, {
    variables: {
      filters: searchFilterList
        .filter(
          (searchFilter) =>
            searchFilter.value &&
            MatcherCashierFields[searchFilter.key]?.filterOptions?.pages?.includes(currentPage.name),
        )
        .map((searchFilter) => {
          if (MatcherCashierFields[searchFilter.key].type === 'date') {
            return {
              key: searchFilter.key,
              value: [moment(searchFilter.value[0], DATETIME2_FORMAT), moment(searchFilter.value[1], DATETIME2_FORMAT)],
            };
          }
          return searchFilter;
        }),
      dimensions,
      sort,
      limit: pageSize,
      offset: pageSize * (page - 1),
    },
  });

  const columns = useColumns();

  const customColumns = columns.map((c) => {
    switch (c.key) {
      case MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS:
        return {
          ...c,
          render: (_, row) => {
            let color = '#FA9502';
            let backgroundColor = 'rgba(250, 149, 2, .1)';
            if (row.matcher_matched_transactions === 0) {
              color = '#FF4F4F';
              backgroundColor = 'rgba(255, 79, 79, .1)';
            }
            if (row.matcher_matched_transactions === row.matcher_total_transactions) {
              color = '#55C985';
              backgroundColor = 'rgba(85, 201, 133, .1)';
            }
            return (
              <span
                style={{
                  color,
                  backgroundColor,
                  borderRadius: 4,
                  padding: 4,
                }}
              >
                <Space>
                  <BookmarkIcon />
                  {row.matcher_matched_transactions} / {row.matcher_total_transactions}
                </Space>
              </span>
            );
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_AMOUNT_MATCHED:
        return {
          ...c,
          render: (_, row) => {
            let color = '#FA9502';
            let backgroundColor = 'rgba(250, 149, 2, .1)';
            if (row.matcher_matched_amount === 0) {
              color = '#FF4F4F';
              backgroundColor = 'rgba(255, 79, 79, .1)';
            }
            if (row.matcher_matched_amount === row.matcher_total_amount) {
              color = '#55C985';
              backgroundColor = 'rgba(85, 201, 133, .1)';
            }

            return (
              <span
                style={{
                  color,
                  backgroundColor,
                  borderRadius: 6,
                  padding: 4,
                }}
              >
                <Space>
                  <BookmarkIcon />
                  {amountFormatter(row.matcher_matched_amount, row.currency, c.format)} /{' '}
                  {amountFormatter(row.matcher_total_amount, row.currency, c.format)}
                </Space>
              </span>
            );
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE:
        return {
          ...c,
          render: (value) => RECONCILIATION_TYPES[value]?.label,
        };
      case MatcherCashierFieldsEnum.MATCHER_REASON:
      case MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON:
        return {
          ...c,
          render: (value) =>
            ({
              ...MATCH_LATER_REASONS,
              ...RECONCILIATION_REASONS,
              ...WITHDRAW_REASONS,
            })[value]?.label,
        };
      case MatcherCashierFieldsEnum.MATCHER_PAYMENT_CHANNEL:
        return {
          ...c,
          render: (value) => {
            const Icon = iconByChannel[value];
            return (
              <Space>
                {Icon && (
                  <span>
                    <Icon />
                  </span>
                )}
                <span>{PAYMENT_CHANNELS[value]?.label}</span>
              </Space>
            );
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT:
        return {
          ...c,
          render: (value, row) => (
            <span style={{ color: value === 0 ? '#29cc7e' : 'var(--primaryColor)' }}>
              {amountFormatter(value, row.currency, c.format)}
            </span>
          ),
        };
      case MatcherCashierFieldsEnum.MATCHER_AMOUNT_GAP:
        return {
          ...c,
          render: (value, row) => (
            <span style={{ color: value === 0 ? '#29cc7e' : 'var(--primaryColor)' }}>
              {amountFormatter(value, row.currency, c.format)}
            </span>
          ),
        };
      case MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS:
        return {
          ...c,
          render: (value) => <span style={{ color: value === 0 ? '#29cc7e' : 'var(--primaryColor)' }}>{value}</span>,
        };
      default:
        return c;
    }
  });

  const handleTableChange = (_p, _f, sorter) => {
    // handle single sort, default from favorite view
    if (Array.isArray(sorter)) {
      setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
    } else if (sorter.order) {
      setSort([{ field: sorter.field, sortOrder: sorter.order }]);
    } else {
      setSort([]);
    }
  };

  return (
    <>
      <Layout>
        <Layout.Header className={styles.layoutHeader}>
          <Row gutter={24} style={{ position: 'relative' }}>
            <Col>
              <Space>
                Results
                <Tag style={{ color: 'darkgrey' }}>{overviewTotal}</Tag>
              </Space>
            </Col>
            <Col flex="1" style={{ position: 'initial' }}>
              <SearchFilterTags list={searchFilterList} onSearch={onSearch} />
            </Col>
            <Col>
              <Radio.Group
                defaultValue="summary"
                buttonStyle="solid"
                className={styles.radio}
                value={currentPage.path}
                onChange={(e) => navigate(e.target.value)}
              >
                <Radio.Button value="/brainpower/matcher-cashier-summary">Summary</Radio.Button>
                <Radio.Button value="/brainpower/matcher-cashier-details">Details</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Layout.Header>
        <Layout
          id="matcher-cashier-details-table"
          className={styles.layoutContent}
          style={{ backgroundColor: 'white', boxShadow: '1px 1px 4px #d0d0d0', zIndex: 20 }}
        >
          <Layout.Content>
            <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
              {height ? (
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      className={styles.empty}
                      style={{ height: height - 74 }}
                      image={loading ? null : undefined}
                      description={(() => {
                        if (loading) {
                          return null;
                        }
                        if (error) {
                          return (
                            <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                          );
                        }
                        return [
                          <div key="no-results-found">No results found</div>,
                          <Button
                            key="adjust-filters"
                            type="link"
                            icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                            onClick={() => setDrawer(Drawers.SEARCH)}
                          >
                            Adjust filters
                          </Button>,
                        ];
                      })()}
                    />
                  )}
                >
                  <Table
                    className={classnames(styles.table)}
                    rowClassName={styles.tableRow}
                    columns={customColumns}
                    dataSource={overviewData}
                    loading={loading && { size: 'large' }}
                    size="small"
                    scroll={{ x: 'max-content', y: height - 96 }} // available height - head row height
                    pagination={false}
                    onChange={handleTableChange}
                  />
                </ConfigProvider>
              ) : (
                <div />
              )}
            </ReactHeight>
          </Layout.Content>
          <Layout.Footer className={styles.layoutFooter}>
            <Row gutter={24} justify="space-between">
              <Col>
                <Space>
                  <Pagination
                    className={styles.pagination}
                    total={overviewTotal}
                    current={page}
                    pageSize={pageSize}
                    onChange={setPage}
                    showSizeChanger={false}
                    showLessItems
                    size="small"
                  />
                  <Select
                    value={pageSize}
                    onSelect={(value) => setPage(1, value)}
                    options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                    bordered={false}
                  />
                </Space>
              </Col>
            </Row>
          </Layout.Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default MatcherCashierDetailsTable;
