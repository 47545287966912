import React from 'react';
import { message } from 'antd';
import { unflattenByProp } from '../../../../util/array';
import { encodeFilter } from '../hooks/useSearchFilters';
import { TargetEntity } from '../Common/constants/targetEntities';
import { Pages } from '../hooks/usePage';

export const redirectToEntityList = (navigate, exp) => {
  let redirectUrl;
  switch (exp.view?.entity) {
    case TargetEntity.CHECKOUT:
      redirectUrl = '/brainpower/checkouts';
      break;
    case TargetEntity.ORDER:
      redirectUrl = '/brainpower/orders';
      break;
    case TargetEntity.TRANSACTION:
      redirectUrl = '/brainpower/transactions';
      break;
    case TargetEntity.FINANCIAL_OPERATION:
      redirectUrl = '/brainpower/financial-operations';
      break;
    case TargetEntity.LOG:
      redirectUrl = '/logs';
      break;
    default:
      throw Error('Cannot match export entity. Please contact support.', exp._id);
  }
  const { prefix, search } = Pages[redirectUrl];
  redirectUrl += '?';
  if (exp.filters) redirectUrl += `${search.key}=${encodeFilter({dateFilter: exp.filters.dateFilter, searchFilters: unflattenByProp(exp.filters.searchFilters, 'key')})}`;
  if (exp.view?.columnSet) redirectUrl += `&${prefix}_columns=${encodeFilter(exp.view.columnSet)}`;
  if (exp.view?.sort) redirectUrl += `&${prefix}_sort=${encodeFilter(exp.view.sort)}`;
  if (exp.view?.entity) redirectUrl += `&${prefix}_entity=${exp.view.entity}`;
  navigate(redirectUrl);
  return message.info(
    <span>
      Search and Display inherited from export <strong>{exp.name}</strong>.
    </span>,
  );
};

export default {};
