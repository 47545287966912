import React from 'react';
import { Col, Modal, Row, Space } from 'antd';
import moment from 'moment-timezone';
import CalendarCheckIcon from '@2fd/ant-design-icons/lib/CalendarCheck';
import InformationIcon from '@2fd/ant-design-icons/lib/Information';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import CheckboxMarkedCircleOutlineIcon from '@2fd/ant-design-icons/lib/CheckboxMarkedCircleOutline';
import styles from './CloseRegisterModal.module.scss';
import { amountFormatter } from '../../../../../../../../util/formatter';
import { registerQuery } from './query';
import {useCan} from '../../../../../../../../contexts/ability.context';

const CloseRegisterModal = (props) => {
  const can = useCan();
  const { visible, onClose, register } = props;
  const { data, loading } = useQuery(registerQuery, {
    variables: {
      date: register._id.matcher_order_date,
      merchantAccount: register._id.merchantAccount,
    },
  });

  if (loading) {
    return (
      <Modal open={visible} footer={null}>
        <Space align="center" direction="vertical" style={{ display: 'flex' }}>
          <div className={styles.titleLoading}>CASH REGISTER CLOSURE</div>
          <div>
            <CheckboxMarkedCircleOutlineIcon className={styles.checkCircleIcon} />
          </div>
          <Space align="center">
            <InformationIcon className={styles.infoIcon} />
            <span>Closed</span>
          </Space>
        </Space>
      </Modal>
    );
  }

  return (
    <Modal open={visible} onCancel={onClose} footer={null} width={700}>
      <Space align="center" direction="vertical">
        <CalendarCheckIcon className={styles.calendarIcon} />
        <div className={styles.title}>Register closed</div>
        <Space align="start" className={styles.content}>
          <InformationIcon className={styles.infoIcon} />
          <div>
            <p>
              The register on the {register._id.matcher_order_date} is now successfully closed on the{' '}
              {moment(data.register.createdAt).format('YYYY-MM-DD')} {moment(data.register.createdAt).format('HH:mm')}.
            </p>
            <p>Summary of the register:</p>
            <Row gutter={8}>
              <Col span={can('read', 'matcher-withdraw') ? 6 : 8}>
                <Space direction="vertical" className={classnames(styles.card, styles.green)}>
                  <div className={styles.cardTitle}>Daily revenue</div>
                  <div>{amountFormatter(register?.matcher_total_amount, register?.currency)}</div>
                </Space>
              </Col>
              <Col span={can('read', 'matcher-withdraw') ? 6 : 8}>
                <Space direction="vertical" className={classnames(styles.card, styles.green)}>
                  <div className={styles.cardTitle}>Matched tickets</div>
                  <div>
                    <Space>
                      <BookmarkIcon />
                      {register?.matcher_matched_transactions}
                    </Space>
                  </div>
                </Space>
              </Col>
              <Col span={can('read', 'matcher-withdraw') ? 6 : 8}>
                <Space direction="vertical" className={classnames(styles.card, styles.orange)}>
                  <div className={styles.cardTitle}>To match later</div>
                  <div>
                    <Space>
                      <BookmarkIcon />
                      {register?.matcher_unmatched_transactions}
                    </Space>
                  </div>
                </Space>
              </Col>
              {can('read', 'matcher-withdraw') && (
                <Col span={6}>
                  <Space direction="vertical" className={classnames(styles.card, styles.grey)}>
                    <div className={styles.cardTitle}>Withdrawn tickets</div>
                    <div>
                      <Space>
                        <BookmarkIcon />
                        {data?.register?.details?.reduce(
                          (acc, row) =>
                            acc + (row.matcher_reconciliation_type === 'withdraw' ? row.matcher_total_transactions : 0),
                          0,
                        )}
                      </Space>
                    </div>
                  </Space>
                </Col>
              )}
            </Row>
          </div>
        </Space>
      </Space>
    </Modal>
  );
};

export default CloseRegisterModal;
