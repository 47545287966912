// a little function to help us with reordering the result
export const reorder = (list: Array<any>, startIndex: number, endIndex: number): Array<any> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const compareByProp = (
  propertyName: string,
  direction: string = 'asc',
  type: string = '',
): ((a: any, b: any) => number) => {
  switch (type) {
    case 'string':
      return (a: any, b: any) => {
        if (b[propertyName] == null) return direction === 'desc' ? 1 : -1;
        if (a[propertyName] == null) return direction === 'desc' ? -1 : 1;
        if (a[propertyName]?.toLowerCase() === b[propertyName]?.toLowerCase()) return 0;
        return (
          (a[propertyName]?.toLowerCase() < b[propertyName]?.toLowerCase() ? -1 : 1) * (direction === 'desc' ? -1 : 1)
        );
      };
    default:
      return (a: any, b: any) => {
        if (a[propertyName] === b[propertyName]) return 0;
        return (a[propertyName] < b[propertyName] ? -1 : 1) * (direction === 'desc' ? -1 : 1);
      };
  }
};

export const groupByProp = (arr: Array<any>, key: any): object =>
  arr.reduce((memo, item) => ({ ...memo, [item[key]]: [...memo[item[key]], item] }), {});

export const unflattenByProp = (arr: Array<any>, key: any): object =>
  arr.reduce((memo, item) => ({ ...memo, [item[key]]: item }), {});

export const flattenByProp = (arr: Array<any> = [], key: any): object =>
  arr.reduce((memo, item) => [...memo, ...item[key]], []);

/**
 * intersection will give us the elements that both arrays share in common
 * @param arrA
 * @param arrB
 */
export const intersection = (arrA: Array<any>, arrB: Array<any>): Array<any> =>
  arrA.filter((value) => arrB.includes(value));

/**
 * difference will output the elements from array A that are not in the array B.
 * @param arrA
 * @param arrB
 */
export const difference = (arrA: Array<any>, arrB: Array<any>): Array<any> =>
  arrA.filter((value) => !arrB.includes(value));

/**
 * uniq will give us the array without duplicated elements
 * @param arr
 * @returns {any[]}
 */
export const uniq = (arr: Array<any>): Array<any> => Array.from(new Set(arr));

export default {};
