import React from 'react';
import { Col, Skeleton } from 'antd';
import styles from './kpi.module.scss';

export default () => (
  <Col lg={8} sm={12} xs={24}>
    <div className={styles.skeleton}>
      <Skeleton avatar={{ size: 'large' }} paragraph={{ rows: 1 }} loading />
    </div>
  </Col>
);
