import { useLocation } from 'react-router-dom';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import moment from 'moment-timezone';
import { DATETIME_FORMAT } from '../../../../constants/DATES';
import periods from '../../../../constants/periods';
import AmountFormats from '../../../../constants/amountFormats';
import DateFormats from '../../../../constants/dateFormats';
import { SortOrder } from '../Common/constants/sortOrders';
import { TableDisplay } from '../Common/constants/tableDisplays';
import { TargetEntity } from '../Common/constants/targetEntities';
import { ModeInternal, ModeProgramManager } from '../../../../constants/mode';
import SOURCE from '../../../../constants/SOURCES';
import Dimensions from '../Dashboard/constants/dimensions';
import Indicators from '../Dashboard/constants/indicators';
import { PageType } from '../Common/constants/pageTypes';
import {
  MatcherCashierDetailFields,
  MatcherCashierFieldsEnum,
  MatcherCashierSummaryFields,
  MatcherPages,
  MatcherPaymentPartnerFields,
} from '../Matcher/MatcherCashier/fields';
import fields from '../Common/constants/fields';
import OverviewFields from '../Overview/fields';
import MatcherEngineFields from '../Matcher/MatcherTable/fields';
import MerchantAccountFields, { Fields as MerchantAccountFieldEnum } from '../../Admin/MerchantAccounts/fields';
import MerchantCompanyFields, { Fields as MerchantCompanyFieldEnum } from '../../Admin/Company/fields';
import MerchantUserFields, { Fields as MerchantUserFieldEnum } from '../../Admin/Users/fields';
import ProgramManagerUserFields, {
  Fields as ProgramManagerUserFieldEnum,
} from '../../Admin/ProgramManagerUsers/fields';
import InternalUserFields, { Fields as InternalUserFieldEnum } from '../../Admin/InternalUsers/fields';
import { useCan } from '../../../../contexts/ability.context';

const period = 'current_month';
const { from, to } = periods()[period];

export const SEARCH = {
  ACTIVITY: 'activitySearch',
  NOTIFICATION: 'notificationSearch',
  LOG: 'logSearch',
  MATCHER_CASHIER: 'matcherCashier',
  MATCHER_PAYMENT_PARTNER: 'matcherPaymentPartner',
  MERCHANT_ACCOUNT: 'merchantAccountSearch',
  MERCHANT_COMPANY: 'merchantCompanySearch',
  MERCHANT_USER: 'merchantUserSearch',
  PROGRAM_MANAGER_USER: 'programManagerUserSearch',
  INTERNAL_USER: 'internalUserSearch',
};

export const Searches = {
  [SEARCH.ACTIVITY]: {
    id: SEARCH.ACTIVITY,
    key: 'activityFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
    },
  },
  [SEARCH.MATCHER_CASHIER]: {
    id: SEARCH.MATCHER_CASHIER,
    key: 'matcherCashierFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {
        [MatcherCashierFieldsEnum.MATCHER_ORDER_DATE]: [
          moment().subtract(7, 'day').startOf('day').format(DATETIME_FORMAT),
          moment().endOf('day').format(DATETIME_FORMAT),
        ],
      },
      dateFilter: {},
    },
  },
  [SEARCH.MATCHER_PAYMENT_PARTNER]: {
    id: SEARCH.MATCHER_PAYMENT_PARTNER,
    key: 'matcherPaymentPartnerFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {
        [MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE]: [
          moment().subtract(7, 'day').startOf('day').format(DATETIME_FORMAT),
          moment().endOf('day').format(DATETIME_FORMAT),
        ],
      },
      dateFilter: {},
    },
  },
  [SEARCH.NOTIFICATION]: {
    id: SEARCH.NOTIFICATION,
    key: 'notifFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {
        key: Fields.NOTIFICATION_DATE,
        value: { period, from: from.format(DATETIME_FORMAT), to: to.format(DATETIME_FORMAT) },
      },
    },
  },
  [SEARCH.LOG]: {
    id: SEARCH.LOG,
    key: 'logFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {
        key: Fields.LOG_CREATED_AT,
        value: { period, from: from.format(DATETIME_FORMAT), to: to.format(DATETIME_FORMAT) },
      },
    },
  },
  [SEARCH.MERCHANT_ACCOUNT]: {
    id: SEARCH.MERCHANT_ACCOUNT,
    key: 'merchantAccountFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {},
    },
  },
  [SEARCH.MERCHANT_COMPANY]: {
    id: SEARCH.MERCHANT_COMPANY,
    key: 'merchantCompanyFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {},
    },
  },
  [SEARCH.MERCHANT_USER]: {
    id: SEARCH.MERCHANT_USER,
    key: 'merchantUserFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {},
    },
  },
  [SEARCH.PROGRAM_MANAGER_USER]: {
    id: SEARCH.PROGRAM_MANAGER_USER,
    key: 'programManagerUserFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {},
    },
  },
  [SEARCH.INTERNAL_USER]: {
    id: SEARCH.INTERNAL_USER,
    key: 'internalUserFilters',
    defaultSearch: {
      _id: 'default',
      label: 'Default search',
      searchFilters: {},
      dateFilter: {},
    },
  },
};

export const PAGE = {
  DASHBOARD: 'dashboard',
  OVERVIEW: 'overview',
  CHECKOUT_LIST: 'checkoutList',
  ORDER_LIST: 'orderList',
  TRANSACTION_LIST: 'transactionList',
  FINANCIAL_OPERATION_LIST: 'financialOperationList',
  NOTIFICATION_LIST: 'notificationList',
  LOG_LIST: 'logList',
  MERCHANT_ACCOUNT_LIST: 'merchantAccountList',
  MERCHANT_COMPANY_LIST: 'merchantCompanyList',
  MERCHANT_USER_LIST: 'merchantUserList',
  PROGRAM_MANAGER_USER_LIST: 'programManagerUserList',
  INTERNAL_USER_LIST: 'internalUserList',
  ...MatcherPages,
};

export const Pages = {
  '/brainpower/dashboard': {
    name: PAGE.DASHBOARD,
    label: 'Dashboard',
    path: '/brainpower/dashboard',
    prefix: 'db',
    type: PageType.DASHBOARD,
    displayEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    searchEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [],
    fields,
  },
  '/brainpower/orders': {
    name: PAGE.ORDER_LIST,
    label: 'Orders',
    path: '/brainpower/orders',
    prefix: 'o',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.ORDER_ID, label: 'ORDER' },
        { field: Fields.ORDER_TRANSACTIONS_COUNT, label: 'TRX' },
        { field: Fields.ORDER_CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
        { field: Fields.ORDER_STATUS, label: 'ORDER STATUS' },
        { field: Fields.ORDER_AMOUNT, label: 'AMOUNT', format: AmountFormats.FR_CURRENCY },
        { field: Fields.PAYIN_PARTNER, label: 'PARTNER' },
        { field: Fields.PAYMENT_METHOD, label: 'PM' },
        { field: Fields.CUSTOMER_COUNTRY, label: 'CUST. C' },
        { field: Fields.ORDER_MERCHANT_ID, label: 'MERCHANT REFERENCE' },
        { field: Fields.CUSTOMER_EMAIL, label: 'CUSTOMER EMAIL' },
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [{ field: Fields.ORDER_CREATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.ORDER,
    },
    displayEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER],
    searchEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.INSIGHTS_POS], excluded: true }],
    fields,
  },
  '/brainpower/transactions': {
    name: PAGE.TRANSACTION_LIST,
    label: 'Transactions',
    path: '/brainpower/transactions',
    prefix: 't',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.ORDER_ID, label: 'ORDER' },
        { field: Fields.TRANSACTION_ID, label: 'TRANSACTION' },
        { field: Fields.ORDER_CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
        { field: Fields.TRANSACTION_STATUS, label: 'TRANSACTION STATUS' },
        { field: Fields.TRANSACTION_AMOUNT, label: 'AMOUNT', format: AmountFormats.FR_CURRENCY },
        { field: Fields.PAYIN_PARTNER, label: 'PARTNER' },
        { field: Fields.PAYMENT_METHOD, label: 'PM' },
        { field: Fields.CUSTOMER_COUNTRY, label: 'CUST. C' },
        { field: Fields.AUTHENTICATION_RESULT, label: 'AUTH' },
        { field: Fields.ORDER_MERCHANT_ID, label: 'MERCHANT REF' },
        { field: Fields.CUSTOMER_EMAIL, label: 'CUSTOMER EMAIL' },
        { field: Fields.ACCOUNT_NUMBER, label: 'ACCOUNT NUMBER' },
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [{ field: Fields.TRANSACTION_CREATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.TRANSACTION,
    },
    displayEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    searchEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.STACK, SOURCE.INSIGHTS], excluded: false }],
    fields,
  },
  '/brainpower/checkouts': {
    name: PAGE.CHECKOUT_LIST,
    label: 'Checkouts',
    path: '/brainpower/checkouts',
    prefix: 'ch',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.CHECKOUT_ID, label: 'CHECKOUT ID' },
        { field: Fields.CHECKOUT_CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
        { field: Fields.CHECKOUT_AMOUNT, label: 'AMOUNT', format: AmountFormats.FR_CURRENCY },
        { field: Fields.CHECKOUT_CURRENCY, label: 'CURRENCY' },
        { field: Fields.CHECKOUT_CUSTOMER_EMAIL, label: 'CUSTOMER EMAIL' },
        { field: Fields.CHECKOUT_CUSTOMER_ID, label: 'CUSTOMER ID' },
        { field: Fields.CHECKOUT_ORDERS_COUNT, label: 'ORDER COUNT' },
        { field: Fields.CHECKOUT_TRANSACTIONS_COUNT, label: 'TX COUNT' },
        { field: Fields.CHECKOUT_UPDATE_DATE, label: 'UPDATE DATE', format: DateFormats.DATETIME },
        { field: Fields.CHECKOUT_TYPE, label: ' TYPE' },
        { field: Fields.CHECKOUT_EXPIRATION_DATE, label: 'EXPIRATION', format: DateFormats.DATETIME },
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [{ field: Fields.CHECKOUT_CREATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.CHECKOUT,
    },
    displayEntities: [TargetEntity.CHECKOUT],
    searchEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.STACK, SOURCE.INSIGHTS], excluded: false }],
    fields,
  },
  '/brainpower/overview': {
    name: PAGE.OVERVIEW,
    label: 'Overview',
    path: '/brainpower/overview',
    prefix: 'ov',
    type: PageType.OVERVIEW,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      dimensions: [
        ...(ModeInternal ? [Dimensions.programManagerId] : []),
        ...(ModeInternal || ModeProgramManager ? [Dimensions.companyId] : []),
        Dimensions.merchantAccountId,
        Dimensions.checkoutCreationDate,
      ],
      indicators: [
        Indicators.allCheckouts,
        Indicators.allOrders,
        Indicators.allTransactions,
        Indicators.authorizedTransactions,
        Indicators.authorizedTurnover,
        Indicators.refundedTransactions,
        Indicators.chargebackTransactions,
      ],
      columnSet: [
        ...(ModeInternal ? [{ field: Dimensions.programManagerId, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Dimensions.companyId, label: 'COMPANY' }] : []),
        { field: Dimensions.merchantAccountId, label: 'MERCHANT ACCOUNT' },
        { field: Dimensions.checkoutCreationDate, label: 'CHECKOUT CREATION DATE', format: DateFormats.DATETIME },
        { field: Indicators.allCheckouts, label: 'CHECKOUTS' },
        { field: Indicators.allOrders, label: 'ORDERS' },
        { field: Indicators.allTransactions, label: 'TRANSACTIONS' },
        { field: Indicators.authorizedTransactions, label: 'AUTHORIZED TRANSACTIONS' },
        { field: Indicators.authorizedTurnover, label: 'AUTHORIZED TURNOVER', format: AmountFormats.FR_CURRENCY },
        { field: Indicators.refundedTransactions, label: 'REFUNDED TRANSACTIONS' },
        { field: Indicators.chargebackTransactions, label: 'CHARGEBACK TRANSACTIONS' },
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [{ field: Dimensions.merchantAccountId, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
    },
    displayEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    searchEntities: [TargetEntity.CHECKOUT, TargetEntity.ORDER, TargetEntity.TRANSACTION],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [],
    fields: OverviewFields,
  },
  '/brainpower/matcher-engine': {
    name: PAGE.MATCHER_ENGINE,
    label: 'Matcher - Engine',
    path: '/brainpower/matcher-engine',
    prefix: 'me',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.ORDER_ID, label: 'ORDER ID' },
        { field: Fields.ORDER_CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
        { field: Fields.ORDER_AMOUNT, label: 'ORDER AMOUNT', format: AmountFormats.FR_CURRENCY },
        { field: Fields.ORDER_IS_RECONCILED, label: 'LINKED TRX' },
        { field: Fields.ORDER_UNRECONCILED_AMOUNT, label: 'AMOUNT GAP', format: AmountFormats.FR_CURRENCY },
        { field: Fields.CASHIER_PARTNER, label: 'CASHIER' },
        { field: Fields.ORDER_MERCHANT_ID, label: 'RECEIPT #' },
        { field: Fields.CASHIER_FILE_ID, label: 'FILE #' },
        { field: Fields.CASHIER_FILE_NAME, label: 'FILE NAME' },
        { field: Fields.RECONCILIATION_TYPE, label: 'MATCH TYPE' },
        { field: Fields.RECONCILIATION_DATE, label: 'MATCH DATE', format: DateFormats.DATETIME },
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [{ field: Fields.ORDER_CREATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.ORDER,
    },
    displayEntities: [TargetEntity.ORDER, TargetEntity.TRANSACTION],
    searchEntities: [TargetEntity.ORDER, TargetEntity.TRANSACTION],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.INSIGHTS_POS], excluded: false }],
    fields: MatcherEngineFields,
  },
  '/brainpower/matcher-cashier-summary': {
    name: PAGE.MATCHER_CASHIER_SUMMARY,
    label: 'Matcher - Cashier',
    path: '/brainpower/matcher-cashier-summary',
    prefix: 'mcs',
    type: PageType.OVERVIEW,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: MatcherCashierFieldsEnum.MATCHER_COMPANY, label: 'COMPANY' }] : []),
        { field: MatcherCashierFieldsEnum.MATCHER_STORE, label: 'STORE' },
        { field: MatcherCashierFieldsEnum.MATCHER_ORDER_DATE, label: 'ORDER DATE', format: DateFormats.DATE },
        {
          field: MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
          label: 'NOTIFICATION',
          format: DateFormats.DATE,
        },
        { field: MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS, label: 'MATCHED TRX RATIO' },
        {
          field: MatcherCashierFieldsEnum.MATCHER_AMOUNT_MATCHED,
          label: 'MATCHED AMOUNT RATIO',
          format: AmountFormats.FR_CURRENCY,
        },
        { field: MatcherCashierFieldsEnum.MATCHER_REGISTER_STATUS, label: 'STATUS' },
        { field: MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE, label: 'MATCH TYPE' },
        { field: MatcherCashierFieldsEnum.MATCHER_REASON, label: 'REASON' },
        { field: MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON, label: 'WITHDRAW REASON' },
        {
          field: MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT,
          label: 'UNMATCHED AMOUNT',
          format: AmountFormats.FR_CURRENCY,
        },
        { field: MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS, label: 'TRANSACTIONS' },
        { field: MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS, label: 'UNMATCHED TRANSACTIONS' },
      ],
      dimensions: [
        MatcherCashierFieldsEnum.MATCHER_ORDER_DATE,
        MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
        MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE,
        MatcherCashierFieldsEnum.MATCHER_REASON,
      ],
      indicators: [
        MatcherCashierFieldsEnum.MATCHER_REGISTER_STATUS,
        MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS,
        MatcherCashierFieldsEnum.MATCHER_AMOUNT_MATCHED,
        MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT,
        MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS,
        MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS,
      ],
      searchFilters: Searches[SEARCH.MATCHER_CASHIER].defaultSearch.searchFilters,
      sort: [],
      display: TableDisplay.RICH,
      entity: TargetEntity.ORDER,
    },
    displayEntities: [],
    searchEntities: [],
    search: Searches[SEARCH.MATCHER_CASHIER],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.INSIGHTS_POS], excluded: false }],
    fields: MatcherCashierSummaryFields,
  },
  '/brainpower/matcher-cashier-details': {
    name: PAGE.MATCHER_CASHIER_DETAILS,
    label: 'Matcher - Cashier',
    path: '/brainpower/matcher-cashier-details',
    prefix: 'mcd',
    type: PageType.OVERVIEW,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: MatcherCashierFieldsEnum.MATCHER_COMPANY, label: 'COMPANY' }] : []),
        { field: MatcherCashierFieldsEnum.MATCHER_ORDER_DATE, label: 'ORDER DATE', format: DateFormats.DATE },
        { field: MatcherCashierFieldsEnum.MATCHER_CASHIER, label: 'CASHIER' },
        { field: MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD, label: 'PAYMENT METHOD' },
        { field: MatcherCashierFieldsEnum.MATCHER_STORE, label: 'STORE' },
        {
          field: MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
          label: 'NOTIFICATION',
          format: DateFormats.DATE,
        },
        { field: MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS, label: 'TRANSACTIONS' },
        {
          field: MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT,
          label: 'TOTAL AMOUNT',
          format: AmountFormats.FR_CURRENCY,
        },
        { field: MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS, label: 'MATCHED TRX' },
        { field: MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS, label: 'UNMATCHED TRX' },
      ],
      dimensions: [
        MatcherCashierFieldsEnum.MATCHER_ORDER_DATE,
        MatcherCashierFieldsEnum.MATCHER_CASHIER,
        MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD,
        MatcherCashierFieldsEnum.MATCHER_STORE,
        MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
      ],
      indicators: [
        MatcherCashierFieldsEnum.MATCHER_TOTAL_TRANSACTIONS,
        MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT,
        MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS,
        MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS,
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [],
      display: TableDisplay.RICH,
      entity: TargetEntity.ORDER,
    },
    displayEntities: [],
    searchEntities: [],
    search: Searches[SEARCH.MATCHER_CASHIER],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.INSIGHTS_POS], excluded: false }],
    fields: MatcherCashierDetailFields,
  },
  '/brainpower/matcher-payment-partner': {
    name: PAGE.MATCHER_PAYMENT_PARTNER,
    label: 'Matcher - Payment partner',
    path: '/brainpower/matcher-payment-partner',
    prefix: 'mpp',
    type: PageType.OVERVIEW,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: MatcherCashierFieldsEnum.MATCHER_COMPANY, label: 'COMPANY' }] : []),
        { field: MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE, label: 'NOTIFICATION', format: DateFormats.DATE },
        { field: MatcherCashierFieldsEnum.MATCHER_PAYIN_PARTNER, label: 'PAYIN PARTNER' },
        { field: MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD, label: 'PAYMENT METHOD' },
        { field: MatcherCashierFieldsEnum.MATCHER_STORE, label: 'STORE' },
        { field: MatcherCashierFieldsEnum.MATCHER_TOTAL_NOTIFICATIONS, label: 'NOTIFICATIONS' },
        { field: MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT, label: 'AMOUNT', format: AmountFormats.FR_CURRENCY },
        { field: MatcherCashierFieldsEnum.MATCHER_MATCHED_NOTIFICATIONS, label: 'MATCHED NOTIFICATIONS' },
        { field: MatcherCashierFieldsEnum.MATCHER_UNMATCHED_NOTIFICATIONS, label: 'UNMATCHED NOTIFICATIONS' },
      ],
      dimensions: [
        MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE,
        MatcherCashierFieldsEnum.MATCHER_PAYIN_PARTNER,
        MatcherCashierFieldsEnum.MATCHER_PAYMENT_METHOD,
        MatcherCashierFieldsEnum.MATCHER_STORE,
      ],
      indicators: [
        MatcherCashierFieldsEnum.MATCHER_TOTAL_NOTIFICATIONS,
        MatcherCashierFieldsEnum.MATCHER_TOTAL_AMOUNT,
        MatcherCashierFieldsEnum.MATCHER_MATCHED_NOTIFICATIONS,
        MatcherCashierFieldsEnum.MATCHER_UNMATCHED_NOTIFICATIONS,
      ],
      searchFilters: Searches[SEARCH.MATCHER_PAYMENT_PARTNER].defaultSearch.searchFilters,
      sort: [{ field: MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.ORDER,
    },
    displayEntities: [],
    searchEntities: [],
    search: Searches[SEARCH.MATCHER_PAYMENT_PARTNER],
    rawSearch: [],
    fields: MatcherPaymentPartnerFields,
  },
  '/brainpower/financial-operations': {
    name: PAGE.FINANCIAL_OPERATION_LIST,
    label: 'Finance',
    path: '/brainpower/financial-operations',
    prefix: 'fo',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.ORDER_ID, label: 'ORDER' },
        { field: Fields.FINANCIAL_OPERATION_ID, label: 'FIOP ID' },
        { field: Fields.PAYIN_PARTNER, label: 'PARTNER' },
        { field: Fields.FINANCIAL_OPERATION_TYPE, label: 'FIOP TYPE' },
        { field: Fields.FINANCIAL_OPERATION_FEE_TYPE, label: 'FEE TYPE' },
        { field: Fields.PAYMENT_METHOD, label: 'PM' },
        { field: Fields.FINANCIAL_OPERATION_AMOUNT, label: 'FIOP AMOUNT', format: AmountFormats.FR_CURRENCY },
        { field: Fields.FINANCIAL_OPERATION_CURRENCY, label: 'FIOP CUR' },
        { field: Fields.FINANCIAL_OPERATION_MOVEMENT, label: 'MOVEMENT' },
        { field: Fields.FINANCIAL_OPERATION_CREATED_AT, label: 'FIOP DATE', format: DateFormats.DATETIME },
      ],
      searchFilters: Searches[SEARCH.ACTIVITY].defaultSearch.searchFilters,
      sort: [{ field: Fields.TRANSACTION_CREATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.FINANCIAL_OPERATION,
    },
    displayEntities: [
      TargetEntity.CHECKOUT,
      TargetEntity.ORDER,
      TargetEntity.TRANSACTION,
      TargetEntity.FINANCIAL_OPERATION,
    ],
    searchEntities: [
      TargetEntity.CHECKOUT,
      TargetEntity.ORDER,
      TargetEntity.TRANSACTION,
      TargetEntity.FINANCIAL_OPERATION,
    ],
    search: Searches[SEARCH.ACTIVITY],
    rawSearch: [{ key: Fields.SOURCE, value: [SOURCE.STACK, SOURCE.INSIGHTS], excluded: false }],
    fields,
  },
  '/brainpower/notifications': {
    name: PAGE.NOTIFICATION_LIST,
    label: 'Notifications',
    path: '/brainpower/notifications',
    prefix: 'n',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.NOTIFICATION_PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.NOTIFICATION_COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.NOTIFICATION_DATE, label: 'CREATION', format: DateFormats.DATETIME },
        { field: Fields.NOTIFICATION_CALLS, label: 'CALLS' },
        { field: Fields.NOTIFICATION_RESULT_CODE, label: 'RESULT' },
        { field: Fields.NOTIFICATION_STATUS, label: 'STATUS' },
        { field: Fields.NOTIFICATION_TRIGGER, label: 'TRIGGER' },
        { field: Fields.NOTIFICATION_ID, label: 'NOTIFICATION ID' },
        { field: Fields.NOTIFICATION_URL, label: 'URL' },
        { field: Fields.NOTIFICATION_WEBHOOK, label: 'WEBHOOK' },
        { field: Fields.NOTIFICATION_TRANSACTION_ID, label: 'TRANSACTION' },
        { field: Fields.NOTIFICATION_ORDER_ID, label: 'ORDER' },
      ],
      searchFilters: Searches[SEARCH.NOTIFICATION].defaultSearch.searchFilters,
      sort: [{ field: Fields.NOTIFICATION_DATE, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.NOTIFICATION,
    },
    displayEntities: [TargetEntity.NOTIFICATION],
    searchEntities: [TargetEntity.NOTIFICATION],
    search: Searches[SEARCH.NOTIFICATION],
    fields, // TODO - split notification fields from activity fields
  },
  '/logs': {
    name: PAGE.LOG_LIST,
    label: 'Logs',
    path: '/logs',
    prefix: 'l',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: Fields.LOG_PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        ...(ModeInternal || ModeProgramManager ? [{ field: Fields.LOG_COMPANY, label: 'COMPANY' }] : []),
        { field: Fields.LOG_CREATED_AT, label: 'Created at', format: DateFormats.DATETIME },
        { field: Fields.LOG_AUTHOR_EMAIL, label: 'Author email' },
        { field: Fields.LOG_AUTHOR_FULL_NAME, label: 'Author name' },
        { field: Fields.LOG_ENTITY_TYPE, label: 'Entity type' },
        { field: Fields.LOG_ENTITY_ID, label: 'Entity ID' },
        { field: Fields.LOG_ENTITY_NAME, label: 'Entity name' },
        { field: Fields.LOG_MERCHANT_ACCOUNT_NAME, label: 'Merchant account name' },
        { field: Fields.LOG_MERCHANT_ACCOUNT_ID, label: 'Merchant account ID' },
        { field: Fields.LOG_UPDATE, label: 'Details' },
        { field: Fields.LOG_IP, label: 'IP address' },
      ],
      searchFilters: Searches[SEARCH.LOG].defaultSearch.searchFilters,
      sort: [{ field: Fields.LOG_CREATED_AT, sortOrder: SortOrder.DESCEND }],
      display: TableDisplay.RICH,
      entity: TargetEntity.LOG,
    },
    displayEntities: [TargetEntity.LOG],
    searchEntities: [TargetEntity.LOG],
    search: Searches[SEARCH.LOG],
    fields, // TODO - split log fields from activity fields
  },
  '/merchant-accounts': {
    name: PAGE.MERCHANT_ACCOUNT_LIST,
    label: 'Merchant accounts',
    path: '/merchant-accounts',
    prefix: 'ma',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: MerchantAccountFieldEnum.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        { field: MerchantAccountFieldEnum.COMPANY, label: 'COMPANY' },
        { field: MerchantAccountFieldEnum.MERCHANT_ACCOUNT, label: 'MERCHANT ACCOUNT' },
        { field: MerchantAccountFieldEnum.USER_COUNT, label: 'USERS' },
        { field: MerchantAccountFieldEnum.CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
        { field: MerchantAccountFieldEnum.STATUS, label: 'STATUS' },
        { field: MerchantAccountFieldEnum.SOURCE, label: 'SOURCE' },
        { field: MerchantAccountFieldEnum.REFERENCE_CURRENCY, label: 'CURRENCY' },
        { field: MerchantAccountFieldEnum.MERCHANT_CONTRACT_COUNT, label: 'CONTRACTS' },
      ],
      searchFilters: Searches[SEARCH.MERCHANT_ACCOUNT].defaultSearch.searchFilters,
      sort: [{ field: MerchantAccountFieldEnum.MERCHANT_ACCOUNT, sortOrder: SortOrder.ASCEND }],
      display: TableDisplay.RICH,
    },
    displayEntities: [TargetEntity.MERCHANT_ACCOUNT],
    searchEntities: [TargetEntity.MERCHANT_ACCOUNT],
    search: Searches[SEARCH.MERCHANT_ACCOUNT],
    fields: MerchantAccountFields,
  },
  '/companies': {
    name: PAGE.MERCHANT_COMPANY_LIST,
    label: 'Companies',
    path: '/companies',
    prefix: 'cmp',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: MerchantCompanyFieldEnum.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        { field: MerchantCompanyFieldEnum.COMPANY, label: 'COMPANY' },
        { field: MerchantCompanyFieldEnum.MERCHANT_ACCOUNT_COUNT, label: 'ACCOUNTS' },
        { field: MerchantCompanyFieldEnum.CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
        { field: MerchantCompanyFieldEnum.COUNTRY, label: 'COUNTRY' },
        { field: MerchantCompanyFieldEnum.STATUS, label: 'STATUS' },
        { field: MerchantCompanyFieldEnum.USER_COUNT, label: 'USERS' },
        { field: MerchantCompanyFieldEnum.MCC, label: 'MCC' },
        { field: MerchantCompanyFieldEnum.ACTIVITY, label: 'ACTIVITY' },
      ],
      searchFilters: Searches[SEARCH.MERCHANT_COMPANY].defaultSearch.searchFilters,
      sort: [{ field: MerchantCompanyFieldEnum.COMPANY, sortOrder: SortOrder.ASCEND }],
      display: TableDisplay.RICH,
    },
    displayEntities: [TargetEntity.MERCHANT_COMPANY],
    searchEntities: [TargetEntity.MERCHANT_COMPANY],
    search: Searches[SEARCH.MERCHANT_COMPANY],
    fields: MerchantCompanyFields,
  },
  '/users': {
    name: PAGE.MERCHANT_USER_LIST,
    label: 'Users',
    path: '/users',
    prefix: 'mu',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: MerchantUserFieldEnum.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        { field: MerchantUserFieldEnum.COMPANY, label: 'COMPANY' },
        { field: MerchantUserFieldEnum.EMAIL, label: 'EMAIL' },
        { field: MerchantUserFieldEnum.AVATAR, label: 'AVATAR' },
        { field: MerchantUserFieldEnum.FULL_NAME, label: 'FULL NAME' },
        { field: MerchantUserFieldEnum.ACL_PROFILE, label: 'PROFILE' },
        { field: MerchantUserFieldEnum.GDPR, label: 'GDPR' },
        { field: MerchantUserFieldEnum.STATUS, label: 'STATUS' },
        { field: MerchantUserFieldEnum.CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
      ],
      searchFilters: Searches[SEARCH.MERCHANT_USER].defaultSearch.searchFilters,
      sort: [{ field: MerchantUserFieldEnum.EMAIL, sortOrder: SortOrder.ASCEND }],
      display: TableDisplay.RICH,
    },
    displayEntities: [TargetEntity.MERCHANT_USER],
    searchEntities: [TargetEntity.MERCHANT_USER],
    search: Searches[SEARCH.MERCHANT_USER],
    fields: MerchantUserFields,
  },
  '/program-users': {
    name: PAGE.PROGRAM_MANAGER_USER_LIST,
    label: 'PM Users',
    path: '/program-users',
    prefix: 'pmu',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        ...(ModeInternal ? [{ field: ProgramManagerUserFieldEnum.PROGRAM_MANAGER, label: 'PROGRAM MANAGER' }] : []),
        { field: ProgramManagerUserFieldEnum.EMAIL, label: 'EMAIL' },
        { field: ProgramManagerUserFieldEnum.AVATAR, label: 'AVATAR' },
        { field: ProgramManagerUserFieldEnum.FULL_NAME, label: 'FULL NAME' },
        { field: ProgramManagerUserFieldEnum.ACL_PROFILE, label: 'PROFILE' },
        { field: ProgramManagerUserFieldEnum.GDPR, label: 'GDPR' },
        { field: ProgramManagerUserFieldEnum.STATUS, label: 'STATUS' },
        { field: ProgramManagerUserFieldEnum.CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
      ],
      searchFilters: Searches[SEARCH.MERCHANT_USER].defaultSearch.searchFilters,
      sort: [{ field: ProgramManagerUserFieldEnum.EMAIL, sortOrder: SortOrder.ASCEND }],
      display: TableDisplay.RICH,
    },
    displayEntities: [TargetEntity.PROGRAM_MANAGER_USER],
    searchEntities: [TargetEntity.PROGRAM_MANAGER_USER],
    search: Searches[SEARCH.PROGRAM_MANAGER_USER],
    fields: ProgramManagerUserFields,
  },
  '/internal-users': {
    name: PAGE.INTERNAL_USER_LIST,
    label: 'Internal Users',
    path: '/internal-users',
    prefix: 'iu',
    type: PageType.LIST,
    defaultView: {
      _id: 'default',
      label: 'Default view',
      columnSet: [
        { field: InternalUserFieldEnum.EMAIL, label: 'EMAIL' },
        { field: InternalUserFieldEnum.AVATAR, label: 'AVATAR' },
        { field: InternalUserFieldEnum.FULL_NAME, label: 'FULL NAME' },
        { field: InternalUserFieldEnum.ACL_PROFILE, label: 'PROFILE' },
        { field: InternalUserFieldEnum.GDPR, label: 'GDPR' },
        { field: InternalUserFieldEnum.STATUS, label: 'STATUS' },
        { field: InternalUserFieldEnum.CREATION_DATE, label: 'CREATION DATE', format: DateFormats.DATETIME },
      ],
      searchFilters: Searches[SEARCH.INTERNAL_USER].defaultSearch.searchFilters,
      sort: [{ field: InternalUserFieldEnum.EMAIL, sortOrder: SortOrder.ASCEND }],
      display: TableDisplay.RICH,
    },
    displayEntities: [TargetEntity.INTERNAL_USER],
    searchEntities: [TargetEntity.INTERNAL_USER],
    search: Searches[SEARCH.INTERNAL_USER],
    fields: InternalUserFields,
  },
};
const usePage = () => {
  const can = useCan();
  const location = useLocation();
  const page = Object.values(Pages).find((p) => location.pathname.includes(p.path));
  if (page) {
    return {
      ...page,
      fields: Object.entries(page.fields)
        .filter(([, field]) => !field?.isForbidden?.(can))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {},
        ),
    };
  }
  return {};
};

export default usePage;
