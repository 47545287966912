import React from 'react';
import { Button, message, Space, Tooltip } from 'antd';
import copy from 'copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { amountFormatter, byteFormatter, dateFormatter, numberFormatter } from '../../../../../util/formatter';
import { middleEllipsis } from '../../../../../util/string';
import fields from '../constants/fields';
import FieldTypes from '../constants/fieldTypes';
import { TableDisplay } from '../constants/tableDisplays';
import OptionCell from './Cell/OptionCell';
import AsyncListTableCell from './Cell/AsyncListTableCell';
import { uniq } from '../../../../../util/array';
import TargetEntities, { TargetEntity } from '../constants/targetEntities';

const renderAsyncListCell = (field) => (value) => <AsyncListTableCell field={field} value={value} />;

export const renderListCell = (field) => (value) => {
  const option = field.displayOptions.getOption(value);
  if (!option) return null;
  return <OptionCell field={field} option={option} />;
};

export const renderDateCell =
  (field, { format } = {}) =>
  (value = null) => {
    if (value === null) return null;
    if (value === 'No label') return 'No date';
    return dateFormatter(value, format);
  };

export const renderNumberCell =
  (field, { format, unit } = {}) =>
  (value = null) => {
    if (value === null) return null;
    switch (unit) {
      case 'byte':
        return byteFormatter(value);
      case 'percent':
        return numberFormatter(value / 100, format, { style: 'percent', maximumFractionDigits: 2 });
      default:
        return numberFormatter(value, format);
    }
  };

export const renderAmountCell =
  (field, { format }) =>
  (value = null, row) => {
    if (value === null) return null;
    const { [field.displayOptions.currency]: currency } = row;
    return amountFormatter(value, currency, format);
  };

export const renderBooleanCell =
  (field) =>
  (value = null) => {
    switch (value) {
      case true:
      case 'True':
        return field.displayOptions?.trueElement ?? 'Yes';
      case false:
      case 'False':
        return field.displayOptions?.falseElement ?? 'No';
      case null:
      case 'No label':
      default:
        return null;
    }
  };

export const renderCountCell =
  (field) =>
  (value = null) => {
    if (value === null) return null;
    return (
      <Button type="primary" size="small" disabled={!value}>
        {value}
      </Button>
    );
  };

export const renderEntityCount =
  (field, entity) =>
  (value = null) => {
    if (value === null) return null;
    const { icon: Icon } = TargetEntities[entity];
    return (
      <Button
        type="primary"
        size="small"
        disabled={!value}
        style={{
          border: 'none',
          backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
          color: 'var(--ant-primary-color)',
        }}
        icon={<Icon />}
      >
        {value}
      </Button>
    );
  };

export const renderTextCell =
  (field) =>
  (value = null) => {
    if (value === null) return null;
    if (field?.displayOptions?.ellipsis === 'middle') {
      return (
        <Tooltip
          destroyTooltipOnHide
          overlayStyle={{ maxWidth: 300 }}
          color="whitesmoke"
          title={
            <Button
              type="link"
              icon={<CopyOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                copy(value);
                message.success('Copied !');
              }}
            >
              {value}
            </Button>
          }
        >
          {middleEllipsis(value, 13, 5)}
        </Tooltip>
      );
    }
    if (field?.displayOptions?.link)
      return (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            copy(value);
            message.success('Copied !');
          }}
        >
          {value}
        </Button>
      );
    return value;
  };

export const renderCustomCell =
  (field) =>
  (value = null) => {
    if (value === null) return null;
    const stringifyValue = JSON.stringify(value, null, 2);
    return <span title={stringifyValue}>{stringifyValue}</span>;
  };

export const renderDefaultCell = (value) => value?.toString();

export const renderGdprCell = () => '******';

const MAX_DISPLAYED_ELEMENTS = 3;
export const renderAsArray =
  (renderFunction) =>
  (value, ...args) => {
    if (Array.isArray(value)) {
      const uniqValues = uniq(value).sort();
      return (
        <Space>
          {uniqValues.slice(0, MAX_DISPLAYED_ELEMENTS).map(renderFunction)}
          {uniqValues.length > MAX_DISPLAYED_ELEMENTS && (
            <div
              style={{
                backgroundColor: '#e7e7e7',
                borderRadius: 4,
                textAlign: 'center',
                padding: '2px 7px',
              }}
              title={uniqValues.slice(MAX_DISPLAYED_ELEMENTS).join(', ')}
            >
              +{uniqValues.length - MAX_DISPLAYED_ELEMENTS}
            </div>
          )}
        </Space>
      );
    }
    return renderFunction(value, ...args);
  };

export const buildColumnProps = (field, column = {}, isGdpr = false, displayText = false) => {
  const columnProps = {};
  switch (field.type) {
    case FieldTypes.ASYNC_LIST:
      if (displayText) columnProps.render = renderDefaultCell;
      else if (field.displayOptions.asId) columnProps.render = renderTextCell(field);
      else if (field.displayOptions.getOption) columnProps.render = renderListCell(field);
      else columnProps.render = renderAsyncListCell(field);
      break;
    case FieldTypes.LIST:
      columnProps.render = displayText ? renderDefaultCell : renderListCell(field);
      break;
    case FieldTypes.DATE:
      columnProps.render = renderDateCell(field, column);
      break;
    case FieldTypes.AMOUNT:
      columnProps.render = renderAmountCell(field, column);
      columnProps.align = 'right';
      break;
    case FieldTypes.NUMBER:
      columnProps.render = renderNumberCell(field, column);
      columnProps.align = 'right';
      break;
    case FieldTypes.BOOLEAN:
      columnProps.render = renderBooleanCell(field);
      columnProps.align = 'center';
      break;
    case FieldTypes.COUNT:
      columnProps.align = 'center';
      if (displayText) columnProps.render = renderDefaultCell;
      else if ([Fields.ORDER_TRANSACTIONS_COUNT, Fields.CHECKOUT_TRANSACTIONS_COUNT].includes(field.key)) {
        columnProps.render = renderEntityCount(field, TargetEntity.TRANSACTION);
      } else if (field.key === Fields.CHECKOUT_ORDERS_COUNT) {
        columnProps.render = renderEntityCount(field, TargetEntity.ORDER);
      } else if (field.key === 'user_count') {
        columnProps.render = renderEntityCount(field, TargetEntity.MERCHANT_USER);
      } else if (field.key === 'merchant_account_count' || field.key === 'authorized_merchant_account_count') {
        columnProps.render = renderEntityCount(field, TargetEntity.MERCHANT_ACCOUNT);
      } else if (field.key === 'merchant_contract_count') {
        columnProps.render = renderEntityCount(field, TargetEntity.MERCHANT_CONTRACT);
      } else {
        columnProps.render = renderCountCell(field);
      }
      break;
    case FieldTypes.TEXT:
      columnProps.render = displayText ? renderDefaultCell : renderTextCell(field);
      break;
    case FieldTypes.CUSTOM:
      columnProps.render = field.displayOptions.customRender;
      columnProps.sorter = null;
      break;
    default:
      columnProps.render = renderDefaultCell;
      break;
  }

  columnProps.render = renderAsArray(columnProps.render);

  if (field.gdpr && !isGdpr) {
    columnProps.render = renderGdprCell;
  }

  return columnProps;
};

const buildColumnFieldProps = (column, gdpr, displayText = false) => {
  const field = fields[column.field];

  if (!field) throw Error(`Field ${column.field} doesn't exist.`);

  return buildColumnProps(field, column, gdpr, displayText);
};

/**
 * Build AntD Table columns from columnSet and context
 */
const buildColumns = (columnSet, sort, tableDisplay, entity, gdpr = false) =>
  columnSet.map((c, index, { length }) => {
    const sortIndex = sort.findIndex((s) => s.field === c.field);

    return {
      key: c.field,
      dataIndex: c.field,
      title: c.label,
      ellipsis: true,
      width: Math.max(c.label.length) * 14 + 30,
      sorter: { multiple: sortIndex === -1 ? length : sortIndex },
      sortOrder: sort[sortIndex]?.sortOrder,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      fixed: index === 0 ? 'left' : false,
      ...buildColumnFieldProps(c, gdpr, tableDisplay === TableDisplay.TEXT),
    };
  });

export default buildColumns;
