import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import EnvSelector from './EnvSelector/EnvSelector';
import Login from './Login/Login';
import RecoverPassword from './RecoverPassword/RecoverPassword';
import SetPassword from './SetPassword/SetPassword';
import styles from './AppUnlogged.module.scss';
import { useProgramManager } from '../../contexts/programManager.context';
import logoNorbr from '../../assets/images/logo/norbr-white.svg';
import { useProgramManagerId } from '../../contexts/app.context';
import AppLoading from '../../AppLoading';

const AppUnlogged = () => {
  const location = useLocation();

  const [programManagerId] = useProgramManagerId();
  const programManager = useProgramManager();

  if (programManagerId && !programManager) return <AppLoading />;

  return (
    <div
      className={styles.root}
      style={
        programManager?.theme.background && {
          backgroundImage: `url(${programManager.theme.background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      }
    >
      <div className={styles.header}>
        <img src={programManager?.theme.logo ?? logoNorbr} alt={`${programManager?.displayName ?? 'norbr'}-logo`} />
      </div>
      <div className={styles.container}>
        <Routes>
          <Route index element={<EnvSelector programManager={programManager} />} />
          <Route path="recover-password" element={<RecoverPassword />} />
          <Route path="set-password" element={<SetPassword />} />
          <Route path="login" element={<Login redirectAfter={location.pathname + location.search} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <div className={styles.footer} />
    </div>
  );
};

export default AppUnlogged;
