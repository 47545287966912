import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Tag } from 'antd';
import TRANSACTION_STATUSES from '../../../../../../constants/TRANSACTION_STATUSES';
import InfoTile, {
  InfoDateTimeRow,
  InfoLinkRow,
  InfoRow,
  InfoTextRow,
  ResponsiveBodyTile,
} from '../../../OrderDetail/Tile/Info/Info';
import WebhookSkeleton from './WebhookSkeleton';
import { webhookQuery } from './query';

const Webhook = () => {
  const { notificationId } = useParams();

  const { data, loading } = useQuery(webhookQuery, {
    variables: {
      notificationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (!data?.notification?.webhook || loading) return <WebhookSkeleton />;

  const { merchantAccount, webhook } = data.notification;

  return (
    <InfoTile name="webhook" title="Webhook">
      <ResponsiveBodyTile>
        <InfoLinkRow
          label="Webhook ID"
          value={webhook.id}
          to={`/router/notifications?maccount=${merchantAccount._id}&api=notifications-${webhook.id}`}
        />
        <InfoDateTimeRow label="Creation" value={webhook.createdAt} />
        <InfoTextRow label="Name" value={webhook.name} />
        <InfoTextRow label="URL" value={webhook.url} />
      </ResponsiveBodyTile>
      <InfoRow label="Triggers" value={webhook.trigger}>
        {webhook.trigger?.map((t) => (
          <Tag key={t} color={TRANSACTION_STATUSES[t].color}>
            {TRANSACTION_STATUSES[t].label}
          </Tag>
        ))}
      </InfoRow>
    </InfoTile>
  );
};

export default Webhook;
