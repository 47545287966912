import React, { useState } from 'react';
import { Avatar, Button, Modal, Typography } from 'antd';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import PollIcon from '@2fd/ant-design-icons/lib/Poll';
import classnames from 'classnames';
import styles from './Message.module.scss';
import Footer from './Footer/Footer';
import CloseIcon from '../../CloseIcon/CloseIcon';
import SendingMethods from '../../../../Layout/Routes/Brainpower/ExportList/constants/sendingMethods';
import { initials } from '../../../../util/string';
import useNavigateWithSearch from '../../../../util/navigate';
import ExportStatuses from '../../../../Layout/Routes/Brainpower/ExportList/constants/exportStatuses';
import Markdown from '../../../../Layout/Routes/Common/Markdown/Markdown';
import announcementTypes, { AnnouncementTypes } from '../../../../Layout/Routes/Admin/Announcements/announcementTypes';

const Message = (props) => {
  const {
    message,
    index,
    count,
    expanded,
    handleClick,
    handleClose,
    title,
    icon,
    content,
    button,
    preview = false,
  } = props;

  const [disappearing, setDisappearing] = useState(false);

  return (
    <div
      role="presentation"
      onClick={handleClick}
      className={classnames(styles.root, { [styles.disappearing]: disappearing })}
      style={
        !preview
          ? {
              ...(index === 0 || expanded
                ? {
                    position: 'relative',
                    zIndex: 9999,
                    marginBottom: 8,
                  }
                : {
                    width: '100%',
                    position: 'absolute',
                    top: `${8 * index}px`,
                    zIndex: 9999 - index,
                  }),
            }
          : {}
      }
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {icon}
        <div
          style={{
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >{title}</div>
        {preview || (
          <CloseIcon
            count={expanded ? 1 : count}
            handleClick={() => {
              handleClose();
              setDisappearing(true);
            }}
          />
        )}
      </div>
      <div className={styles.content}>{content}</div>
      <Footer createdAt={message.createdAt}>{button}</Footer>
    </div>
  );
};

const DashboardMessage = (props) => {
  const { message, ackMessages } = props;

  const navigate = useNavigateWithSearch();

  return (
    <Message
      title="New Dashboard"
      icon={<PollIcon className={styles.titleIcon} />}
      content={
        <>
          <Typography.Paragraph>
            <Avatar size="small" src={message.content.createdBy?.avatar}>
              {initials(message.content.createdBy?.full_name)}
            </Avatar>
            &nbsp;{message.content.createdBy?.full_name}&nbsp;has shared a dashboard&nbsp;
            <b>{message.content.dashboard.name}</b>
            &nbsp;with you.
          </Typography.Paragraph>
        </>
      }
      button={
        <Button
          type="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            ackMessages({
              variables: {
                messageIds: [message.id],
              },
            });
            navigate(`/brainpower/dashboard?dashboard=${message.content.dashboard.id}`);
          }}
        >
          Explore it
        </Button>
      }
      {...props}
    />
  );
};

const ReportMessage = (props) => {
  const { message, ackMessages } = props;

  const navigate = useNavigateWithSearch();

  let content;
  if (message.content.export.status === ExportStatuses.FAILED_TO_EXTRACT) {
    content = (
      <>
        An error occurred while running <b>{message.content.export.name}</b> export.
      </>
    );
  } else {
    switch (message.content.export.sendingMethod) {
      case SendingMethods.SFTP:
        content = (
          <>
            A new report <b>{message.content.export.name}</b> is available and has been sent via sftp.
          </>
        );
        break;
      default:
        content = (
          <>
            A new report <b>{message.content.export.name}</b> is available.
          </>
        );
    }
  }
  return (
    <Message
      title="Export"
      icon={
        <InboxArrowDownIcon
          className={classnames(styles.titleIcon, {
            [styles.error]: message.content.export.status === ExportStatuses.FAILED_TO_EXTRACT,
            [styles.success]: message.content.export.status === ExportStatuses.SUCCESS,
          })}
        />
      }
      content={content}
      button={
        <Button
          type="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            ackMessages({
              variables: {
                messageIds: [message.id],
              },
            });
            navigate(`/brainpower/exports/${message.content.export.id}`);
          }}
        >
          Go to export detail
        </Button>
      }
      {...props}
    />
  );
};

const AnnouncementMessage = (props) => {
  const { message, ackMessages, preview } = props;

  const { type, title, content, body } = message.content;

  const [modal, contextHolder] = Modal.useModal();

  const { icon: Icon, color } = announcementTypes[type] ?? announcementTypes[AnnouncementTypes.ALERT];

  const config = {
    icon: <Icon style={{ color }} />,
    title,
    content: <Markdown>{body}</Markdown>,
    width: '80%',
    bodyStyle: { maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' },
    onOk: () => {
      if (preview) return;
      ackMessages({
        variables: {
          messageIds: [message.id],
        },
      });
    },
  };

  return (
    <Message
      title={title}
      icon={<Icon style={{ color }} className={styles.titleIcon} />}
      content={
        <>
          {content}
          {contextHolder}
        </>
      }
      button={
        body && (
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              modal.info(config);
            }}
          >
            Details
          </Button>
        )
      }
      preview={preview}
      {...props}
    />
  );
};

export { AnnouncementMessage, DashboardMessage, ReportMessage };
