import React from 'react';
import { useCan } from '../../../../../../contexts/ability.context';
import { Block } from '../../../../Common/Sider';
import Item from '../../../../Common/SiderList/Item';
import WidgetTypes, { list as widgetTypeList, defaultWidgets } from '../../constants/widgetTypes';
import { useDashboard, useDroppingItem } from '../../dashboard.context';

const maxWidget = 14;

const WidgetAddableList = () => {
  const can = useCan();
  const { dashboard } = useDashboard();

  const [, setDroppingItem] = useDroppingItem();

  const dashboardFull = dashboard?.layout.length >= maxWidget;

  return (
    <Block
      key="add-chart"
      title="Add a chart"
      description={dashboardFull ? `Dashboard is full (max ${maxWidget})` : 'Drag and drop directly into the grid'}
    >
      {widgetTypeList.map((type) => {
        let disabled = dashboardFull;

        if (type.id === WidgetTypes.financialAnalysis && !can('read', 'financial-operation')) {
          disabled = true;
        }

        return (
          <div
            key={type.id}
            className="droppable-element"
            draggable={!disabled}
            // eslint-disable-next-line react/no-unknown-property
            unselectable="on"
            // this is a hack for firefox
            // Firefox requires some kind of initialization
            // which we can do by adding this attribute
            // @see https:bugzilla.mozilla.org/show_bug.cgi?id=568313
            onDragStart={(e) => {
              setDroppingItem({
                i: 'temp',
                w: defaultWidgets[type.id].w,
                h: defaultWidgets[type.id].h,
                ...type,
              });
              e.dataTransfer.setData(
                'text/plain',
                JSON.stringify({
                  type: type.id,
                  ...defaultWidgets[type.id],
                }),
              );
            }}
            id={type.id}
            style={{ cursor: disabled ? 'not-allowed' : 'grab' }}
          >
            <Item
              id={type.id}
              label={type.label}
              title={type.label}
              icon={type.icon}
              isDraggable={!disabled}
              isDisabled={disabled}
            />
          </div>
        );
      })}
    </Block>
  );
};

export default WidgetAddableList;
