import React from 'react';
import { Tag } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import {useLazyQuery, useQuery} from '@apollo/client';
import TRANSACTION_STATUSES, { TAGS } from '../../../../../../../constants/TRANSACTION_STATUSES';
import TableTile, {renderAmount, renderCopyable, renderDatetime, renderText} from '../../../Tile/Table/Table';
import { orderPaymentOperationsQuery, activityPaymentOperationsQuery } from './query';
import PaymentOperationsSkeleton from './PaymentOperationsSkeleton';
import styles from './PaymentOperations.module.scss';
import {compareByProp} from '../../../../../../../util/array';

const renderResultStatus = (value, context) => {
  let tag = TRANSACTION_STATUSES[value]?.tag || TAGS.pending;
  if (context.request_status?.includes('failed')) {
    tag = TAGS.ko;
  }
  return (
    <Tag color={tag.color} key={`result-status-${tag.id}`}>
      {tag.label}
    </Tag>
  );
};

const PaymentOperations = ({ onHeightReady }) => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityPaymentOperationsQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order:  null } } = useQuery(
    orderPaymentOperationsQuery,
    {
      variables: {
        orderId,
      },
      fetchPolicy: "cache-and-network",
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const transactions = orderStack?.transactions ?? orderInsights?.transactions;

  if (!transactions) return <PaymentOperationsSkeleton />;

  const getResultDateValue = (operation) =>
    operation.result_date && operation.result_response && operation.result_response !== 'pending'
      ? operation.result_date
      : operation.request_date;

  const columns = [
    {
      title: 'Date',
      dataIndex: 'result_date',
      key: 'result_date',
      render: (value, context) => renderDatetime(getResultDateValue(context)),
      sorter: (a, b) => moment(getResultDateValue(a)).diff(moment(getResultDateValue(b))),
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: renderText,
    },
    {
      title: 'Result',
      dataIndex: 'result_status',
      key: 'result_status',
      render: renderResultStatus,
      width: 100,
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: renderAmount,
    },
    {
      title: 'Transaction',
      key: 'transaction',
      dataIndex: 'transaction',
      render: renderCopyable,
    },
    {
      title: 'Reference',
      key: 'id',
      dataIndex: 'id',
      render: renderCopyable,
    },
  ];

  const dataSource = transactions.reduce(
    (memo, trx) => [...memo, ...trx.operations.map((o) => ({ key: o.id, transaction: trx.id, ...o }))],
    [],
  );

  return (
    <TableTile
      name="payment-operations"
      title="Payment operations"
      columns={columns}
      dataSource={dataSource.sort(compareByProp('request_date'))}
      onHeightReady={onHeightReady}
      rowClassName={styles.tableRow}
      scroll={{ x: 1000 }}
    />
  );
};

export default PaymentOperations;
