import React from 'react';
import {useLazyQuery, useQuery as useApolloQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import REFUND_REASONS from "norbr-shared-lib/constants/transaction/refundReasons";
import PAYMENT_CHANNELS from '../../../../../../../constants/PAYMENT_CHANNELS';
import TRANSACTION_STATUSES from '../../../../../../../constants/TRANSACTION_STATUSES';
import { useProgramManager } from '../../../../../../../contexts/programManager.context';
import InfoTile, { InfoAmountRow, InfoDateTimeRow, InfoIconRow, InfoTextRow } from '../../../Tile/Info/Info';
import OrderStatusSkeleton from './OrderStatusSkeleton';
import { orderStatusQuery, activityStatusQuery } from './query';

const OrderStatus = () => {
  const { orderId } = useParams();
  const programManager = useProgramManager();
  const { t } = useTranslation();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityStatusQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderStatusQuery, {
    variables: {
      orderId,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: data => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;
  const transaction = order?.last_transaction;

  if (!transaction) return <OrderStatusSkeleton />;

  const status = transaction.status && t(`order.detail.status.${transaction.status}`);
  const authorizedAmount = transaction.is_authorized && !transaction?.is_cancelled ? transaction.amount : 0;

  const refundReason =
    [TRANSACTION_STATUSES.refund_requested.value, TRANSACTION_STATUSES.refund_successful.value].includes(
      transaction.status,
    ) && transaction.refund_reason
      ? REFUND_REASONS[transaction.refund_reason].label
      : null;

  return (
    <InfoTile name="order-status" title="Order status">
      <InfoTextRow label="Status" value={status} />
      {refundReason && <InfoTextRow label="Refund reason" value={refundReason} />}
      <InfoTextRow label="Merchant reference" value={transaction.order_merchant_id} />
      <InfoTextRow label={`${order.program_manager?.displayName} ID`} value={order._id} />
      <InfoAmountRow label="Authorized amount" value={authorizedAmount} currency={transaction.currency} />
      <InfoAmountRow label="Captured amount" value={transaction.captured_amount} currency={transaction.currency} />
      <InfoDateTimeRow label="Scheduled capture date" value={transaction.scheduled_capture_date} />
      <InfoAmountRow label="Refunded amount" value={transaction.refunded_amount} currency={transaction.currency} />
      <InfoAmountRow
        label="Charged back amount"
        value={transaction.chargeback_amount}
        currency={transaction.currency}
      />
      <InfoTextRow label="Authentication status" value={transaction.authentication_result} />
      <InfoIconRow
        label="Payment channel"
        value={PAYMENT_CHANNELS[transaction.payment_channel]?.label}
        icon={PAYMENT_CHANNELS[transaction.payment_channel]?.icon}
      />
      <InfoDateTimeRow label="Creation date" value={transaction.createdAt} />
      <InfoDateTimeRow label="Last update" value={transaction.updatedAt} />
    </InfoTile>
  );
};

export default OrderStatus;
