import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Progress, Table } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useBoolean, useList } from 'react-use';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { reconciliationCandidatesQuery } from './query';
import { buildColumnProps } from '../../Common/AdvancedTable/buildColumns';
import fields from '../../Common/constants/fields';
import useStatePagination from '../../hooks/useStatePagination';
import styles from './MatcherDrawer.module.scss';

const PAGE_SIZE = 5;

const ReconciliationCandidateList = ({ onSelectReconciliationCandidate }) => {
  const { transactionId } = useParams();

  const [dataSource, { push, reset }] = useList([]);
  const [noMoreCandidates, setNoMoreCandidates] = useBoolean(false);

  const { nextPage, offset, limit } = useStatePagination(PAGE_SIZE);

  // const [fetch, { loading, error }] = useQuery(reconciliationCandidatesQuery, {
  const { loading, error } = useQuery(reconciliationCandidatesQuery, {
    variables: { transactionId, offset, limit },
    skip: !transactionId,
    onCompleted: (data) => {
      push(
        ...data?.reconciliationCandidates.map((row) => ({
          [Fields.PSP_TRANSACTION_ID]: row.psp_transaction_id,
          [Fields.TRANSACTION_CREATION_DATE]: row.action_date,
          [Fields.PAYMENT_METHOD]: row.paymentMethod.api_name,
          [Fields.TRANSACTION_AMOUNT]: row.amount,
          [Fields.CURRENCY]: row.currency.id,
          [Fields.PAYIN_PARTNER]: row.partner.api_name,
          score: (row.score * 20).toFixed(0),
        })),
      );
      if (!(data?.reconciliationCandidates?.length === PAGE_SIZE)) {
        setNoMoreCandidates(true);
      }
    },
  });

  useEffect(() => {
    reset();
    setNoMoreCandidates(false);
  }, [transactionId]);

  return (
    <div
      style={{
        margin: 16,
      }}
    >
      <Table
        className={classnames(styles.table, { [styles.tableReconciled]: true })}
        rowClassName={styles.tableRowShadow}
        size="small"
        columns={[
          {
            dataIndex: Fields.PSP_TRANSACTION_ID,
            title: 'ID PARTNER',
            // width: 154,
            ...buildColumnProps(fields[Fields.PSP_TRANSACTION_ID]),
          },
          {
            dataIndex: Fields.TRANSACTION_CREATION_DATE,
            title: 'CREATION',
            // width: 180,
            ...buildColumnProps(fields[Fields.TRANSACTION_CREATION_DATE]),
          },
          {
            dataIndex: Fields.PAYMENT_METHOD,
            title: 'PM',
            // width: 56,
            ...buildColumnProps(fields[Fields.PAYMENT_METHOD]),
          },
          {
            dataIndex: Fields.TRANSACTION_AMOUNT,
            title: 'AMOUNT',
            // width: 100,
            ...buildColumnProps(fields[Fields.TRANSACTION_AMOUNT]),
          },
          { dataIndex: Fields.PAYIN_PARTNER, title: 'PARTNER', ...buildColumnProps(fields[Fields.PAYIN_PARTNER]) },
          {
            dataIndex: 'score',
            title: '%',
            align: 'center',
            fixed: 'right',
            width: 44,
            render: (value) => (
              <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={value}
                width={28}
              />
            ),
          },
          {
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (_, record) => (
              <Button
                type="primary"
                size="small"
                icon={<LinkOutlined />}
                title="Link to transaction"
                onClick={() => onSelectReconciliationCandidate(record)}
              >
                LINK
              </Button>
            ),
          },
        ]}
        loading={loading}
        dataSource={dataSource}
        pagination={false}
        rowKey={Fields.PSP_TRANSACTION_ID}
        // scroll={{ x: 800 }}
        footer={() => (
          <div style={{ textAlign: 'center' }}>
            {noMoreCandidates ? (
              <span>All candidate transactions are displayed.</span>
            ) : (
              <Button className={styles.button} onClick={nextPage}>
                SEE MORE
              </Button>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default ReconciliationCandidateList;
