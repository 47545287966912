import { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useMe } from '../../../../contexts/me.context';
import usePage from './usePage';

const UpdateBrainpowerSettingsMutation = gql`
  mutation UpdateBrainpowerSettings($brainpowerSettings: BrainpowerSettingsInput!) {
    updateBrainpowerSettings(brainpowerSettings: $brainpowerSettings) {
      id
      brainpower {
        dashboard {
          favorite
          dashboards {
            id
            name
            layout
            widgets
            createdBy {
              id
              full_name
              avatar
            }
          }
        }
        orderList {
          views
          favorite
        }
        transactionList {
          views
          favorite
        }
        matcher {
          views
          favorite
        }
        matcherCashierSummary {
          views
          favorite
        }
        matcherCashierDetails {
          views
          favorite
        }
        matcherPaymentPartner {
          views
          favorite
        }
        overview {
          views
          favorite
        }
        financialOperationList {
          views
          favorite
        }
        checkoutList {
          views
          favorite
        }
        notificationList {
          views
          favorite
        }
        activitySearch {
          searches
          favorite
        }
        notificationSearch {
          searches
          favorite
        }
        notificationDetail {
          layout
        }
        logList {
          views
          favorite
        }
        logSearch {
          searches
          favorite
        }
        merchantAccountList {
          views
          favorite
        }
        merchantAccountSearch {
          searches
          favorite
        }
        merchantCompanyList {
          views
          favorite
        }
        merchantCompanySearch {
          searches
          favorite
        }
        merchantUserList {
          views
          favorite
        }
        merchantUserSearch {
          searches
          favorite
        }
        programManagerUserList {
          views
          favorite
        }
        programManagerUserSearch {
          searches
          favorite
        }
        internalUserList {
          views
          favorite
        }
        internalUserSearch {
          searches
          favorite
        }
      }
    }
  }
`;

const useBrainpowerSettings = (key, defaultValue) => {
  const me = useMe();
  const page = usePage();

  const [mutate] = useMutation(UpdateBrainpowerSettingsMutation);

  const value = useMemo(() => me?.brainpower?.[key] ?? defaultValue, [me, page]);
  const update = (newValue) =>
    mutate({
      variables: {
        brainpowerSettings: {
          [key]: newValue,
        },
      },
    });

  return [value, update];
};

export const useBrainpowerDashboardSettings = () => {
  const page = usePage();
  return useBrainpowerSettings(page.name, { favorite: null, dashboards: [] });
};

export const useBrainpowerViewSettings = () => {
  const page = usePage();
  return useBrainpowerSettings(page.name, { favorite: 'default', views: [] });
};

export const useBrainpowerSearchSettings = () => {
  const page = usePage();
  return useBrainpowerSettings(page.search.id, { favorite: 'default', searches: [] });
};

export default useBrainpowerSettings;
