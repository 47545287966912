import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Tag, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import NOTIFICATION_STATUSES from 'norbr-shared-lib/constants/notification/status';
import { isModeAdmin } from '../../../../../../../constants/mode';
import TRANSACTION_STATUSES from '../../../../../../../constants/TRANSACTION_STATUSES';
import { useCan } from '../../../../../../../contexts/ability.context';
import { compareByProp } from '../../../../../../../util/array';
import useNavigateWithSearch, { Link } from '../../../../../../../util/navigate';
import { middleEllipsis } from '../../../../../../../util/string';
import TableTile, { renderDatetime } from '../../../Tile/Table/Table';
import NotificationSkeleton from './NotificationSkeleton';
import { orderNotificationQuery, activityNotificationQuery } from './query';

const Notification = ({ onHeightReady }) => {
  const can = useCan();
  const { orderId } = useParams();
  const navigate = useNavigateWithSearch();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityNotificationQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderNotificationQuery, {
    variables: {
      orderId,
    },
    errorPolicy: 'all',
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const order = orderStack ?? orderInsights;

  if (!order) return <NotificationSkeleton />;
  const columns = [
    {
      title: 'Date',
      key: 'creation_date',
      sorter: compareByProp('createdAt'),
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      render: (_, notification) => renderDatetime(notification.createdAt),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, notification) => (
        <Tag color={NOTIFICATION_STATUSES[notification.status]?.color}>
          {NOTIFICATION_STATUSES[notification.status]?.label}
        </Tag>
      ),
    },
    {
      title: 'Trigger',
      key: 'trigger',
      render: (_, notification) => TRANSACTION_STATUSES[notification.payload?.status]?.label ?? 'n/a',
    },
    {
      title: 'Request',
      key: 'request',
      render: (_, notification) =>
        notification.payload?.request_id && (
          <Typography.Text copyable={{ text: notification.payload?.request_id }}>
            {middleEllipsis(notification.payload?.request_id)}
          </Typography.Text>
        ),
    },
    ...(can('read', 'webhook')
      ? [
          {
            title: 'Webhook',
            key: 'webhook',
            render: (_, notification) => {
              if (!notification.webhook) return null;
              return isModeAdmin ? (
                <Link to={`/merchant-accounts/${notification.merchantAccount?.id}#tile-ma-webhooks`}>
                  {notification.webhook?.name}
                </Link>
              ) : (
                <Link
                  to={`/router/notifications?api=notifications-${notification.webhook?.id}&maccount=${notification.merchantAccount?.id}`}
                >
                  {notification.webhook?.name}
                </Link>
              );
            },
          },
        ]
      : []),
    {
      title: 'Transaction',
      key: 'transaction',
      render: (_, notification) => (
        <Typography.Text copyable={{ text: notification.payload?.transaction_id }}>
          {middleEllipsis(notification.payload?.transaction_id)}
        </Typography.Text>
      ),
    },
    {
      title: 'URL',
      key: 'url',
      render: (_, notification) => notification.url,
    },
    ...(can('read', 'notification')
      ? [
          {
            key: 'notification',
            render: (_, notification) => (
              <Button
                type="link"
                icon={<ArrowRightOutlined />}
                onClick={() => navigate(`/brainpower/notifications/${notification._id}`)}
                size="small"
              >
                {middleEllipsis(notification._id)}
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <TableTile
      name="notification"
      title="Notifications"
      columns={columns}
      dataSource={order.notifications}
      onHeightReady={onHeightReady}
      scroll={{ x: 'max-content' }}
      pagination={{
        showSizeChanger: false,
        hideOnSinglePage: true,
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} notifications`,
      }}
    />
  );
};

export default Notification;
