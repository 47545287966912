import React from 'react';
import {Button, Form, Input, Modal, Radio, Space} from 'antd';
import WITHDRAW_LIST from 'norbr-shared-lib/constants/transaction/withdrawReasons/list';
import {useParams} from 'react-router-dom';
import {useApolloClient, useMutation} from '@apollo/client';
import {reconcileMutation} from '../query';

const WithdrawModal = ({ onCancel, isOpen }) => {
  const { transactionId } = useParams();

  const [form] = Form.useForm();

  const client = useApolloClient();
  const [reconcile, { loading }] = useMutation(reconcileMutation);

  return (
    <Modal
      title='TO WITHDRAW'
      open={isOpen}
      footer={
        <div style={{display: 'flex'}}>
          <Button style={{width: '100%'}} onClick={onCancel} disabled={loading}>
            CANCEL
          </Button>
          <Button type="primary" style={{width: '100%'}} onClick={form.submit} loading={loading}>
            SAVE
          </Button>
        </div>
      }
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={({reason, note}) => reconcile({
          variables: {
            type: 'withdraw',
            transactionId,
            reason,
            note,
          },
        }).then(() => {
          client.refetchQueries({
            include: ['MatcherOrders', 'ActivityMatcher'],
          }).then(onCancel)
        })}
      >
        <Form.Item name="reason" label="Withdraw reason">
          <Radio.Group>
            <Space
              direction='vertical'
            >
              {WITHDRAW_LIST.map(({ id, label }) => (
                <Radio
                  value={id}
                >
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="note" label="Notes">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default WithdrawModal;