import React, { useContext, useEffect, useState } from 'react';
import { defaultTheme, applyTheme } from '../util/theme';
import { useMe } from './me.context';
import { useProgramManager } from './programManager.context';
import AppLoading from '../AppLoading';

export const ThemeContext = React.createContext(undefined);

export const useTheme = () => useContext(ThemeContext);

const ThemeProvider = (props) => {
  const { children } = props;
  const programManager = useProgramManager();
  const me = useMe();

  const [themeApplied, setThemeApplied] = useState(false);

  const programManagerTheme = {
    primaryColor: programManager?.theme.primaryColor || defaultTheme.primaryColor,
    secondaryColor: programManager?.theme.secondaryColor || defaultTheme.secondaryColor,
    loader: programManager?.theme?.loader,
  };

  const companyTheme = {
    primaryColor: me?.companies?.[0]?.company?.theme.primaryColor || programManagerTheme.primaryColor,
    secondaryColor: me?.companies?.[0]?.company?.theme.secondaryColor || programManagerTheme.secondaryColor,
    loader: programManagerTheme.loader,
  };

  const userTheme = {
    primaryColor: me?.theme.primaryColor || companyTheme.primaryColor,
    secondaryColor: me?.theme.secondaryColor || companyTheme.secondaryColor,
    loader: programManagerTheme.loader,
  };

  // Apply Theme
  // user > company > default
  useEffect(() => {
    applyTheme(userTheme);
    setThemeApplied(true);
  }, []);

  if (!themeApplied) return <AppLoading />;

  return (
    <ThemeContext.Provider value={{ userTheme, companyTheme, programManagerTheme }}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
