import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Row,
  Segmented,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import classnames from 'classnames';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import { Route, Routes, useParams } from 'react-router-dom';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import ReconciliationTypes from 'norbr-shared-lib/constants/transaction/reconciliationTypes/enum';
import useNavigateWithSearch from '../../../../../util/navigate';
import { useGdpr, useTourMessage } from '../../../../../contexts/me.context';
import ErrorBoundary from '../../../Common/ErrorBoundary/ErrorBoundary';
import { TableDisplay, tableDisplayList } from '../../Common/constants/tableDisplays';
import { TargetEntity } from '../../Common/constants/targetEntities';
import useDrawer from '../../Common/useDrawer';
import Drawers from '../../Common/constants/drawers';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import useTableDisplay from '../../hooks/useTableDisplay';
import SearchFilterTags from '../../Common/SearchFilterTags/SearchFilterTags';
import buildColumns, { renderAmountCell } from '../../Common/AdvancedTable/buildColumns';
import useColumnSet from '../../hooks/useColumnSet';
import fields from '../../Common/constants/fields';
import MatcherDrawer from '../MatcherDrawer/MatcherDrawer';
import useMatcherOrderListQuery from './useMatcherOrderListQuery';
import styles from './MatcherTable.module.scss';
import Loader from '../../../Common/Loader/Loader';


const cellStyle = {
  fontWeight: 'bolder',
  padding: '2px 8px',
  borderRadius: 4,
  width: 'max-content',
  display: 'inline-block',
};

const SuccessCell = ({ children, active = false }) => (
  <div
    style={{
      ...cellStyle,
      backgroundColor: active ? '#29cc7e' : 'rgba(85, 201, 133, .1)',
      color: active ? '#ffffff' : '#55C985',
    }}
  >
    {children}
  </div>
);
const PendingCell = ({ children }) => (
  <div
    style={{
      ...cellStyle,
      backgroundColor: 'rgba(250, 149, 2, .1)',
      color: '#FA9502',
    }}
  >
    {children}
  </div>
);
const FailCell = ({ children }) => (
  <div
    style={{
      ...cellStyle,
      backgroundColor: 'rgba(255, 79, 79, .1)',
      color: '#FF4F4F',
    }}
  >
    {children}
  </div>
);

const MatcherTable = () => {
  const [showMessage, consumeMessage] = useTourMessage('matcher_welcome');

  const navigate = useNavigateWithSearch();

  const { orderId, transactionId } = useParams();

  const [, setDrawer] = useDrawer();

  const [height, setHeight] = useState();

  const [columnSet] = useColumnSet();

  const [tableDisplay, setTableDisplay] = useTableDisplay();
  const { page, pageSize, setPage } = usePagination();
  const [sort, setSort] = useSort();
  const isGdpr = useGdpr();

  const columns = buildColumns(columnSet, sort, tableDisplay, TargetEntity.ORDER, isGdpr);

  const customColumns = columns.map((c) => {
    switch (c.key) {
      case Fields.ORDER_UNRECONCILED_AMOUNT:
        return {
          ...c,
          align: 'right',
          render: (value, row) => {
            if (tableDisplay === TableDisplay.TEXT) return renderAmountCell(fields[c.key], c)(value, row);
            // ORDER
            if (row.children) {
              const reconciledTrx = row.children.filter((trx) => trx[Fields.IS_RECONCILED]).length;
              const pendingTrx = row.children.filter(
                (trx) => trx[Fields.RECONCILIATION_TYPE] === ReconciliationTypes.PENDING,
              ).length;
              // All matched
              if (reconciledTrx === row.children.length)
                return <SuccessCell active>{renderAmountCell(fields[c.key], c)(value, row)}</SuccessCell>;
              // All matched or pending
              if (reconciledTrx + pendingTrx === row.children.length)
                return <PendingCell>{renderAmountCell(fields[c.key], c)(value, row)}</PendingCell>;
              // Some unmatched and not pending
              return <FailCell>{renderAmountCell(fields[c.key], c)(value, row)}</FailCell>;
            }
            // TRANSACTION
            if (row[Fields.IS_RECONCILED])
              return <SuccessCell>{renderAmountCell(fields[c.key], c)(value, row)}</SuccessCell>;
            if (row[Fields.RECONCILIATION_TYPE] === ReconciliationTypes.PENDING)
              return <PendingCell>{renderAmountCell(fields[c.key], c)(value, row)}</PendingCell>;
            return <FailCell>{renderAmountCell(fields[c.key], c)(value, row)}</FailCell>;
          },
        };
      case Fields.ORDER_IS_RECONCILED:
        return {
          ...c,
          align: 'left',
          sorter: undefined,
          sortOrder: undefined,
          sortDirections: undefined,
          render: (value, row) => {
            if (tableDisplay === TableDisplay.TEXT) return value.toString();
            // ORDER
            if (row.children) {
              const reconciledTrx = row.children.filter((trx) => trx[Fields.IS_RECONCILED]).length;
              const pendingTrx = row.children.filter(
                (trx) => trx[Fields.RECONCILIATION_TYPE] === ReconciliationTypes.PENDING,
              ).length;
              // All matched
              if (reconciledTrx === row.children.length)
                return (
                  <SuccessCell active>
                    <BookmarkIcon />
                    {row.children.length} / {row.children.length}
                  </SuccessCell>
                );
              // All matched or pending
              if (reconciledTrx + pendingTrx === row.children.length)
                return (
                  <PendingCell>
                    <BookmarkIcon />
                    {reconciledTrx} / {row.children.length}
                  </PendingCell>
                );
              // Some unmatched and not pending
              return (
                <FailCell>
                  <BookmarkIcon />
                  {reconciledTrx} / {row.children.length}
                </FailCell>
              );
            }
            // TRANSACTION
            if (row[Fields.IS_RECONCILED])
              return (
                <SuccessCell>
                  <BookmarkIcon />1
                </SuccessCell>
              );
            if (row[Fields.RECONCILIATION_TYPE] === ReconciliationTypes.PENDING)
              return (
                <PendingCell>
                  <BookmarkIcon />0
                </PendingCell>
              );
            return (
              <FailCell>
                <BookmarkIcon />0
              </FailCell>
            );
          },
        };
      default:
        return c;
    }
  });

  const handleTableChange = (_p, _f, sorter) => {
    // handle single sort, default from favorite view
    if (Array.isArray(sorter)) {
      setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
    } else if (sorter.order) {
      setSort([{ field: sorter.field, sortOrder: sorter.order }]);
    } else {
      setSort();
    }
  };

  const { result, total, loading, error, loadingCount } = useMatcherOrderListQuery();

  return (
    <>
      <Layout>
        <Layout.Header className={styles.layoutHeader}>
          <Row gutter={24} style={{ position: 'relative' }}>
            <Col>
              <Space>
                Results
                <Tag style={{ color: 'darkgrey' }}>{loadingCount ? <Loader /> : total}</Tag>
              </Space>
            </Col>
            <Col flex="1" style={{ position: 'initial' }}>
              <SearchFilterTags />
            </Col>
          </Row>
        </Layout.Header>
        {showMessage && (
          <Alert
            message="Click on an incomplete order to view its candidate transactions. Use the ‘Link’ button to pair them, or click ‘See more’ to view all unmatched transactions."
            banner
            closable
            type="info"
            showIcon={false}
            onClose={consumeMessage}
            style={{
              fontSize: 11,
              textAlign: 'center',
              height: 56,
              backgroundColor: '#171717',
              color: 'white',
            }}
            className={styles.alertMessage}
          />
        )}
        <Layout
          id="matcher-table"
          className={styles.layoutContent}
          style={{ backgroundColor: 'white', boxShadow: '1px 1px 4px #d0d0d0', zIndex: 20 }}
        >
          <Layout.Content>
            <Row justify="space-between" style={{ padding: '8px 16px', alignItems: 'center' }}>
              <Col>
                <Col style={{ display: 'flex', gap: 8, lineHeight: '40px' }}>
                  <ShoppingIcon style={{ fontSize: '24px' }} />
                  ORDERS
                </Col>
              </Col>
            </Row>
            <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
              {height && (
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      className={styles.empty}
                      style={{ height: height - 74 }}
                      image={loading ? null : undefined}
                      description={(() => {
                        if (loading) {
                          return null;
                        }
                        if (error) {
                          return (
                            <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                          );
                        }
                        return [
                          <div key="no-results-found">No results found</div>,
                          <Button
                            key="adjust-filters"
                            type="link"
                            icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                            onClick={() => setDrawer(Drawers.SEARCH)}
                          >
                            Adjust filters
                          </Button>,
                        ];
                      })()}
                    />
                  )}
                >
                  <Table
                    className={classnames(styles.table)}
                    rowClassName={(record) => {
                      switch (record[Fields.ORDER_ID]) {
                        case transactionId:
                          return styles.tableRowSelected;
                        case orderId:
                          return styles.tableRowOpened;
                        default:
                          return styles.tableRow;
                      }
                    }}
                    columns={customColumns}
                    dataSource={result}
                    loading={
                      loading && {
                        size: 'large',
                      }
                    }
                    size="small"
                    scroll={{ x: 'max-content', y: height - 96 }} // available height - head row height
                    pagination={false}
                    onRow={(record) => ({
                      onClick: () => {
                        if (!record.children)
                          navigate(`/brainpower/matcher-engine/${record.parent_id}/${record.transaction_id}`);
                      },
                    })}
                    rowKey={(record) => (record.children ? record[Fields.ORDER_ID] : record[Fields.TRANSACTION_ID])}
                    expandable={{
                      showExpandColumn: false,
                      expandRowByClick: true,
                      expandedRowKeys: orderId ? [orderId] : [],
                      onExpand: (expanded, record) =>
                        expanded
                          ? navigate(`/brainpower/matcher-engine/${record[Fields.ORDER_ID]}`)
                          : navigate('/brainpower/matcher-engine'),
                    }}
                    onChange={handleTableChange}
                  />
                </ConfigProvider>
              )}
            </ReactHeight>
          </Layout.Content>
          <Layout.Footer className={styles.layoutFooter}>
            <Row gutter={24} justify="space-between">
              <Col>
                <Space>
                  <Pagination
                    className={styles.pagination}
                    total={total}
                    current={page}
                    pageSize={pageSize}
                    onChange={setPage}
                    showSizeChanger={false}
                    showLessItems
                    size="small"
                    hideOnSinglePage
                  />
                  <Select
                    value={pageSize}
                    onSelect={(value) => setPage(1, value)}
                    options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                    bordered={false}
                  />
                </Space>
              </Col>
              <Col>
                <Segmented
                  value={tableDisplay}
                  onChange={setTableDisplay}
                  options={tableDisplayList}
                  style={{ fontSize: 18 }}
                />
              </Col>
            </Row>
          </Layout.Footer>
        </Layout>
      </Layout>

      <MatcherDrawer />
    </>
  );
};

const MatcherRouter = () => (
  <ErrorBoundary>
    <Routes>
      <Route index element={<MatcherTable />} />
      <Route path=":orderId" element={<MatcherTable />} />
      <Route path=":orderId/:transactionId" element={<MatcherTable />} />
    </Routes>
  </ErrorBoundary>
);

export default MatcherRouter;
