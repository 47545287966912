export enum WidgetOptionTypes {
  Boolean,
  Select,
  MultiSelect,
  Slider,
}

enum WidgetOptions {
  logarithmicAxis = 'logarithmic_axis',
  sliceItems = 'slice_items',
  customerIdentifier = 'customer_identifier',
  complexAnalysisPeriod = 'complex_analysis_period',
  orientation = 'orientation',
}

export const config = {
  [WidgetOptions.logarithmicAxis]: {
    name: WidgetOptions.logarithmicAxis,
    label: 'Logarithmic Y axis',
    description: 'Apply logarithmic scale on Y axis',
    type: WidgetOptionTypes.Boolean,
  },
  [WidgetOptions.sliceItems]: {
    name: WidgetOptions.sliceItems,
    label: (value: number) => `Display ${value} items`,
    description: 'Limit the number of displayed dimension items',
    type: WidgetOptionTypes.Slider,
  },
  [WidgetOptions.customerIdentifier]: {
    name: WidgetOptions.customerIdentifier,
    label: 'Customer identifier',
    description: 'Field to use as customer identifier',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'customer_email', label: 'Customer email' },
      { value: 'customer_id', label: 'Customer ID' },
    ],
  },
  [WidgetOptions.complexAnalysisPeriod]: {
    name: WidgetOptions.complexAnalysisPeriod,
    label: 'Period',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'week', label: 'Week' },
      { value: 'month', label: 'Month' },
      { value: 'quarter', label: 'Quarter' },
      { value: 'year', label: 'Year' },
    ],
  },
  [WidgetOptions.orientation]: {
    name: WidgetOptions.orientation,
    label: 'Orientation',
    type: WidgetOptionTypes.Select,
    options: [
      { value: 'horizontal', label: 'Horizontal' },
      { value: 'vertical', label: 'Vertical' },
    ],
  },
};

export default WidgetOptions;
