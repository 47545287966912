import { Button, Col, Drawer, Row, Space, Typography } from 'antd';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import useNavigateWithSearch from '../../../../../util/navigate';
import ReconciliationCandidateList from './ReconciliationCandidateList';
import { activityMatcherQuery } from './query';
import ReconciliationModal from './ReconciliationModal';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import { useCan } from '../../../../../contexts/ability.context';
import MatcherOrderDescription from './MatcherOrderDescription';
import LinkedTransactionsTable from './LinkedTransactionsTable';
import styles from './MatcherDrawer.module.scss';
import MatchLaterModal from './MatchLaterModal/MatchLaterModal';
import WithdrawModal from './WithdrawModal/WithdrawModal';

const mapActivityTransaction = (trx) => ({
  [Fields.PSP_TRANSACTION_ID]: trx.psp_transaction_id,
  [Fields.TRANSACTION_CREATION_DATE]: trx.createdAt,
  [Fields.PAYMENT_METHOD]: trx.payment_method_name,
  [Fields.TRANSACTION_AMOUNT]: trx.amount,
  [Fields.CURRENCY]: trx.currency,
  [Fields.PAYIN_PARTNER]: trx.payin_partner_api_name,
});

const MatcherDrawer = () => {
  const breakpoint = useBreakpoint();
  const { orderId, transactionId } = useParams();

  const [reconciliationCandidate, setReconciliationCandidate] = useState();
  const [reconciledNotification, setReconciledNotification] = useState();
  const [matchLater, setMatchLater] = useState();
  const [withdraw, setWithdraw] = useState();

  const can = useCan();

  const { data } = useQuery(activityMatcherQuery, { variables: { orderId }, skip: !orderId });

  const navigate = useNavigateWithSearch();

  const handleClose = () => {
    setReconciliationCandidate(undefined);
    setReconciledNotification(undefined);
    navigate(`/brainpower/matcher-engine/${orderId}`);
  };

  useEffect(() => {
    setReconciliationCandidate(undefined);
    setReconciledNotification(undefined);
  }, [transactionId]);

  if (!transactionId || !data) return null;

  const order = data?.matcherActivity;

  if (orderId && !order) {
    throw new Error(`Order ${orderId} not found.`);
  }

  const transaction = order.transactions.find((trx) => trx.id === transactionId);

  if (transactionId && !transaction) {
    throw new Error(`Transaction ${transactionId} not found.`);
  }

  const displayAsReconciled = order.is_reconciled || transaction.is_reconciled || reconciledNotification;

  return (
    <Drawer
      open={orderId}
      title={
        <Space>
          <BookmarkIcon />
          <Typography.Text>CANDIDATE TRANSACTIONS</Typography.Text>
        </Space>
      }
      headerStyle={{ display: 'none' }}
      contentWrapperStyle={{ width: ['xs', 'sm', 'md', 'lg', 'xl'].includes(breakpoint) ? '100%' : 832 }} // responsive width
      width="100%"
      key="match-drawer"
      getContainer="#matcher-table"
      style={{ position: 'absolute', zIndex: 50 }}
      bodyStyle={{
        padding: 0,
        backgroundColor: '#f6f6f6',
        ...(can('create', 'matcher') && !displayAsReconciled
          ? {
              backgroundImage: 'radial-gradient(#dadada 1px, transparent 0)',
              backgroundSize: '20px 20px',
              backgroundPosition: '-19px -19px',
            }
          : {}),
      }}
      onClose={handleClose}
      destroyOnClose
      closable
      closeIcon={<WindowCloseIcon />}
      mask={false}
      push={false}
    >
      <Row
        justify="space-between"
        style={{
          padding: '8px 8px 8px 16px',
          alignItems: 'center',
          position: 'sticky',
          backgroundColor: '#f6f6f6',
          ...(displayAsReconciled ? { marginBottom: 56 } : {}),
        }}
      >
        <Col style={{ display: 'flex', gap: 8, lineHeight: '40px' }}>
          {!displayAsReconciled && (
            <>
              <BookmarkIcon style={{ fontSize: '24px' }} />
              <Typography.Text>CANDIDATE TRANSACTIONS</Typography.Text>
            </>
          )}
        </Col>
        <Col>
          <Button type="text" size="small" icon={<CloseOutlined />} onClick={handleClose} />
        </Col>
      </Row>

      <MatcherOrderDescription order={order} />

      {can('create', 'matcher') && !transaction.is_withdraw && (
        <div
          style={{
            position: 'relative',
            zIndex: 19,
            backgroundColor: displayAsReconciled ? '#fafafa' : '#979797',
            color: displayAsReconciled ? 'inherit' : 'white',
            boxShadow: '1px 1px 4px #d0d0d0',
            borderRadius: '0 0 6px 6px',
            margin: '-2px 8px 0 8px',
            padding: '10px 12px 8px 12px',
            overflowY: 'hidden',
          }}
        >
          <Row justify="space-between" style={{ alignItems: 'center' }}>
            <Col style={{ display: 'flex', gap: 8, lineHeight: '40px' }}>
              <BookmarkIcon style={{ fontSize: '24px' }} />
              LINKED TRANSACTION
            </Col>
          </Row>
          {/* transaction.is_reconciled -> display transaction */}
          {transaction.is_reconciled &&  (
            <LinkedTransactionsTable dataSource={[transaction].map(mapActivityTransaction)} reconciled />
          )}
          {/* !transaction.is_reconciled && (but) reconciledNotification -> display reconciledNotification (pending reconciliation) */}
          {!transaction.is_reconciled && reconciledNotification && (
            <LinkedTransactionsTable dataSource={[reconciledNotification]} reconciled />
          )}
          {/* !transaction.is_reconciled && (but) reconciledNotification -> display reconciledNotification (pending reconciliation) */}
          {!transaction.is_reconciled && !reconciledNotification && reconciliationCandidate && (
            <LinkedTransactionsTable dataSource={[reconciliationCandidate]} />
          )}
          {/* !transaction.is_reconciled && !reconciledNotification -> display Empty message "Click on ..." */}
          {!transaction.is_reconciled && !reconciledNotification && !reconciliationCandidate && (
            <div
              style={{
                height: 85,
                lineHeight: '82px',
                textAlign: 'center',
                margin: '4px 0',
                borderRadius: 6,
                color: 'white',
                backgroundColor: '#a7a7a7',
              }}
            >
              Click ‘Link’ on a candidate transaction to attach it to the order
            </div>
          )}
        </div>
      )}

      {!displayAsReconciled &&
        can('create', 'matcher') && [
          <ReconciliationCandidateList
            key="reconciliation-candidate-list"
            onSelectReconciliationCandidate={setReconciliationCandidate}
          />,
          <div
            style={{
              textAlign: 'center',
              marginBottom: 16,
            }}
          >
            <Space>
              {can('create', 'matcher-withdraw') && (
                <Button
                  className={classnames(styles.button, styles.action)}
                  onClick={() => setWithdraw(true)}
                >
                  WITHDRAW
                </Button>
              )}
              {transaction.reconciliationType !== 'pending' && (
                <Button
                  className={classnames(styles.button, styles.action)}
                  onClick={() => setMatchLater(true)}
                >
                  MATCH LATER
                </Button>
              )}
            </Space>
          </div>,
          <ReconciliationModal
            key={`reconciliation-modal-${reconciliationCandidate?.psp_transaction_id}`}
            reconciliationCandidate={reconciliationCandidate}
            onCancel={() => setReconciliationCandidate(undefined)}
            onOk={() => {
              setReconciledNotification(reconciliationCandidate);
              setReconciliationCandidate(undefined);
            }}
          />,
          <MatchLaterModal
            isOpen={matchLater}
            onCancel={() => setMatchLater(false)}
          />,
          <WithdrawModal
            isOpen={withdraw}
            onCancel={() => setWithdraw(false)}
          />
        ]}
    </Drawer>
  );
};

export default MatcherDrawer;
