import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import am4themeAnimated from '@amcharts/amcharts4/themes/animated';
import am4theme from '@amcharts/amcharts4/themes/kelly';
import { useTheme } from '@amcharts/amcharts4/core';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import CogBoxIcon from '@2fd/ant-design-icons/lib/CogBox';
import PollIcon from '@2fd/ant-design-icons/lib/Poll';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import SlashForwardIcon from '@2fd/ant-design-icons/lib/SlashForward';
import { Col, Layout, Row, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useLayoutMenu } from '../../../layout.context';
import LoadingPage from '../../Common/Pages/Loading';
import { DrawerContext } from '../Common/useDrawer';
import SearchDrawer from '../Common/SearchDrawer/SearchDrawer';
import Drawers from '../Common/constants/drawers';
import { formatEndUnderline } from '../Common/utils';
import brainpowerStyles from '../Brainpower.module.scss';
import HeaderButton from '../Common/HeaderButton/HeaderButton';
import SearchFilterTags from '../Common/SearchFilterTags/SearchFilterTags';
import CurrencySelector from '../Common/CurrencySelector/CurrencySelector';
import SetUpDrawer from './SetUpDrawer/SetUpDrawer';
import Widget from './Widget/Widget';
import { DashboardContext, useDashboard } from './dashboard.context';
import './dashboard.scss';
import styles from './dashboard.module.scss';
import GranularitySelector from '../Common/GranularitySelector/GranularitySelector';
import { config as widgetTypes } from './constants/widgetTypes';

const GridLayout = WidthProvider(RGL);

// set amchart theme
useTheme(am4themeAnimated);
useTheme(am4theme);

const Dashboard = () => {
  const { dashboard, editable, updateLayout, addWidget } = useDashboard();

  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  const [selectedWidgetId, setSelectedWidgetId] = useState();
  const [droppingItem, setDroppingItem] = useState();

  const [drawerState, setDrawerState] = useState();
  const handleToggleDrawer = (key) => () => setDrawerState(drawerState !== key ? key : undefined);
  const handleCloseDrawer = () => setDrawerState(undefined);

  useEffect(() => {
    setSelectedWidgetId(null);
  }, []);

  // onDrop - create temp widget
  const onDrop = useCallback(
    (layout, item, event) => {
      const widget = JSON.parse(event.dataTransfer.getData('text/plain'));
      const widgetId = `${layout.length}_${widget.type}`;
      addWidget(
        { i: widgetId, ...widget },
        layout.map((element) => ({
          x: element.x,
          y: element.y,
          h: element.h,
          w: element.w,
          minH: element.minH,
          minW: element.minW,
          i: element.i === 'temp' ? widgetId : element.i,
        })),
      );
    },
    [dashboard],
  );

  // select widget by data-id attribute (deselect if undefined)
  const handleClickWidget = useCallback(
    (event) => {
      setSelectedWidgetId(event.currentTarget.getAttribute('data-id'));
      event.stopPropagation();
    },
    [setSelectedWidgetId],
  );

  const layout = useMemo(
    () =>
      dashboard?.layout.map((element) => ({
        ...element,
        isResizable: editable && widgetTypes[dashboard.widgets[element.i]?.type]?.isResizable,
      })),
    [dashboard],
  );

  if (!dashboard) {
    return <LoadingPage />;
  }

  return (
    <DrawerContext.Provider value={[drawerState, setDrawerState]}>
      <DashboardContext.Provider
        value={{
          selectedWidgetId,
          setSelectedWidgetId,
          droppingItem,
          setDroppingItem,
        }}
      >
        <Layout className={classnames(brainpowerStyles.root, styles.siderPageWithFilters)}>
          <PageHeader
            className={brainpowerStyles.pageHeader}
            backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
            onBack={toggleMenu}
            title={
              <Space>
                <PollIcon />
                {formatEndUnderline('Dashboard')}
                <SlashForwardIcon />
                <span style={{ fontSize: 16 }}>{dashboard?.name ?? '...'}</span>
              </Space>
            }
            extra={[
              <HeaderButton
                drawerState={drawerState}
                key={Drawers.SEARCH}
                id={Drawers.SEARCH}
                label="SEARCH"
                icon={FeatureSearchIcon}
                onClick={handleToggleDrawer(Drawers.SEARCH)}
              />,
              <HeaderButton
                drawerState={drawerState}
                key={Drawers.SETUP}
                id={Drawers.SETUP}
                label="DISPLAY"
                icon={CogBoxIcon}
                onClick={handleToggleDrawer(Drawers.SETUP)}
              />,
            ]}
          />
          <Layout>
            <Layout.Header className={styles.layoutHeader}>
              <Row gutter={24} style={{ position: 'relative' }}>
                <Col flex="1" style={{ position: 'initial' }}>
                  <SearchFilterTags />
                </Col>
                <Col>
                  <GranularitySelector />
                </Col>
                <Col>
                  <CurrencySelector />
                </Col>
              </Row>
            </Layout.Header>
            <Layout.Content id="dashboard" className={styles.contentContainer}>
              <div
                className={styles.root}
                // deselect widget
                role="button"
                tabIndex={0}
                onClick={handleClickWidget}
                onKeyPress={handleClickWidget}
              >
                <GridLayout
                  className={styles.gridRoot}
                  layout={layout}
                  margin={[30, 24]} // margin between items
                  containerPadding={[30, 30]} // grid padding
                  rowHeight={36} // height unit
                  cols={12}
                  isBounded
                  isDroppable={editable}
                  isResizable={editable}
                  isDraggable={editable}
                  onDrop={onDrop}
                  droppingItem={droppingItem}
                  onDragStop={updateLayout}
                  onResizeStop={updateLayout}
                >
                  {dashboard.layout.map((element) => (
                    <div
                      key={element.i}
                      // select widget
                      data-id={element.i}
                      role="button"
                      tabIndex={0}
                      onClick={handleClickWidget}
                      onKeyPress={handleClickWidget}
                      className={classnames({
                        [styles.selected]: element.i === selectedWidgetId,
                      })}
                    >
                      {element.i === 'temp' ? (
                        <div className={styles.tempWidget} />
                      ) : (
                        <Widget widget={dashboard.widgets[element.i]} layout={element} />
                      )}
                    </div>
                  ))}
                </GridLayout>
              </div>
            </Layout.Content>
          </Layout>
        </Layout>
        <SearchDrawer open={drawerState === Drawers.SEARCH} onClose={handleCloseDrawer} containerId="dashboard" />
        <SetUpDrawer open={drawerState === Drawers.SETUP} onClose={handleCloseDrawer} />
      </DashboardContext.Provider>
    </DrawerContext.Provider>
  );
};

export default Dashboard;
