import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import CHALLENGE_PREFERENCES from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/challengePreferences';
import SCA_EXEMPTION_REASONS from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons';
import {useLazyQuery} from '@apollo/client';
import AUTHENTICATION_INDICATORS from '../../../../../../../constants/AUTHENTICATION_INDICATORS';
import AUTHENTICATION_RESULTS from '../../../../../../../constants/AUTHENTICATION_RESULTS';
import usePaymentMethods from '../../../../../../../hooks/common/usePaymentMethods';
import InfoTile, { InfoCountryRow, InfoIconRow, InfoImgRow, InfoTextRow } from '../../../Tile/Info/Info';
import { orderPaymentDetailsQuery, activityPaymentDetailsQuery } from './query';
import PaymentDetailsSkeleton from './PaymentDetailsSkeleton';

const PaymentDetails = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(activityPaymentDetailsQuery, {
    variables: {
      orderId,
    }
  });

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(
    orderPaymentDetailsQuery,
    {
      variables: {
        orderId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => !data.order && fetchInsights(),
    },
  );

  const paymentMethods = usePaymentMethods();

  const transaction = orderStack?.last_transaction ?? orderInsights?.last_transaction;

  if (!transaction) return <PaymentDetailsSkeleton />;

  return (
    <InfoTile name="payment-details" title="Payment details">
      <InfoTextRow label="Status" value={transaction.status && t(`order.detail.status.${transaction.status}`)} />
      <InfoTextRow label="Card / Account number" value={transaction.account_number} />
      <InfoTextRow label="Card token" value={transaction.token?.norbr} />
      <InfoImgRow
        label="Payment method"
        value={t(`order.detail.payment_methods.${transaction?.payment_method_name}`)}
        img={paymentMethods[transaction?.payment_method_name]?.imgUrl}
      />
      <InfoTextRow label="Holder" value={transaction.token?.holder} />
      <InfoTextRow label="Customer scheme name" value={transaction.token?.customer_scheme_name} />
      <InfoTextRow label="Real scheme" value={transaction.token?.real_scheme} />
      <InfoTextRow label="Secondary scheme" value={transaction.token?.secondary_scheme} />
      <InfoTextRow label="Card category" value={transaction.token?.type1} />
      <InfoTextRow label="Card type" value={transaction.token?.type2} />
      <InfoIconRow
        label="Authentication indicator"
        icon={AUTHENTICATION_INDICATORS[transaction.authentication_indicator]?.icon}
        value={AUTHENTICATION_INDICATORS[transaction.authentication_indicator]?.label}
      />
      <InfoIconRow
        label="Authentication result"
        icon={AUTHENTICATION_RESULTS[transaction.authentication_result]?.icon}
        value={AUTHENTICATION_RESULTS[transaction.authentication_result]?.label}
      />
      <InfoTextRow
        label="Challenge preference"
        value={CHALLENGE_PREFERENCES[transaction.challenge_preference]?.label}
      />
      <InfoTextRow
        label="SCA exemption reason"
        value={SCA_EXEMPTION_REASONS[transaction.sca_exemption_reason]?.label}
      />
      <InfoTextRow label="Authorization code" value={transaction.authorization_code} />
      <InfoTextRow label="Issuer / Bank" value={transaction.card_issuer} />
      <InfoCountryRow label="Issuing country" value={transaction.card_country} />
      <InfoTextRow label="Account IBAN" value={transaction.account_iban} />
      <InfoTextRow label="Account BIC" value={transaction.account_bic} />
      <InfoTextRow label="Device fingerprint" value={transaction.risk_assessment_device_fingerprint} />
    </InfoTile>
  );
};

export default PaymentDetails;
