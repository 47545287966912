import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { checkFieldMode } from '../Common/constants/fields';
import { decodeFilter, encodeFilter } from './useSearchFilters';
import useFavoriteView from './useFavoriteView';
import usePage from './usePage';
import { updateSortInSearchParams } from './useSort';
import { updatePaginationInSearchParams } from './usePagination';
import {useCan} from '../../../../contexts/ability.context';

/**
 * Update columnSet in SearchParams (react-router)
 * @param searchParams
 * @param value
 * @param prefix
 * @returns {Object}
 */
export const updateColumnSetInSearchParams = (searchParams, value, prefix) =>
  searchParams.set(`${prefix}_columns`, encodeFilter(value));

const useColumnSet = () => {
  const page = usePage();
  const can = useCan();

  const { columnSet: userColumnSet } = useFavoriteView();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(`${page.prefix}_columns`);

  const columnSet = useMemo(
    () => (paramValue ? decodeFilter(paramValue) : userColumnSet).filter(({ field }) => {
      const fieldOptions = page.fields[field];
      return checkFieldMode(field) && fieldOptions && !fieldOptions?.isForbidden?.(can)
    }),
    [paramValue],
  );

  const setColumnSet = useCallback(
    (value, sort) => {
      const newSearchParams = new URLSearchParams(searchParams);
      updateColumnSetInSearchParams(newSearchParams, value, page.prefix);
      if (sort) {
        updateSortInSearchParams(newSearchParams, sort, page.prefix);
        updatePaginationInSearchParams(newSearchParams, 1, null, page.prefix);
      }
      setSearchParams(newSearchParams);
    },
    [searchParams],
  );

  useEffect(() => {
    // init search url with user|default columnSet
    if (paramValue === null) setColumnSet(userColumnSet);
  }, []);

  return [columnSet, setColumnSet];
};

export default useColumnSet;
