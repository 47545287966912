// eslint-disable-next-line import/no-mutable-exports
let domain;
switch (window.location.hostname) {
  case 'localhost': {
    switch (process.env.REACT_APP_PM) {
      case 'merchant-norbr':
        domain = process.env.REACT_APP_MERCHANT_NORBR_DOMAIN;
        break;
      case 'merchant-progman':
        domain = process.env.REACT_APP_MERCHANT_PROGMAN_DOMAIN;
        break;
      case 'admin-norbr':
        domain = process.env.REACT_APP_ADMIN_NORBR_DOMAIN;
        break;
      case 'admin-progman':
        domain = process.env.REACT_APP_ADMIN_PROGMAN_DOMAIN;
        break;
      case 'admin':
        domain = null;
        break;
      default:
        domain = null;
    }
    break;
  }
  case process.env.REACT_APP_ADMIN_DOMAIN:
    domain = null;
    break;
  default:
    domain = window.location.hostname;
    break;
}

// Override here to deploy on DEVELOPMENT services
// --- Deploy as MERCHANT (NORBr BV)
// domain = 'merchant-dev.norbrbv.norbr.com';
// --- Deploy as PROGRAM_MANAGER (NORBr BV)
// domain = 'admin-dev.norbrbv.norbr.com';
// --- Deploy as INTERNAL
// domain = null;

export default domain;
