import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UndoOutlined, CloseCircleOutlined, LoginOutlined, ApartmentOutlined } from '@ant-design/icons';
import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import SlashForwardIcon from '@2fd/ant-design-icons/lib/SlashForward';
import { Avatar, Button, Space, Tag } from 'antd';
import { useQuery } from '@apollo/client';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import CancelModal from './Cancel/CancelModal';
import CaptureModal from './Capture/CaptureModal';
import RefundModal from './Refund/RefundModal';
import { useCan } from '../../../../../contexts/ability.context';
import { maintenanceQuery, activityContextQuery } from './query';
import { Link } from '../../../../../util/navigate';
import { formatEndUnderline } from '../../Common/utils';
import styles from './Maintenance.module.scss';
import SOURCE, { SOURCES } from '../../../../../constants/SOURCES';
import { ModeInternal, ModeProgramManager } from '../../../../../constants/mode';

const ActionButton = (props) => {
  const { icon: Icon, label, onClick, disabled } = props;
  return (
    <div className={styles.actionDiv}>
      <Button
        shape="circle"
        className={styles.actionButton2}
        style={{ opacity: disabled ? 0.2 : 1 }}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon className={styles.actionIcon} />
      </Button>
      <div className={styles.actionLabel}>{label}</div>
    </div>
  );
};

const Maintenance = () => {
  const can = useCan();
  const { orderId } = useParams();

  const { data: { order } = { order: null }, loading } = useQuery(maintenanceQuery, {
    variables: {
      orderId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: { activity } = { activity: null } } = useQuery(activityContextQuery, {
    variables: {
      orderId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const company = order?.company ?? activity?.company;
  const programManager = order?.program_manager ?? activity?.program_manager;

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  return (
    <div className={styles.buttonsContainer2}>
      <Space style={{ fontSize: 20 }}>
        <ShoppingIcon />
        <Link style={{ color: 'inherit' }} to="/brainpower/orders">
          {formatEndUnderline('Orders')}
        </Link>
        <SlashForwardIcon />
        <span style={{ fontSize: 16 }}>{orderId}</span>
        {!(order || loading) && <Tag color={SOURCES[SOURCE.INSIGHTS].color}>{SOURCES[SOURCE.INSIGHTS].label}</Tag>}
      </Space>
      <Space size="large">
        {order &&
          can('create', 'maintenance') && [
            <ActionButton
              key="cancel"
              icon={CloseCircleOutlined}
              label="Cancel"
              disabled={loading || !order.last_transaction.is_cancellable}
              onClick={() => setIsCancelModalOpen(true)}
            />,
            <ActionButton
              key="refund"
              icon={UndoOutlined}
              label="Refund"
              disabled={loading || !order.last_transaction.is_refundable}
              onClick={() => setIsRefundModalOpen(true)}
            />,
            <ActionButton
              key="capture"
              icon={LoginOutlined}
              label="Capture"
              disabled={loading || !order.last_transaction.is_capturable}
              onClick={() => setIsCaptureModalOpen(true)}
            />,
          ]}
        {(ModeInternal || ModeProgramManager) && company && (
          <Link to={`/companies/${company.id}`}>
            <Avatar
              shape="circle"
              title={company.name}
              src={company.theme.icon}
              icon={<DomainIcon />}
              style={{ backgroundColor: company.theme.secondaryColor }}
            />
          </Link>
        )}
        {ModeInternal && programManager && (
          <Link to={`/program-managers/${programManager.id}`}>
            <Avatar
              shape="square"
              title={programManager.name}
              src={programManager.theme.icon}
              icon={<ApartmentOutlined />}
              style={{ backgroundColor: programManager.theme.secondaryColor }}
            />
          </Link>
        )}
      </Space>
      {isCancelModalOpen && <CancelModal onClose={() => setIsCancelModalOpen(false)} order={order} />}
      {isRefundModalOpen && <RefundModal onClose={() => setIsRefundModalOpen(false)} order={order} />}
      {isCaptureModalOpen && <CaptureModal onClose={() => setIsCaptureModalOpen(false)} order={order} />}
    </div>
  );
};

export default Maintenance;
