import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import {Button, Form, Input, message, Modal, Select, Space, Switch, Upload} from 'antd';
import { CameraFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useMutation, useQuery } from '@apollo/client';
import { useMe } from '../../../../../contexts/me.context';
import { useCan } from '../../../../../contexts/ability.context';
import { initials } from '../../../../../util/string';
import useDebounce from '../../../../../hooks/useDebounce';
import { SEND_PASSWORD_RESET_EMAIL } from '../../../../../App/AppUnlogged/RecoverPassword/RecoverPassword';
import SiderContainer from '../../../Common/Sider/SiderContainer/SiderContainer';
import { ProfileListQuery } from '../../../Admin/AccessControl/query';
import {
  CompanyUserAuthorizedMerchantAccountMutation,
  CompanyUserIsGdprMutation,
  CompanyUserProfileMutation,
} from '../../../Admin/Company/query';
import { UserAvatarUpload, UserDisabledUpdateMutation, UserUpdateMutation } from '../../../Admin/Users/UserView/query';
import LinkedAccounts from './LinkedAccounts';
import AvailableAccounts from './AvailableAccounts';
import { UserDeleteMutation, UserDetailQuery } from './query';
import styles from './UserDetail.module.scss';
import { useLogout } from '../../../../../contexts/app.context';
import Loader from '../../../Common/Loader/Loader';
import useNavigateWithSearch from '../../../../../util/navigate';
import AllAccountsAccess from './AllAccountsAccess';

const { confirm } = Modal;


const UserAvatar = ({user}) => user.avatar ? (
  <img className={styles.avatarImg} src={user.avatar} alt="" />
) : (
  <div className={styles.avatarInitials} style={{ backgroundColor: user.theme?.primaryColor }}>
    {initials(user.full_name)}
  </div>
);

const AvatarUpload = ({ user }) => {
  const can = useCan();

  const [uploadUserAvatar] = useMutation(UserAvatarUpload, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUpload = ({ file }) => uploadUserAvatar({ variables: { id: user.id, file } });


  if (!can('update', 'user')) {
    return <UserAvatar user={user} />;
  }

  return (
    <ImgCrop aspect={1}>
      <Upload
        className={styles.upload}
        multiple={false}
        showUploadList={false}
        customRequest={handleUpload}
        beforeCrop={(file) => {
          if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            message.error(`${file.name} is not a png/jpeg file`);
          }
          return file.type === 'image/png' || file.type === 'image/jpeg' ? true : Upload.LIST_IGNORE;
        }}
      >
        <>
          <UserAvatar user={user} />
          <CameraFilled className={styles.cameraIcon} />
        </>
      </Upload>
    </ImgCrop>
  );
};

const UserDetail = ({ userId }) => {
  const me = useMe();
  const can = useCan();
  const logout = useLogout();
  const navigate = useNavigateWithSearch();

  const { data } = useQuery(UserDetailQuery, { variables: { id: userId }, skip: !userId });

  const user = data?.merchantUser;

  const profilesQuery = useQuery(ProfileListQuery);

  const [updateUser] = useMutation(UserUpdateMutation);

  const [updateCompanyUserProfile] = useMutation(CompanyUserProfileMutation, {
    refetchQueries: ['TeamUserListQuery', 'MerchantAccountDetailQuery'],
  });

  const [updateCompanyUser] = useMutation(CompanyUserAuthorizedMerchantAccountMutation);

  const [updateUserDisabled] = useMutation(UserDisabledUpdateMutation);

  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const [updateCompanyUserIsGdpr, updateCompanyUserIsGdprMutation] = useMutation(CompanyUserIsGdprMutation, {
    onError: () => {
      message.error('An error occurred, please try again later.');
    },
  });

  const [deleteUser] = useMutation(UserDeleteMutation, {
    refetchQueries: ['TeamUserListQuery', 'MerchantAccountDetailQuery'],
    variables: { id: userId },
    context: {
      headers: {
        company: me.companies[0].company.id,
      },
    },
  });

  // update lastname with debounce
  const [lastname, setLastname] = useState(user?.last_name);
  const debouncedLastname = useDebounce(lastname, 300);
  useEffect(() => {
    if (debouncedLastname && debouncedLastname !== user?.last_name) {
      updateUser({ variables: { id: userId, input: { last_name: debouncedLastname } } });
    }
  }, [debouncedLastname]);

  // update firstname with debounce
  const [firstname, setFirstname] = useState(user?.first_name);
  const debouncedFirstname = useDebounce(firstname, 300);
  useEffect(() => {
    if (debouncedFirstname && debouncedFirstname !== user?.first_name) {
      updateUser({ variables: { id: userId, input: { first_name: debouncedFirstname } } });
    }
  }, [debouncedFirstname]);

  // update phone with debounce
  const [phone, setPhone] = useState(user?.phone);
  const debouncedPhone = useDebounce(phone, 300);
  useEffect(() => {
    if (
      debouncedPhone &&
      (debouncedPhone.code !== user?.phone?.code || debouncedPhone.number !== user?.phone?.number)
    ) {
      updateUser({
        variables: {
          id: userId,
          input: {
            phone: {
              code: debouncedPhone.code || user?.phone?.code,
              number: debouncedPhone.number || user?.phone?.number,
            },
          },
        },
      });
    }
  }, [debouncedPhone, user]);

  const userHasAllAccountAccess = useMemo(() => {
    if (user?.companies[0]?.aclProfile) {
      return user.companies[0]?.aclProfile?.roles?.some((role) =>
        role.privileges.some((p) => p.action === 'access' && p.subject === 'all-accounts'),
      );
    }
    return false;
  }, [user]);

  if (!user) return <Loader className={styles.loading} />;

  if (user.deleted) {
    return (
      <SiderContainer
        title={
          <Space>
            <UserAvatar user={user} />
            <div>
              <div>{user.full_name}</div>
              <div className={styles.subtitle}>{user.email}</div>
              <div className={styles.subtitle}>
                Last seen : {user.lastConnection ? moment(user.lastConnection).calendar() : 'Never'}
              </div>
            </div>
          </Space>
        }
      >
        <Form layout="vertical" className={styles.userForm}>
          <Form.Item label="Firstname" name="first_name" initialValue={user.first_name}>
            <Input placeholder="Firstname" disabled />
          </Form.Item>
          <Form.Item label="Lastname" name="last_name" initialValue={user.last_name}>
            <Input placeholder="Lastname" disabled />
          </Form.Item>
          <br/>
          <small>This user has been deleted</small>
        </Form>
      </SiderContainer>
    )
  }

  const companyUser = user?.companies[0];

  const handleChangeProfile = (aclProfile) =>
    updateCompanyUserProfile({ variables: { id: user?.companies[0]?.id, aclProfile } });

  const handleChangeIsGdpr = (value) =>
    updateCompanyUserIsGdpr({
      variables: {
        id: user.companies[0].id,
        isGdpr: value,
      },
      optimisticResponse: {
        updateCompanyUserIsGdpr: {
          id: user.companies[0].id,
          isGdpr: value,
          __typename: 'MerchantCompanyUser',
        },
      },
    });

  const handleClickUnlock = () =>
    updateUserDisabled({
      variables: { id: userId, disabled: false },
    });

  const handleClickLock = () =>
    updateUserDisabled({
      variables: { id: userId, disabled: true },
    });

  const handleAddAuthorizedAccount =
    can('manage-users', 'company') &&
    ((merchantAccount) => {
      updateCompanyUser({
        variables: { id: user?.companies[0]?.id, merchantAccount, toAdd: true },
      });
    });

  const handleRemoveAuthorizedAccount =
    can('manage-users', 'company') &&
    ((merchantAccount) => {
      updateCompanyUser({
        variables: { id: user?.companies[0]?.id, merchantAccount, toAdd: false },
      });
    });

  const handleClickResetPassword = () => {
    confirm({
      title: 'Are you sure you want to reset the password?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction="vertical">
          {user.id === me.id
            ? `You will receive an email at ${user.email} to reset your password.`
            : `An email will be send at ${user.email} to reset the password.`}
        </Space>
      ),
      okText: 'Apply and proceed',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        sendPasswordResetEmail({ variables: { email: user.email } });
      },
    });
  };

  // You are about to delete an administrator account
  const handleDeleteUser = () => {
    let content;
    if (companyUser.aclProfile.name === 'Admin') {
      content = 'You are about to delete an administrator account.';
    }

    confirm({
      title: 'Are you sure you want to delete this user?',
      icon: <ExclamationCircleOutlined />,
      content,
      okText: 'Apply and proceed',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        deleteUser().then(() => {
          if (user.id === me.id) {
            logout();
          } else {
            navigate('/teams/users');
          }
        });
      },
    });
  };

  return (
    <SiderContainer
      title={
        <Space>
          <AvatarUpload user={user} />
          <div>
            <div>{user.full_name}</div>
            <div className={styles.subtitle}>{user.email}</div>
            <div className={styles.subtitle}>
              Last seen : {user.lastConnection ? moment(user.lastConnection).calendar() : 'Never'}
            </div>
          </div>
        </Space>
      }
    >
      {can('update', 'user') && (
        <Form layout="vertical" className={styles.userForm}>
          <Form.Item label="Firstname" name="first_name" initialValue={user.first_name}>
            <Input value={firstname} placeholder="Firstname" onChange={(e) => setFirstname(e.target.value)} />
          </Form.Item>
          <Form.Item label="Lastname" name="last_name" initialValue={user.last_name}>
            <Input value={lastname} placeholder="Lastname" onChange={(e) => setLastname(e.target.value)} />
          </Form.Item>
          <Form.Item label="Phone">
            <Space>
              <Form.Item name={['phone', 'code']} initialValue={user.phone.code} noStyle>
                <Input
                  style={{ width: 65 }}
                  placeholder="31"
                  prefix="+"
                  value={phone?.code}
                  onChange={(e) => setPhone({ ...phone, code: e.target.value })}
                />
              </Form.Item>
              <Form.Item name={['phone', 'number']} initialValue={user.phone.number} noStyle>
                <Input
                  style={{ width: 237 }}
                  placeholder="123456789"
                  value={phone?.number}
                  onChange={(e) => setPhone({ ...phone, number: e.target.value })}
                />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label="Profile" name="aclProfile" initialValue={user.companies[0].aclProfile.id}>
            <Select
              options={profilesQuery.data?.merchantAclProfiles.map((p) => ({ value: p.id, label: p.name }))}
              onChange={handleChangeProfile}
              disabled={!can('update-rights', 'user')}
            />
          </Form.Item>
          <Form.Item label="Access personal data">
            <Switch
              checked={user.companies[0].isGdpr}
              disabled={!can('update-rights', 'user')}
              onChange={handleChangeIsGdpr}
              loading={updateCompanyUserIsGdprMutation.loading}
            />
          </Form.Item>
        </Form>
      )}
      <div className={styles.userButtons}>
        {can('update', 'user') && (
          <Button
            name="reset-password"
            block
            onClick={handleClickResetPassword}
            type={user.status === 'created' ? 'primary' : 'default'}
          >
            Reset password
          </Button>
        )}
        {user.id !== me.id &&
          can('update-disabled', 'user') &&
          (user.disabled ? (
            <Button
              name="unlock"
              block
              onClick={handleClickUnlock}
              title={`Locked by ${user.disabledBy ? user.disabledBy.full_name : 'Security'} on ${moment(
                user.disabledAt,
              ).format('LLL')}`}
              type="primary"
            >
              Unlock user
            </Button>
          ) : (
            <Button name="lock" block onClick={handleClickLock}>
              Lock user
            </Button>
          ))}
        {user.id !== me.id && can('delete', 'user') && (
          <Button name="delete" block onClick={handleDeleteUser}>
            Delete user
          </Button>
        )}
      </div>
      {userHasAllAccountAccess ? (
        <AllAccountsAccess key="all-accounts-access" user={user} />
      ) : (
        [
          <LinkedAccounts
            key="linked-accounts"
            user={user}
            onAdd={handleAddAuthorizedAccount}
            onRemove={handleRemoveAuthorizedAccount}
          />,
          <AvailableAccounts
            key="available-accounts"
            user={user}
            onAdd={handleAddAuthorizedAccount}
            onRemove={handleRemoveAuthorizedAccount}
          />,
        ]
      )}
    </SiderContainer>
  );
};

export default UserDetail;
