import React from 'react';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import FallOutlined from '@ant-design/icons/FallOutlined';
import RiseOutlined from '@ant-design/icons/RiseOutlined';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import styles from './kpi.module.scss';
import LordIcon from '../../../../../../assets/icons/LordIcon';
import useHover from '../../../../../../hooks/useHover';

const Kpi = ({ amount, currency, label, orders, rate, turnover, variation, icon, color }) => {
  const { t } = useTranslation();

  const [ref, isHover] = useHover();

  return (
    <Col className={styles.root} lg={8} sm={12} xs={24}>
      <div className="kpiContainer" ref={ref}>
        <div className={styles.icon}>
          <LordIcon type={icon} width={68} height={68} color={color} isStopped={!isHover} loop autoplay />
        </div>

        <div className="kpi_turnover">
          <div className={styles.turnoverAmountContainer}>
            <div className={styles.turnoverAmount}>
              {rate && `${rate.toFixed(2)}%`}
              {amount !== undefined &&
                amount !== null &&
                `${new Intl.NumberFormat('en-US').format(amount.toFixed(2))} ${CURRENCIES[currency].symbol}`}
              {turnover !== undefined &&
                turnover !== null &&
                `${new Intl.NumberFormat('en-US').format(turnover.toFixed(2))} ${CURRENCIES[currency].symbol}`}
            </div>
          </div>
          {variation !== null && (
            <span
              className={classNames(styles.kpiVariation, styles[label], {
                [styles.negatif]: variation < 0,
                [styles.positif]: variation > 0,
              })}
            >
              {variation >= 0 ? <RiseOutlined /> : <FallOutlined />}
              {variation ? Math.abs(variation.toFixed(2)) : '0'}%
            </span>
          )}
        </div>

        <div className={styles.kpiTitle}>
          {t(`dashboard.kpis.${label}.title`)}
          <span style={{ float: 'right', fontSize: 12 }}>{orders && pluralize(' order', orders, true)}</span>
        </div>
      </div>
    </Col>
  );
};

export default Kpi;
