import React from 'react';
import { Table } from 'antd';
import classnames from 'classnames';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { buildColumnProps } from '../../Common/AdvancedTable/buildColumns';
import fields from '../../Common/constants/fields';
import styles from './MatcherDrawer.module.scss';

const LinkedTransactionsTable = ({ dataSource, reconciled = false }) => (
  <Table
    className={classnames(styles.table, { [styles.tableReconciled]: reconciled })}
    rowClassName={styles.tableRow}
    size="small"
    columns={[
      {
        dataIndex: Fields.PSP_TRANSACTION_ID,
        title: 'ID PARTNER',
        // width: 150,
        ...buildColumnProps(fields[Fields.PSP_TRANSACTION_ID]),
      },
      {
        dataIndex: Fields.TRANSACTION_CREATION_DATE,
        title: 'CREATION',
        // width: 180,
        ...buildColumnProps(fields[Fields.TRANSACTION_CREATION_DATE]),
      },
      { dataIndex: Fields.PAYMENT_METHOD, title: 'PM', width: 56, ...buildColumnProps(fields[Fields.PAYMENT_METHOD]) },
      {
        dataIndex: Fields.TRANSACTION_AMOUNT,
        title: 'AMOUNT',
        // width: 100,
        ...buildColumnProps(fields[Fields.TRANSACTION_AMOUNT]),
      },
      { dataIndex: Fields.PAYIN_PARTNER, title: 'PARTNER', ...buildColumnProps(fields[Fields.PAYIN_PARTNER]) },
      { width: 140 },
    ]}
    dataSource={dataSource}
    pagination={false}
    rowKey={Fields.PSP_TRANSACTION_ID}
    scroll={{ x: 'auto' }}
  />
);

export default LinkedTransactionsTable;
