import countryList from 'norbr-shared-lib/constants/countries/list';
import currencyList from 'norbr-shared-lib/constants/currencies/list';
import deviceTypeList from 'norbr-shared-lib/constants/transaction/deviceTypes/list';
import paymentChannelList from 'norbr-shared-lib/constants/paymentChannels/list';
import paymentMethodList from 'norbr-shared-lib/constants/paymentMethods/list';
import refundReasonList from 'norbr-shared-lib/constants/transaction/refundReasons/list';
import codesList from 'norbr-shared-lib/constants/returnCodes/list';
import ChallengePreferences from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences/list';
import ExemptionReasons from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons/list';

import SOURCES from '../constants/SOURCES';
import {integrationTypeList} from '../constants/INTEGRATION_TYPES';
import {TRANSACTION_STATUS_LIST} from '../constants/TRANSACTION_STATUSES';
import {cmsList} from '../constants/CMSS';
import {
  challengePreferenceOptions,
  exemptionReasonOptions
} from '../Layout/Routes/AndMe/Optimizer/AuthorizationFallbacks/DrawerForm';

const countries = countryList.reduce((memo, c) => ({ ...memo, [c.id]: c.label }), {});
const currencies = currencyList.reduce((memo, c) => ({ ...memo, [c.id]: c.label }), {});
const deviceTypes = deviceTypeList.reduce((memo, c) => ({ ...memo, [c.id]: c.label }), {});
const paymentChannels = paymentChannelList.reduce((memo, pc) => ({ ...memo, [pc.id]: pc.label }), {});
const paymentMethods = paymentMethodList.reduce((memo, pm) => ({ ...memo, [pm.id]: pm.label }), {});
const refundReasons = refundReasonList.reduce((memo, r) => ({ ...memo, [r.id]: r.label }), {});

const status = {
  init: 'Initialized',
  created: 'Created',
  authentication_attempted: 'Authentication attempted',
  authentication_requested: 'Authentication requested',
  authentication_request_failed: 'Authentication request failed',
  authentication_successful: 'Authentication successful',
  authentication_failed: 'Authentication failed',
  authentication_declined: 'Authentication declined',
  authorization_requested: 'Authorization requested',
  authorization_successful: 'Authorization successful',
  authorization_failed: 'Authorization failed',
  authorization_request_failed: 'Authorization request failed',
  authorization_declined: 'Authorization declined',
  authorization_cancel_requested: 'Authorization cancel requested',
  authorization_cancel_request_failed: 'Authorization cancel request failed',
  authorization_cancel_successful: 'Authorization cancel successful',
  authorization_cancel_failed: 'Authorization cancel failed',
  authorization_cancel_declined: 'Authorization cancel declined',
  authorization_renewed: 'Authorization renewed',
  authorization_expired: 'Authorization expired',
  blocked_by_router: 'Blocked by router',
  capture_requested: 'Capture requested',
  capture_successful: 'Capture successful',
  capture_request_failed: 'Capture request failed',
  capture_declined: 'Capture declined',
  refund_requested: 'Refund requested',
  refund_successful: 'Refund successful',
  refund_request_failed: 'Refund request failed',
  refund_declined: 'Refund declined',
  risk_approved: 'Risk approved',
  risk_declined: 'Risk declined',
  paid: 'Paid',
  charged_back: 'Charged back',
  risk_assessment_requested: 'Risk assessment requested',
  risk_assessment_request_failed: 'Risk assessment request failed',
  risk_assessment_approved: 'Risk assessment approved',
  risk_assessment_declined: 'Risk assessment declined',
  risk_assessment_manual_review: 'Risk assessment manual review',
  risk_assessment_request_expired: 'Risk assessment request expired',
  authentication_request_expired: 'Authentication request expired',
  authorization_request_expired: 'Authorization request expired',
};

const type = {
  authorization: 'Authorization',
  capture: 'Capture',
  refund: 'Refund',
  cancel: 'Cancel',
  chargeback: 'Chargeback',
};

const en = {
  countries,
  currencies,
  andMe: {
    notifications: {
      input: {
        trigger: status,
        integrationType: {
          direct_api: 'Direct API',
          hosted_fields: 'Hosted fields',
          hosted_elements: 'Hosted Elements',
          hosted_page: 'Hosted page',
          client_side_encryption: 'Client side encryption',
          cms_module: 'CMS module',
          other: 'Other',
        },
      },
    },
    paymentNetwork: {
      merchantContract: {
        payment_methods: paymentMethods,
        channels: {
          moto: 'Moto',
          'e-commerce': 'E-Commerce',
          recurring: 'Recurring',
          pos: 'POS',
        },
        count: '{{count}} contract',
        count_plural: '{{count}} contracts',
      },
      routingMatrix: {
        routingRules: {
          ruleEditor: {
            splitters: {
              amount: {
                label: 'Amount',
              },
              authenticationLevel: {
                label: 'Authentication level',
                options: {
                  '3ds': '3DS',
                  no3ds: 'No 3DS',
                },
              },
              authorizationCurrency: {
                label: 'Authorization currency',
              },
              customerIpCountry: {
                label: 'Country (IP)',
              },
              issuerCountry: {
                label: 'Country (Card)',
              },
              issuer: {
                label: 'Issuer',
              },
              paymentMethodType: {
                label: 'Payment type',
                options: {
                  card: 'Card',
                  'bank transfer': 'Bank transfer',
                  bank_transfer: 'Bank transfer',
                  'e-wallet': 'E-wallet',
                  'open invoice': 'Open invoice',
                  open_invoice: 'Open invoice',
                  cash: 'Cash',
                  instalments: 'Instalments',
                  'on reference': 'On reference',
                  on_reference: 'On reference',
                  instant_payment: 'Instant payment',
                },
              },
              paymentMethodName: {
                label: 'Payment method',
              },
              paymentChannel: {
                label: 'Payment channel',
                options: {
                  'mail order': 'Mail order',
                  mobile: 'Mobile',
                  'call center': 'Call center',
                  web: 'Web',
                },
              },
              splitter: {
                label: 'Splitter',
              },
              merchantStoreId: {
                label: 'Merchant store ID',
              },
              list: {
                label: 'List',
              },
              merchantData: {
                label: 'Merchant data',
              }
            },
          },
        },
      },
    },
    lists: {
      types: {
        customer_id: 'Customer ID',
        customer_email: 'Customer email',
        bin: 'BIN',
        customer_ip: 'Customer IP',
        product_sku: 'Product SKU',
        customer_zip_code: 'Customer zip code',
      },
    },
  },
  filters: {
    // transaction
    order_id: {
      label: 'Order id',
    },
    id: {
      label: 'Transaction id',
    },
    customer_email: {
      label: 'Customer email',
    },
    customer_first_name: {
      label: 'Customer first name',
    },
    customer_last_name: {
      label: 'Customer last name',
    },
    customer_ip: {
      label: 'Customer IP',
    },
    token: {
      label: 'Token',
    },
    payment_channel: {
      label: 'Payment channel',
      options: paymentChannels,
    },
    payin_partner: {
      label: 'Payin partner',
    },
    status: {
      label: 'Order status',
      options: status,
    },
    payment_method_name: {
      label: 'Payment method',
      options: paymentMethods,
    },
    filter_amount: {
      label: 'Order amount',
    },
    amount_range: {
      label: 'Order amount range',
    },
    currency: {
      label: 'Currency',
      options: currencies,
    },
    merchant_account: {
      label: 'Merchant account',
    },
    merchant_contract: {
      label: 'Merchant contract',
    },
    psp_transaction_id: {
      label: 'PSP transaction reference',
    },
    risk_assessment_partner: {
      label: 'Risk assessment partner',
    },
    risk_assessment_result: {
      label: 'Risk assessment result',
      options: {
        accepted: 'Accepted',
        declined: 'Declined',
        manual_review: 'Manual review',
        automated_review: 'Automated review',
        pending: 'Pending',
        null: 'None',
      },
    },
    is_authenticated: {
      label: 'Is authenticated',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    authentication_partner: {
      label: 'Authentication partner',
    },
    authentication_result: {
      label: 'Authentication result',
      options: {
        success: 'Success',
        failure: 'Failure',
        pending: 'Pending',
        accepted: 'Accepted',
        attempted: 'Attempted',
        not_available: 'Not available',
        declined: 'Declined',
        null: 'None',
        manual_review: 'Manual review',
      },
    },
    card_issuer: {
      label: 'Card issuer',
    },
    card_country: {
      label: 'Card country',
      options: countries,
    },
    customer_country: {
      label: 'Customer country',
      options: countries,
    },
    is_authorized: {
      label: 'Is authorized',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    is_captured: {
      label: 'Is captured',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    is_refunded: {
      label: 'Is refunded',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    is_chargebacked: {
      label: 'Is chargebacked',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    created_at: {
      label: 'Period',
    },
    updated_at: {
      label: 'Updated at',
    },
    authorization_date: {
      label: 'Authorization date',
    },
    capture_date: {
      label: 'Capture date',
    },
    refund_date: {
      label: 'Refund date',
    },
    refund_reason: {
      label: 'Refund reason',
      options: refundReasons,
    },
    chargeback_date: {
      label: 'Chargeback date',
    },
    chargeback_reason: {
      label: 'Chargeback reason',
      options: {
        'Account Number Not on File': 'Account Number Not on File',
        'Addition Error': 'Addition Error',
        'Advance Deposit': 'Advance Deposit',
        'Altered Amount': 'Altered Amount',
        'Alternate Settlement Currency Incorrect Exchange Rates':
          'Alternate Settlement Currency Incorrect Exchange Rates',
        'Authorization Approval Expired': 'Authorization Approval Expired',
        'Authorization Declined': 'Authorization Declined',
        'Authorization Processing Errors': 'Authorization Processing Errors',
        'Cancelation of Recurring Goods/Services services': 'Cancelation of Recurring Goods/Services services',
        'Canceled Merchandise/Services': 'Canceled Merchandise/Services',
        'Canceled Recurring': 'Canceled Recurring',
        'Canceled Recurring Transaction': 'Canceled Recurring Transaction',
        'Canceled Recurring Transactions': 'Canceled Recurring Transactions',
        'Canceled Transaction': 'Canceled Transaction',
        'Card Absent Fraud': 'Card Absent Fraud',
        'Card holder does not agree with amount billed': 'Card holder does not agree with amount billed',
        'Card Not Present (fraud)': 'Card Not Present (fraud)',
        'Card Not Valid or Expired': 'Card Not Valid or Expired',
        'Card on Stop List': 'Card on Stop List',
        'Card Recovery Bulletin': 'Card Recovery Bulletin',
        'Cardholder Canceled Recurring Transaction': 'Cardholder Canceled Recurring Transaction',
        'Cardholder Denies the Transaction': 'Cardholder Denies the Transaction',
        'Cardholder dispute': 'Cardholder dispute',
        'Cardholder Dispute- Transaction Amount Differs': 'Cardholder Dispute- Transaction Amount Differs',
        'Cardholder Disputes Quality of Goods or Services': 'Cardholder Disputes Quality of Goods or Services',
        'Cardholder does not Recognize': 'Cardholder does not Recognize',
        'Cardholder does not recognize – Potential Fraud': 'Cardholder does not recognize – Potential Fraud',
        'Cardmember Dispute': 'Cardmember Dispute',
        'Cardmember Does Not Recognize': 'Cardmember Does Not Recognize',
        'Cardmember Paid by Other Means': 'Cardmember Paid by Other Means',
        'Chip Liability Shift': 'Chip Liability Shift',
        'Chip/PIN Liability Shift': 'Chip/PIN Liability Shift',
        'Correct Transaction Currency Code not provided': 'Correct Transaction Currency Code not provided',
        'Counterfeit Card': 'Counterfeit Card',
        'Counterfeit Merchandise': 'Counterfeit Merchandise',
        'Credit not Processed': 'Credit not Processed',
        'Credit Not Received': 'Credit Not Received',
        'Credit Posted as a Purchase': 'Credit Posted as a Purchase',
        'Credit/ Debit Posted Incorrectly': 'Credit/ Debit Posted Incorrectly',
        'Credit/Debit Presentment Error': 'Credit/Debit Presentment Error',
        'Currency Discrepancy': 'Currency Discrepancy',
        'Damaged and/or Defective Goods/Services': 'Damaged and/or Defective Goods/Services',
        'Declined Authorization': 'Declined Authorization',
        'Defective Merchandise/Good Not Described': 'Defective Merchandise/Good Not Described',
        'Does not Recognize': 'Does not Recognize',
        'Domestic Chargeback Dispute (Europe region only)': 'Domestic Chargeback Dispute (Europe region only)',
        'Duplicate Charge': 'Duplicate Charge',
        'Duplicate Processing': 'Duplicate Processing',
        'Duplicate Processing/Paid by Other Means': 'Duplicate Processing/Paid by Other Means',
        'EMV Liability Shift Counterfeit Fraud': 'EMV Liability Shift Counterfeit Fraud',
        'EMV Liability Shift Non-Counterfeit Fraud': 'EMV Liability Shift Non-Counterfeit Fraud',
        'Exceeds Limited or Authorised Amount': 'Exceeds Limited or Authorised Amount',
        'Expired Authorization': 'Expired Authorization',
        'Expired JCB Card': 'Expired JCB Card',
        'Expired/Not Yet valid card': 'Expired/Not Yet valid card',
        'Fraud - Card Not Present Transaction': 'Fraud - Card Not Present Transaction',
        'Fraud - Card Present Transaction': 'Fraud - Card Present Transaction',
        'Fraud -Disputed transaction': 'Fraud -Disputed transaction',
        'Fraud – Chip Card Counterfeit Transaction': 'Fraud – Chip Card Counterfeit Transaction',
        'Fraud – Lost or Stolen Chip and PIN Card Transaction': 'Fraud – Lost or Stolen Chip and PIN Card Transaction',
        'Fraud Card Not Present Transaction': 'Fraud Card Not Present Transaction',
        'Fraud Card Present Transaction': 'Fraud Card Present Transaction',
        'Fraud Chip Card and PIN Transaction': 'Fraud Chip Card and PIN Transaction',
        'Fraud Chip Card Counterfeit Transaction': 'Fraud Chip Card Counterfeit Transaction',
        'Fraud Liability Shift – Counterfeit': 'Fraud Liability Shift – Counterfeit',
        'Fraud Liability Shift – Lost/Stolen/Non-Received': 'Fraud Liability Shift – Lost/Stolen/Non-Received',
        'Fraudulent Multiple Transactions': 'Fraudulent Multiple Transactions',
        'Fraudulent Processing of Transactions': 'Fraudulent Processing of Transactions',
        'Full recourse': 'Full recourse',
        'Goods and Services Not Received': 'Goods and Services Not Received',
        'Goods and Services not Received/Refused': 'Goods and Services not Received/Refused',
        'Goods Returned/Canceled': 'Goods Returned/Canceled',
        'Illegible Item': 'Illegible Item',
        'Incorrect Account Number': 'Incorrect Account Number',
        'Incorrect Amount': 'Incorrect Amount',
        'Incorrect Card number': 'Incorrect Card number',
        'Incorrect Currency': 'Incorrect Currency',
        'Incorrect Transaction Amount': 'Incorrect Transaction Amount',
        'Incorrect Transaction Amount Presented': 'Incorrect Transaction Amount Presented',
        'Incorrect Transaction Code': 'Incorrect Transaction Code',
        'Invalid Cardholder Number': 'Invalid Cardholder Number',
        'Invalid Data': 'Invalid Data',
        'Late Presentation': 'Late Presentation',
        'Late Presentment': 'Late Presentment',
        'Late Submission': 'Late Submission',
        'Local Regulatory/Legal Dispute': 'Local Regulatory/Legal Dispute',
        'Merchandise/Services Not Received': 'Merchandise/Services Not Received',
        Mispost: 'Mispost',
        Misrepresentation: 'Misrepresentation',
        'Multiple Charges at Service Establishment Fraudulent Transaction':
          'Multiple Charges at Service Establishment Fraudulent Transaction',
        'Multiple Processing': 'Multiple Processing',
        'No Authorization': 'No Authorization',
        'No Card Member Authorization': 'No Card Member Authorization',
        'No Cardholder Authorization': 'No Cardholder Authorization',
        'No Credit Received': 'No Credit Received',
        'No Imprint': 'No Imprint',
        'No show dispute': 'No show dispute',
        'No Signature': 'No Signature',
        'No Valid Approval Code': 'No Valid Approval Code',
        'No Valid Authorization': 'No Valid Authorization',
        'Non Matching Account Number': 'Non Matching Account Number',
        'Non-Receipt of Goods or Services': 'Non-Receipt of Goods or Services',
        'Non-Receipt of Merchandise/Non-Receipt of Cash at ATM':
          'Non-Receipt of Merchandise/Non-Receipt of Cash at ATM',
        'Non-Receipt of Requested Item': 'Non-Receipt of Requested Item',
        'Not as Described': 'Not as Described',
        'Not as Described or Defective Merchandise/Services': 'Not as Described or Defective Merchandise/Services',
        'Not Elsewhere Classified (U.S. region only)': 'Not Elsewhere Classified (U.S. region only)',
        'Original Credit Transaction Not Accepted': 'Original Credit Transaction Not Accepted',
        'Other Fraud-Card Absent Environment': 'Other Fraud-Card Absent Environment',
        'Other Fraud-Card Present Environment': 'Other Fraud-Card Present Environment',
        'Paid by Other Means': 'Paid by Other Means',
        'Paid through Other Means': 'Paid through Other Means',
        'Placed in Immediate Chargeback Program': 'Placed in Immediate Chargeback Program',
        'Placed in the Partial Immediate Chargeback Program': 'Placed in the Partial Immediate Chargeback Program',
        'Processing Error': 'Processing Error',
        'Questionable merchant activity': 'Questionable merchant activity',
        'Recurring Payment': 'Recurring Payment',
        'Refund Not Processed': 'Refund Not Processed',
        'Requested /Required Authorization not obtained': 'Requested /Required Authorization not obtained',
        'Requested Copy Illegible': 'Requested Copy Illegible',
        'Services Not Rendered': 'Services Not Rendered',
        'Split Sale': 'Split Sale',
        'Transaction Amount Changed': 'Transaction Amount Changed',
        'Transaction amount differs': 'Transaction amount differs',
        'Transaction was Settled but Cash/Goods/Services were not Received':
          'Transaction was Settled but Cash/Goods/Services were not Received',
        'Unassigned C/M Account Number': 'Unassigned C/M Account Number',
        'Unauthorized Multiple Transaction': 'Unauthorized Multiple Transaction',
        'Unauthorized Purchase': 'Unauthorized Purchase',
        'Unissued Account Number': 'Unissued Account Number',
        'Visa Fraud Monitoring Program': 'Visa Fraud Monitoring Program',
        'Warning Bulletin File': 'Warning Bulletin File',
      },
    },
    authorization_result: {
      label: 'Authorization result',
      options: {
        failure: 'Failure',
        null: 'None',
        pending: 'Pending',
        success: 'Success',
      },
    },
    order_merchant_id: {
      label: 'Merchant reference',
    },
    // merchantAccount
    ma_status: {
      label: 'Account status',
      options: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    ma_created_at: {
      label: 'Creation date',
    },
    // partner
    p_created_at: {
      label: 'Created at',
    },
    p_status: {
      label: 'Status',
    },
    device_type: {
      label: 'Device type',
      options: deviceTypes,
    }
  },
  datePicker: {
    customPeriods: {
      last_hour: 'Last hour',
      last_4_hours: 'Last 4 hours',
      last_12_hours: 'Last 12 hours',
      last_24_hours: 'Last 24 hours',
      today: 'Today',
      yesterday: 'Yesterday',
      current_week: 'Current week',
      previous_week: 'Previous week',
      current_month: 'Current month',
      previous_month: 'Previous month',
      current_quarter: 'Current quarter',
      previous_quarter: 'Previous quarter',
      all: 'All',
    },
  },
  order: {
    actions: {
      cancel: {
        questionStart: 'Do you want to ',
        questionEnd: 'this authorization ?',
        verb: 'Cancel',
      },
      capture: {
        verb: 'Capture',
      },
      refund: {
        verb: 'Refund',
      },
      cta: {
        questionStart: 'Do you want to ',
        questionEnd: 'this transaction ?',
        label: 'Enter an amount : ',
        max: 'Max amount : ',
      },
    },
    history: {
      retriedBy: 'Retried by',
      operatedBy: 'Operated by',
    },
    detail: {
      orderType: {
        direct_capture: 'Direct capture',
        authorize: 'Authorize',
      },
      paymentChannels,
      status: {
        ...status,
        // Authentication statuses
        success: 'Authentication successful',
        pending: 'Authentication pending',
        failure: 'Authentication failure',
        accepted: 'Authentication accepted',
        attempted: 'Authentication attempted',
        declined: 'Authentication declined',
      },
      payment_methods: paymentMethods,
      operationStatus: {
        created: 'Ok',
        successful: 'Ok',
        approved: 'Ok',
        paid: 'Ok',
        charged: 'OK',
        requested: 'Pending',
        renewed: 'Pending',
        failed: 'KO',
        declined: 'KO',
      },
      type,
      routing: {
        mode: {
          merchant_contract: 'Merchant contract',
          routing_system: 'Routing system',
          default_provider: 'Default provider',
        },
      },
    },
  },
  dashboard: {
    chartHeaders: {
      refunded_amount: 'Refunded amount',
      refunded_orders: 'Refunded orders',
      chargeback_amount: 'Chargeback amount',
      chargeback_orders: 'Chargeback orders',
    },
    kpis: {
      authorized_turnover: {
        title: 'Authorized turnover',
      },
      success_rate: {
        title: 'Success rate',
      },
      captured_turnover: {
        title: 'Captured turnover',
      },
      average_basket: {
        title: 'Average basket',
      },
      saved_turnover: {
        title: 'Saved turnover',
      },
      refunded_amount: {
        title: 'Refunds',
      },
      chargeback_amount: {
        title: 'Chargebacks',
      },
    },
    trendsChart: {
      title: 'Trends',
      loading: 'Loading trends...',
    },
    globalPerformanceChart: {
      title: 'Global performance',
      loading: 'Loading global performance...',
      categories: {
        success_rate: 'Success rate',
        authentication_rate: 'Authentication rate',
        saved_rate: 'Saved rate',
        block_rate: 'Block rate',
        repeat_rate: 'Repeat rate',
      },
      psps: {
        adyen: 'Adyen',
        Checkout: 'Checkout',
        'BNP Paribas': 'BNP Paribas',
        ingenico: 'Ingenico',
        pxp: 'PXP',
        Pxp: 'PXP',
        'PXP Financial': 'PXP Financial',
        Volt: 'Volt',
      },
    },
    paymentDistributionChart: {
      title: 'Payment distribution',
      loading: 'Loading payment distribution...',
    },
    fraudResultsChart: {
      title: 'Fraud results',
      loading: 'Loading fraud results...',
    },
    performanceAnalysisChart: {
      title: 'Performance analysis',
      loading: 'Loading performance analysis...',
    },
    channelDistributionChart: {
      title: 'Channel distribution',
      loading: 'Loading channel distribution...',
    },
    refundsChart: {
      title: 'Refunds by reasons',
      loading: 'Loading refunds...',
      reasons: {
        'No label': 'None',
        requested_by_customer: 'Requested by customer',
        fraudulent: 'Fraudulent',
        duplicate: 'Duplicate',
        other: 'Other',
      },
    },
    chargebacksChart: {
      title: 'Chargebacks by reasons',
      loading: 'Loading chargebacks...',
      reasons: {
        'No label': 'None',
        requested_by_customer: 'Requested by customer',
        fraudulent: 'Fraudulent',
        duplicate: 'Duplicate',
        other: 'Other',
      },
    },
    mapChart: {
      title: 'Distribution by country',
      loading: 'Loading distribution by country...',
    },
    topChart: {
      title: 'Country distribution',
      loading: 'Loading data...',
    },
    multiple_value_axes: {
      title: 'Trends',
      loading: 'Loading data...',
    },
    radar_timeline: {
      title: 'Payin partner distribution timeline',
      loading: 'Loading data...',
    },
    paymentChannelChart: {
      title: 'Sales channel distribution',
      loading: 'Loading data...',
      options: paymentChannels,
    },
    savedOrdersChart: {
      title: 'Saved orders',
    },
  },
  account: {
    status: {
      active: 'Active',
      inactive: 'Inactive',
    },
  },
  merchantAccount: {
    list: {
      columns: {
        createdAt: 'Creation date',
        name: 'Account name',
        status: 'Status',
        createdBy: 'Created by',
        country: 'Country',
        account_id: 'Account ID',
        company: 'Company',
        email: 'E-mail',
        last_name: 'Name',
        first_name: 'First name',
        users: 'Users',
      },
    },
  },
  partnerAccount: {
    list: {
      columns: {
        createdAt: 'Creation date',
        status: 'Status',
        createdBy: 'Created by',
        country: 'Country',
        partner_id: 'Partner ID',
        company: 'Company',
        type: 'Type',
        name: 'Name',
      },
      types: {
        risk_assessment: 'Risk assessment',
        payin: 'Payin',
        payout: 'Payout',
        authentication: 'Authentication',
      },
    },
  },
  transaction: {
    list: {
      pagination: {
        next: 'Next',
        prev: 'Previous',
      },
      columns: {
        order_id: 'Order',
        transaction: 'Transaction',
        transaction_number: '',
        createdAt: 'Creation date',
        amount: 'Amount',
        currency: 'Currency',
        payment_method_name: 'PM',
        customer_country: 'CC',
        card_country: 'CC',
        status: 'Status',
        partner: 'Partner',
        customer_email: 'Customer email',
        payment_channel: 'Channel',
        payment_method_type: 'PM type',
        token: 'Token',
        authentication_result: 'Auth',
        authorization_date: 'Authorization date',
        authorization_status: 'Authorization',
        cancellation_date: 'Cancellation date',
        cancellation_status: 'Cancellation',
        refund_date: 'Refund date',
        refund_status: 'Refund',
        capture_date: 'Capture date',
        capture_status: 'Capture',
        customer_first_name: 'First name',
        customer_last_name: 'Last name',
        customer_id: 'Customer',
        customer_ip: 'Customer IP',
        customer_zip_code: 'Zip',
        psp_transaction_id: 'PSP Transaction Id',
        psp_token: 'PSP Token',
        updated_at: 'Updated at',
        account_number: 'Account Number',
        order_merchant_id: 'Merchant ref.',
      },
    },
    orderStatus: status,
    authStatus: {
      accepted: 'Accepted',
      declined: 'Declined',
      manual_review: 'Manual review',
      automated_review: 'Automated review',
      pending: 'Pending',
      null: 'None',
    },
  },
  exception: {
    404: {
      message: 'Sorry, page not found.',
      button: 'Redirect me to the Ignition page',
    },
  },
  ignition: {
    form: {
      header: 'Enter your professional e-mail address to register to NORBr',
      footer: 'By clicking on the button below, you accept our ',
      btn: 'privacy policy.',
      success: 'Thanks for your registration to NORBr, you will hear from us soon!',
    },
    txt: {
      title1: 'One single integration',
      content1:
        'Connect to major payment and fraud providers and manage all your payment scenarios with one single integration.',
      title2: 'Smart-routing',
      content2:
        'NORBr Router analyzes and selects in real-time the best payment scenarios for every single transaction.',
      title3: 'Real-time analytics',
      content3:
        'Get insights into your payment performance with NORBr Brainpower to get the best from your payment and fraud data.',
    },
    err: {
      emailIsEmpty: 'Please enter your professional e-mail address.',
      emailIsNotValid: 'Your e-mail address is not valid.',
      captchaIsEmpty: 'Captcha validation failed, please try again.',
    },
  },
  partner: {
    opportunity: {
      question: {
        title: 'Response to opportunity',
      },
    },
  },
  merchant: {
    finetune: {
      header: {
        button: 'VALIDATE THE OPPORTUNITY',
      },
      sidebar: {
        title: 'Opportunity Chapters',
        box: {
          ponderation: 'ponderation',
        },
      },
      content: {
        title: 'Questions for payment providers',
        ponderation: 'Ponderation',
        presentation: {
          title: 'Lets build the best !',
          paragraphe:
            'Select a chapter, then choose the questions that you think' +
            'are most relevant. Build your opportunity in a few clicks. <br /><br />' +
            'Involve your colleagues by inviting them to choose the questions according to their expertise area.' +
            '<br /><br />' +
            'Once satisfied, launch the consultation' +
            'with more than 120 payment providers around the world' +
            'and choose the best one for your business !',
        },
      },
    },
    waiting: {
      title: 'Before the end of the call for tenders ',
      remaining: 'TIME REMAINING',
      deadline: 'DEADLINE',
      button: 'EXPLORE THE RFP',
      integration:
        '<strong>You’re almost done.</strong><br />\nDo you want to complete your information in the meantime ?',
      integrationButton: 'START MY INTEGRATION',
    },
    opportunity: {
      button: 'CREATE A NEW OPPORTUNITY',
    },
  },
  menu: {
    title: {
      safeDepositBox: 'Safe-deposit box',
      opportunity: 'Opportunity',
      activity: 'Activity',
      news: 'News & Events',
      payment: 'Payment',
      customer: 'Customer',
      token: 'Token',
      transaction: 'Transaction',
      order: 'Order',
    },
    link: {
      dashboard: 'Dashboard',
      transactions: 'Transactions',
      team: 'Team',
      calendar: 'Calendar',
      world: 'World Payment Journal',
      network: 'Payment network',
    },
    button: {
      show: 'SHOW MORE',
    },
  },
  rfp: {
    signin: {
      left: {
        list1:
          '<strong>Quick and free registration</strong><br />Sign up with your favorite social network and get free access to NORBR',
        list2: '<strong>Simple integration</strong><br />use the NORBR API or connect directly to your provider',
        list3: '<strong>Start accepting payments</strong><br />try the sandbox or get started in a few minutes',
      },
      right: {
        title: 'Create your free <br /> NORBR account now',
        terms: "By clicking on the button below, you agree to our <a href=''>terms & conditions</a>",
      },
    },
    linkedinBack: {
      title: 'Let’s get started',
      description:
        'Welcome to NORBR %s,<br />' +
        'Let’s have a look at your setup and see what payment providers have<br /> to offer to boost your business.',
      step1: 'Loading in progress...',
      step2: 'Your businness opportunity is almost ready...',
      step3: 'Screen, benchmark and select providers…',
      step4: 'A single registration no matter what the payment provider is',
      step5: 'Your first opportunity in few clicks',
    },
    linkedinResume: {
      title: 'Confirm the information below',
    },
    company: {
      title: 'Let’s get started',
      description:
        'Welcome to NORBR %s,<br />' +
        'Let’s have a look at your setup and see what payment providers have<br /> to offer to boost your business.',
      titleForm: 'Please tell us more about you',
      form: {
        button: 'NEXT STEP',
      },
      button: {
        modify: 'MODIFY THE INFORMATION',
        next: 'NEXT STEP',
      },
    },
    resume: {
      title: 'Let’s get started',
      description:
        'Welcome to NORBR %s,<br />' +
        'Let’s have a look at your setup and see what payment providers have<br /> to offer to boost your business.',
      confirm: 'Confirm the information below',
      table: {
        companyName: 'Company Name',
        legalForm: 'Legal Form',
        registrationNumber: 'Registration Number',
        shareCapital: 'Share capital',
        ceo: 'CEO',
        website: 'Website',
        activity: 'Activity',
        psp: 'Your current PSP',
        address: 'Main Address',
        channels: 'Sales channels',
        payment: 'Subscription payments',
      },
    },
    compile: {
      title: 'We are generating your business opportunity ...',
    },
    finish: {
      title: 'Get the best offers from payment providers !',
      subtitle: 'Below, the opportunity we will send<br /> to eligible PSPs.',
      description: 'Customer centric<br /> Payment service',
      exploreButton: 'EXPLORE THE RFP',
      information:
        'Your company name will not be disclosed until the PSP approves our T&Cs.<br />' +
        'NORBr uses all its knowledge to make sure PSPs meet your highest requirements ( know more )',
      advertise:
        'You can fine-tune your opportunity for more accurate results or send it straight away to receive first offers. ',
      score: 'Your opportunity score is currently',
      date: 'Final presentation',
      button: {
        submit: 'SUBMIT THE OPPORTUNITY',
        finetune: 'FINE-TUNE MY OPPORTUNITY',
      },
    },

    Graph: {
      WhatsLackingFromYourCurrentPSP: "What's lacking from your current PSP ?",
      WhatDoYouExpectFromAPSP: 'What do you expect from a PSP ?',
      ClickOnAnOptionOrDragABranchOfTheStarToDrawYourNeed:
        'Click on an option or drag a branch of the star to draw your need.',
      AccessToYourOpportunity: 'ACCESS TO YOUR OPPORTUNITY',
      IgnoreForTheMoment: 'Ignore for the moment',
      data: {
        item1: 'COMPLIANCE',
        item2: 'PRICE',
        item3: 'DESIGN',
        item4: 'INTERACTION',
        item5: 'GEOGRAPHICAL COVERAGE',
        item6: 'BRAND',
        item7: 'LEGAL',
        item8: 'INFRO',
      },
    },
    Resume: {
      GetTheBestOffersFromPaymentProviders: 'Get the best offers from payment providers !',
      BelowTheOpportunityWeWillSend: 'Below, the opportunity we will send',
      ToMoreThan25DifferentPSPs: 'To more than 25 different PSPs.',
      CustomerCentric: 'Customer centric',
      PaymentService: 'Payment service',
      ExploreTheRfp: 'explore the rfp',
      FinalPresentation: 'Final presentation',
      YourNameWillNotBeDisclosedUntilThePSPApprovesOurTCs:
        'Your name will not be disclosed until the PSP approves our T&Cs.',
      NORBrUsesAllItsKnowledgeToCoverAllCriticalMattersToYourBusiness:
        'NORBr uses all its knowledge to cover all critical matters to your business ( know more )',
      YouCanFineTuneYourOpportunityForMoreAccurateResultsOrSendItStraightAwayToReceiveFirstOffers:
        'You can fine-tune your opportunity for more accurate results or send it straight away to receive first offers.',
      YourOpportunityScoreIsCurrently: 'Your opportunity score is currently',
      FineTuneMyOpportunity: 'Fine-tune my opportunity',
      SubmitTheOpportunity: 'Submit the opportunity',
    },
    Wait: {
      OnlyAFewDaysLeftBefore: 'Only a few days left before',
      YourPaymentSolutionsTakeOff: 'your payment solutions take off!',
      BeforeTheEndOfTheCallForTenders: 'Before the end of the call for tenders ',
      TimeRemaining: 'TIME REMAINING',
      Deadline: 'DEADLINE',
    },
    Finetune: {
      OpportunityChapter: 'Opportunity chapter',
      QuestionChapter: 'Question chapter',
    },
  },
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    start: 'Start',
    end: 'End',
    successfullyCopied: 'Successfully copied to clipboard',
    errorOccured: 'An error occured, please try again later.',
  },

  app: {
    title: 'Norbr Administration',
  },

  entities: {
    template: {
      name: 'template',
      label: 'Templates',
      menu: 'Templates',
      exporterFileName: 'template_export',
      list: {
        menu: 'Templates',
        title: 'Templates',
      },
      create: {
        success: 'Template saved successfully',
      },
      update: {
        success: 'Template saved successfully',
      },
      destroy: {
        success: 'Template deleted successfully',
      },
      destroyAll: {
        success: 'Template(s) deleted successfully',
      },
      edit: {
        title: 'Edit Template',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        type: 'Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Template',
      },
      view: {
        title: 'View Template',
      },
      importer: {
        title: 'Import Templates',
        fileName: 'template_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    employee: {
      name: 'employee',
      label: 'Employees',
      menu: 'Employees',
      exporterFileName: 'employee_export',
      list: {
        menu: 'Employees',
        title: 'Employees',
      },
      create: {
        success: 'Employee saved successfully',
      },
      update: {
        success: 'Employee saved successfully',
      },
      destroy: {
        success: 'Employee deleted successfully',
      },
      destroyAll: {
        success: 'Employee(s) deleted successfully',
      },
      edit: {
        title: 'Edit Employee',
      },
      fields: {
        id: 'Id',
        firstname: 'First name',
        lastname: 'Last name',
        birthdateRange: 'Birthdate',
        birthdate: 'Birthdate',
        gender: 'Gender',
        companyName: 'Company name',
        address: 'Address',
        city: 'City',
        zipcode: 'Zipcode',
        country: 'Country',
        status: 'Status',
        company: 'Company',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        gender: {
          male: 'Male',
          female: 'Female',
        },
        country: {
          France: 'France',
          Uk: 'Uk',
          Nederland: 'Nederland',
          Deutshland: 'Deutshland',
          Spain: 'Spain',
        },
        status: {
          initialized: 'Initialized',
          validated: 'Validated',
          canceled: 'Canceled',
          deactivated: 'Deactivated',
        },
      },
      new: {
        title: 'New Employee',
      },
      view: {
        title: 'View Employee',
      },
      importer: {
        title: 'Import Employees',
        fileName: 'employee_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    company: {
      name: 'company',
      label: 'Companies',
      menu: 'Companies',
      exporterFileName: 'company_export',
      list: {
        menu: 'Companies',
        title: 'Companies',
      },
      create: {
        success: 'Company saved successfully',
      },
      update: {
        success: 'Company saved successfully',
      },
      destroy: {
        success: 'Company deleted successfully',
      },
      destroyAll: {
        success: 'Company(s) deleted successfully',
      },
      edit: {
        title: 'Edit Company',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        address: 'Address',
        city: 'City',
        zipcode: 'Zipcode',
        numberEmployeeRange: 'NumberEmployee',
        numberEmployee: 'NumberEmployee',
        status: 'Status',
        opportunities: 'Opportunities',
        employees: 'Employees',
        legalForm: 'LegalForm',
        duns: 'Duns',
        capitalRange: 'Capital',
        capital: 'Capital',
        ceo: 'Ceo',
        executiveRange: 'Executive',
        executive: 'Executive',
        sharesholderRange: 'Sharesholder',
        sharesholder: 'Sharesholder',
        website: 'Website',
        activity: 'Activity',
        specialization: 'Specialization',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          initialized: 'Initialized',
          validated: 'Validated',
          canceled: 'Canceled',
          deactivated: 'Deactivated',
        },
      },
      new: {
        title: 'New Company',
      },
      view: {
        title: 'View Company',
      },
      importer: {
        title: 'Import Companies',
        fileName: 'company_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    category: {
      name: 'category',
      label: 'Categories',
      menu: 'Categories',
      exporterFileName: 'category_export',
      list: {
        menu: 'Categories',
        title: 'Categories',
      },
      create: {
        success: 'Category saved successfully',
      },
      update: {
        success: 'Category saved successfully',
      },
      destroy: {
        success: 'Category deleted successfully',
      },
      destroyAll: {
        success: 'Category(s) deleted successfully',
      },
      edit: {
        title: 'Edit Category',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        address: 'Address',
        city: 'City',
        zipcode: 'Zipcode',
        numberEmployeeRange: 'NumberEmployee',
        numberEmployee: 'NumberEmployee',
        status: 'Status',
        opportunities: 'Opportunities',
        employees: 'Employees',
        legalForm: 'LegalForm',
        duns: 'Duns',
        capitalRange: 'Capital',
        capital: 'Capital',
        ceo: 'Ceo',
        executiveRange: 'Executive',
        executive: 'Executive',
        sharesholderRange: 'Sharesholder',
        sharesholder: 'Sharesholder',
        website: 'Website',
        activity: 'Activity',
        specialization: 'Specialization',
        questions: 'Questions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          initialized: 'Initialized',
          validated: 'Validated',
          canceled: 'Canceled',
          deactivated: 'Deactivated',
        },
      },
      new: {
        title: 'New Category',
      },
      view: {
        title: 'View Category',
      },
      importer: {
        title: 'Import Categories',
        fileName: 'category_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    subcategory: {
      name: 'subcategory',
      label: 'Subcategories',
      menu: 'Subcategories',
      exporterFileName: 'category_export',
      list: {
        menu: 'Subcategories',
        title: 'Subcategories',
      },
      create: {
        success: 'Subcategory saved successfully',
      },
      update: {
        success: 'Subcategory saved successfully',
      },
      destroy: {
        success: 'Subcategory deleted successfully',
      },
      destroyAll: {
        success: 'Subcategory(s) deleted successfully',
      },
      edit: {
        title: 'Edit Subcategory',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        category: 'Category',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          initialized: 'Initialized',
          validated: 'Validated',
          canceled: 'Canceled',
          deactivated: 'Deactivated',
        },
      },
      new: {
        title: 'New Subcategory',
      },
      view: {
        title: 'View Subcategory',
      },
      importer: {
        title: 'Import Subcategory',
        fileName: 'subcategory_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    opportunitie: {
      name: 'opportunitie',
      label: 'Opportunities',
      menu: 'Opportunities',
      exporterFileName: 'opportunitie_export',
      list: {
        menu: 'Opportunities',
        title: 'Opportunities',
      },
      create: {
        success: 'Opportunitie saved successfully',
      },
      update: {
        success: 'Opportunitie saved successfully',
      },
      destroy: {
        success: 'Opportunitie deleted successfully',
      },
      destroyAll: {
        success: 'Opportunitie(s) deleted successfully',
      },
      edit: {
        title: 'Edit Opportunitie',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        companie: 'Companie',
        project: 'Project',
        volumeRange: 'Volume',
        volume: 'Volume',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Opportunitie',
      },
      view: {
        title: 'View Opportunitie',
      },
      importer: {
        title: 'Import Opportunities',
        fileName: 'opportunitie_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    question: {
      name: 'question',
      label: 'Questions',
      menu: 'Questions',
      exporterFileName: 'question_export',
      list: {
        menu: 'Questions',
        title: 'Questions',
      },
      create: {
        success: 'Question saved successfully',
      },
      update: {
        success: 'Question saved successfully',
      },
      destroy: {
        success: 'Question deleted successfully',
      },
      destroyAll: {
        success: 'Question(s) deleted successfully',
      },
      edit: {
        title: 'Edit Question',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        question: 'Question',
        order: 'Order',
        status: 'Status',
        proposals: 'Proposals',
        isRated: 'Is rated ? ',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        ponderation: 'Ponderation',
        eliminatory: 'is Elminatory ?',
        category: 'Category',
        type: 'type',
        worst: 'worst proposal',
        best: 'best proposal',
        hasLimit: 'Has Limit ?',
        limitMin: 'Limit Min',
        limitMax: 'Limit Max',
        template: 'Template',
        Theme: 'Theme',
        parent: 'Parent',
      },
      enumerators: {
        type: {
          free: 'Free',
          'multiple-list': 'Multiple list',
          'uniq-list': 'Uniq list',
          link: 'Link',
          file: 'File',
          numeric: 'Numeric',
        },
      },
      new: {
        title: 'New Question',
      },
      view: {
        title: 'View Question',
      },
      importer: {
        title: 'Import Questions',
        fileName: 'question_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    proposal: {
      name: 'proposal',
      label: 'Proposals',
      menu: 'Proposals',
      exporterFileName: 'proposal_export',
      list: {
        menu: 'Proposals',
        title: 'Proposals',
      },
      create: {
        success: 'Proposal saved successfully',
      },
      update: {
        success: 'Proposal saved successfully',
      },
      destroy: {
        success: 'Proposal deleted successfully',
      },
      destroyAll: {
        success: 'Proposal(s) deleted successfully',
      },
      edit: {
        title: 'Edit Proposal',
      },
      fields: {
        id: 'Id',
        proposal: 'Proposal',
        status: 'Status',
        question: 'Question',
        ponderation: 'Ponderation',
        eliminatory: 'is Elminatory ?',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          activated: 'Activated',
          deactivated: 'Deactivated',
        },
      },
      new: {
        title: 'New Proposal',
      },
      view: {
        title: 'View Proposal',
      },
      importer: {
        title: 'Import Proposals',
        fileName: 'proposal_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    response: {
      name: 'response',
      label: 'Responses',
      menu: 'Responses',
      exporterFileName: 'response_export',
      list: {
        menu: 'Responses',
        title: 'Responses',
      },
      create: {
        success: 'Response saved successfully',
      },
      update: {
        success: 'Response saved successfully',
      },
      destroy: {
        success: 'Response deleted successfully',
      },
      destroyAll: {
        success: 'Response(s) deleted successfully',
      },
      edit: {
        title: 'Edit Response',
      },
      fields: {
        id: 'Id',
        value: 'Value',
        proposal: 'Proposal',
        opportunitie: 'Opportunitie',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Response',
      },
      view: {
        title: 'View Response',
      },
      importer: {
        title: 'Import Responses',
        fileName: 'response_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
    theme: {
      name: 'theme',
      label: 'Themes',
      menu: 'Themes',
      exporterFileName: 'theme_export',
      list: {
        menu: 'Themes',
        title: 'Themes',
      },
      create: {
        success: 'Theme saved successfully',
      },
      update: {
        success: 'Theme saved successfully',
      },
      destroy: {
        success: 'Theme deleted successfully',
      },
      destroyAll: {
        success: 'Theme(s) deleted successfully',
      },
      edit: {
        title: 'Edit Theme',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        type: 'Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Theme',
      },
      view: {
        title: 'View Theme',
      },
      importer: {
        title: 'Import Themes',
        fileName: 'theme_import_theme',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    answer: {
      name: 'answer',
      label: 'Answers',
      menu: 'Answers',
      exporterFileName: 'answer_export',
      list: {
        menu: 'Answers',
        title: 'Answers',
      },
      create: {
        success: 'Answer saved successfully',
      },
      update: {
        success: 'Answer saved successfully',
      },
      destroy: {
        success: 'Answer deleted successfully',
      },
      destroyAll: {
        success: 'Answer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Answer',
      },
      fields: {
        id: 'Id',
        value: 'Value',
        proposal: 'Proposal',
        opportunitie: 'Opportunitie',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Answer',
      },
      view: {
        title: 'View Answer',
      },
      importer: {
        title: 'Import Answers',
        fileName: 'answer_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: 'Please confirm your email at <strong>{0}</strong> to continue.',
      submit: 'Resend email verification',
    },
    emptyPermissions: {
      message: 'You have no permissions yet. Wait for the admin to grant you privileges.',
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: 'Email not recognized',
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: 'Email not recognized',
    },
    verificationEmailSuccess: 'Verification email sent successfully',
    passwordResetEmailSuccess: 'Password reset email sent successfully',
    passwordResetSuccess: 'Password changed successfully',
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: 'Full access to manage users roles',
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    employeeEditor: {
      label: 'Employee Editor',
      description: 'Edit access to Employees',
    },
    employeeViewer: {
      label: 'Employee Viewer',
      description: 'View access to Employees',
    },
    companyEditor: {
      label: 'Company Editor',
      description: 'Edit access to Companies',
    },
    companyViewer: {
      label: 'Company Viewer',
      description: 'View access to Companies',
    },
    opportunitieEditor: {
      label: 'Opportunitie Editor',
      description: 'Edit access to Opportunities',
    },
    opportunitieViewer: {
      label: 'Opportunitie Viewer',
      description: 'View access to Opportunities',
    },
    questionEditor: {
      label: 'Question Editor',
      description: 'Edit access to Questions',
    },
    questionViewer: {
      label: 'Question Viewer',
      description: 'View access to Questions',
    },
    proposalEditor: {
      label: 'Proposal Editor',
      description: 'Edit access to Proposals',
    },
    proposalViewer: {
      label: 'Proposal Viewer',
      description: 'View access to Proposals',
    },
    responseEditor: {
      label: 'Response Editor',
      description: 'Edit access to Responses',
    },
    responseViewer: {
      label: 'Response Viewer',
      description: 'View access to Responses',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space.' +
        ' Relationships must be the ID of the referenced records separated by space.' +
        ' Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: "You can't disable yourself",
      revokingOwnPermission: "You can't revoke your own owner permission",
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
    list: {
      columns: {
        email: 'Name',
        company: 'Company',
        role: 'Role',
        first_name: 'First name',
        last_name: 'Name',
        status: 'Status',
        nb_accounts: 'Nb accounts',
        actions: '',
      },
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success: 'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      primary: 'Primary Color',
      secondary: 'Secondary Color',
      shade: 'Shade',
    },
  },
  home: {
    menu: 'Home',
    message:
      'This page uses fake data for demonstration purposes only. You can edit it at frontend/view/home/HomePage.js.',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: "Sorry, you don't have access to this page",
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
    noOptions: 'No data found',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  table: {
    noData: 'No records found',
    loading: 'Loading...',
  },

  pagination: {
    labelDisplayedRows: '{0}-{1} of {2}',
    labelRowsPerPage: 'Per page:',
  },
  sources: {
    [SOURCES.STACK]: 'Stack',
    [SOURCES.INSIGHTS]: 'Insights',
    [SOURCES.INSIGHTS_POS]: 'Insights POS',
  },

  logs: {
    ApiKey: {
      entityName: 'API key',
      status: 'API key status',
      name: 'API key name',
    },
    List: {
      entityName: 'List',
      content: 'List content',
    },
    MerchantAccount: {
      entityName: 'Merchant account',
      cms_name: 'CMS name',
      cms_name__options: cmsList.reduce((acc, cms) => ({
        ...acc,
        [cms.value]: cms.label
      }), {}),
      cms_version: 'CMS version',
      integration_type: 'Integration type',
      integration_type__options: integrationTypeList.reduce((acc, int_type) => ({...acc, [int_type.value]: int_type.label}), {}),
      fallback_settings: {
        status: 'Fallback service status',
        max_attempts: 'Fallback max. number of attempts',
        max_retries: 'Fallback max. number of retries',
        count_psp_unavailability: 'Fallback include PSP downtimes in count',
        ask_3ds_default: {
          value: 'Fallback default value for ask_3DS',
          value__options: ChallengePreferences.reduce((acc, pref) => ({ ...acc, [pref.id]: pref.label }), {}),
        },
        force_3ds_default: {
          value: 'Fallback default value for force_3DS',
          value__options: ChallengePreferences.reduce((acc, pref) => ({ ...acc, [pref.id]: pref.label }), {}),
        },
        no_3ds_default: {
          value: 'Fallback default value for no_3DS',
          value__options: ExemptionReasons.reduce((acc, pref) => ({ ...acc, [pref.id]: pref.label }), {}),
        },
        authorization_error_codes: codesList.reduce((acc, code) => ({
          ...acc,
          [code.id]: {
            active: `${code.label} activate fallback`,
            retry_on_same_psp: `${code.label} retry on`,
            retry_on_same_psp__options: {
              true: 'Same PSP',
              false: 'Other PSP',
            },
            result: {
              type: `${code.label} type`,
              type__options: {
                ...challengePreferenceOptions.reduce((acc, opt) => ({
                  ...acc,
                  [opt.type]: opt.label,
                }), {}),
                ...exemptionReasonOptions.reduce((acc, opt) => ({
                  ...acc,
                  [opt.type]: opt.label,
                }), {}),
                inherit: 'Inherit',
              },
              value: `${code.label} value`,
              value__options: {
                ...challengePreferenceOptions.reduce((acc, opt) => ({
                  ...acc,
                  [opt.value]: opt.label,
                }), {}),
                ...exemptionReasonOptions.reduce((acc, opt) => ({
                  ...acc,
                  [opt.value]: opt.label,
                }), {}),
                inherit: 'Inherit',
              }
            }
          },
        }), {}),
        authentication_failed: 'Activate fallback',
        authentication_succeed: 'Forced authorization',
        authorization_failed: 'Authorization failed',
      },
      scheduled_capture: {
        status: 'Capture',
        status__options: {
          active: 'Scheduled capture',
          inactive: 'Capture on demand',
        },
        delay: 'Capture delay (in minutes)'
      },
      hosted_page_configuration: {
        status: 'Hosted page configuration status',
        logo: 'Hosted page logo',
        primary_color: 'Hosted page primary color',
        show_pci_logo: 'Hosted page show PCI logo',
        hosted_fields: {
          display_buttons: 'Hosted page display pay button by payment type',
          display_cardholder: 'Hosted page display cardholder field',
          display_save: 'Hosted page display save card option',
        },
      },
      exception_url: 'Exception URL',
      pending_url: 'Pending URL',
      decline_url: 'Decline URL',
      accept_url: 'Accept URL',
    },
    MerchantContract: {
      entityName: 'Merchant contract',
      status: 'Merchant contract status',
      settlement_currency: 'Settlement currency',
      channels: 'Sales channels',
      authentication_contract: 'Authentication contract',
      authentication_managed: 'Authentication',
      authentication_managed__options: {
        byPsp: 'Managed by PSP',
        by3rdParty: 'Managed by an authentication service provider',
        null: 'None',
      },
      risk_assessment_contract: 'Risk assessment contract',
      risk_assessment_managed: 'Risk assessment',
      risk_assessment_managed__options: {
        byPsp: 'Managed by PSP',
        by3rdParty: 'Managed by a risk assessment service provider',
        null: 'None',
      },
      payment_method: 'Payment method',
      payment_method__options: paymentMethods,
      name: 'Merchant contract name',
      payment_methods: {
        sales_channels: 'Sales channels',
        sales_channels__options: {
          moto: 'Moto',
          'e-commerce': 'E-Commerce',
          recurring: 'Recurring',
          pos: 'POS',
        },
        authorization_currencies: 'Authorization currencies',
      },
    },
    Rule: {
      entityName: 'Rule',
      status: 'Rule status',
      name: 'Rule name',
    },
    User: {
      entityName: 'User',
      disabled: 'Disabled',
      deleted: 'Deleted',
      authorizedMerchantAccounts: 'Access to merchant account',
      isGdpr: 'Has GDPR access',
      profile: 'User profile',
      avatar: 'Avatar',
    },
    Webhook: {
      entityName: 'Notification',
      name: 'Notification name',
      url: 'Notification URL',
      trigger: 'Notification trigger',
      trigger__options: TRANSACTION_STATUS_LIST.reduce((acc, int_type) => ({...acc, [int_type.value]: int_type.label}), {}),
      additional_headers: 'Notification additional headers',
      notify_last_transaction_only: 'Notification notify last transaction only?',
      status: 'Notification status',
    },
    MerchantCompany: {
      entityName: 'Merchant company',
      banner: 'Company banner',
      icon: 'Company icon',
      logo: 'Company logo',
      primaryColor: 'Company primary color',
      secondaryColor: 'Company secondary color',
    }
  },
  testRouter: {
    card_issuer: 'Card issuer',
    card_6_digits: 'BIN',
    merchant_store_id: 'Merchant store ID',
    customer_id: 'Customer ID',
    customer_email: 'Customer email',
    customer_ip: 'Customer IP',
    basket_content: 'Basket',
    customer_zip_code: 'Customer zip code',
    merchant_data: 'Merchant data',
    card_country: 'Card country',
    customer_ip_country: 'Customer IP country',
    payment_channel: 'Payment channel',
    payment_method_name: 'Payment method name',
    payment_method_type: 'Payment method type',
    reference_currency_amount: 'Amount (in the reference currency)',
    currency: 'Currency',
    authentication_indicator: 'Authentication indicator',
    company_id: 'Company',
    merchant_account: 'Merchant account',
  }
};

export default en;
