import {gql} from '@apollo/client';

export const registerQuery = gql`
    query Register($date: String!, $merchantAccount: ID!) {
        register(date: $date, merchantAccount: $merchantAccount) {
            _id {
                matcher_order_date
                merchantAccount
            }
            details {
                matcher_order_date
                matcher_store
                matcher_notification_date
                matcher_matched_transactions
                matcher_unmatched_transactions
                matcher_matched_amount
                matcher_unmatched_amount
                matcher_amount_gap
                matcher_total_transactions
                matcher_total_amount
                matcher_reconciliation_type
                matcher_reason
                currency
            }
            createdAt
        }
    }
`;