import React, { useContext } from 'react';
import { Form, Select } from 'antd';
import CURRENCY_LIST from 'norbr-shared-lib/constants/currencies/list';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { MerchantContractContext } from '../../../MerchantContractDrawer';
import { ContractEditionContext } from '../../../../../PaymentNetwork';
import { compareByProp } from '../../../../../../../../../util/array';
import SOURCES from '../../../../../../../../../constants/SOURCES';

const { Item } = Form;

const MerchantContractSettlementCurrency = () => {
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);

  return (
    <Item label="Settlement currency">
      <Select
        disabled={!isEdited || merchantContract?.merchant_routes?.length}
        options={(merchantContract?.source === SOURCES.STACK
          ? merchantContract?.partner?.settlement_currencies?.map((settlementCurrency) => {
              const { id, label } = CURRENCIES[settlementCurrency];
              return {
                value: id,
                label: `[${id}] - ${label}`,
              };
            }) ?? []
          : CURRENCY_LIST.map(({ id, label }) => ({
              value: id,
              label: `[${id}] - ${label}`,
            }))
        ).sort(compareByProp('label'))}
        onChange={(value) =>
          updateMerchantContract({
            variables: {
              input: {
                settlement_currency: value,
              },
            },
            optimisticResponse: {
              updateContract: {
                ...merchantContract,
                settlement_currency: value,
              },
            },
          })
        }
        value={merchantContract?.settlement_currency}
        locked={merchantContract?.payment_methods?.length > 0}
        showSearch
      />
    </Item>
  );
};

export default MerchantContractSettlementCurrency;
