import React from 'react';
import { Button, DatePicker, Drawer, Form, Select, Space, Tag } from 'antd';
import { useQuery } from '@apollo/client';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import AlertCircleIcon from '@2fd/ant-design-icons/lib/AlertCircle';
import CheckCircleIcon from '@2fd/ant-design-icons/lib/CheckCircle';
import ProgressClockIcon from '@2fd/ant-design-icons/lib/ProgressClock';
import moment from 'moment-timezone';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Block } from '../../../../Common/Sider';
import usePage from '../../../hooks/usePage';
import {
  merchantAccountsQuery,
  merchantCompaniesQuery,
  partnersQuery,
  paymentMethodsQuery,
  programManagersQuery,
} from './query';
import { MatcherCashierFields, MatcherCashierFieldsEnum } from '../fields';
import { DATE_FORMAT, DATETIME_FORMAT } from '../../../../../../constants/DATES';
import { searchByProp } from '../../../../../../util/string';
import { ModeInternal, ModeProgramManager } from '../../../../../../constants/mode';
import ProgramManagerAvatar from '../../../../Common/ProgramManager/Avatar';

const SearchDrawerContent = ({ searchFilters, onSearch, onClose }) => {
  const page = usePage();

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    onSearch({
      ...values,
      matcher_order_date: values.matcher_order_date
        ? [
            values.matcher_order_date[0].startOf('day').format(DATETIME_FORMAT),
            values.matcher_order_date[1].endOf('day').format(DATETIME_FORMAT),
          ]
        : undefined,
      matcher_notification_date: values.matcher_notification_date
        ? [
            values.matcher_notification_date[0].startOf('day').format(DATETIME_FORMAT),
            values.matcher_notification_date[1].endOf('day').format(DATETIME_FORMAT),
          ]
        : undefined,
    });
    onClose();
  };

  const { data: { programManagers } = { v: [] }, loading: loadingProgramManagers } = useQuery(programManagersQuery, {
    skip: !ModeInternal,
  });
  const { data: { merchantCompanies } = { v: [] }, loading: loadingMerchantCompanies } = useQuery(
    merchantCompaniesQuery,
    { skip: !(ModeInternal || ModeProgramManager) },
  );
  const { data: { merchantAccounts } = { merchantAccounts: [] }, loading: loadingMerchantAccounts } =
    useQuery(merchantAccountsQuery);
  const { data: { partners: partnerPaymentMethods } = { partners: [] }, loading: loadingPaymentMethods } =
    useQuery(paymentMethodsQuery);
  const { data: { partners } = { partners: [] }, loading: loadingPartners } = useQuery(partnersQuery);

  return (
    <Block key="search-form" title="Search" description="Filter export list">
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={{
          ...searchFilters,
          matcher_order_date: searchFilters.matcher_order_date
            ? [
                moment(searchFilters.matcher_order_date[0], DATETIME_FORMAT),
                moment(searchFilters.matcher_order_date[1], DATETIME_FORMAT),
              ]
            : undefined,
          matcher_notification_date: searchFilters.matcher_notification_date
            ? [
                moment(searchFilters.matcher_notification_date[0], DATETIME_FORMAT),
                moment(searchFilters.matcher_notification_date[1], DATETIME_FORMAT),
              ]
            : undefined,
        }}
      >
        {[
          <Form.Item label="Order date" name="matcher_order_date" key="matcher_order_date">
            <DatePicker.RangePicker format={DATE_FORMAT} style={{ width: '100%' }} />
          </Form.Item>,
          <Form.Item label="Notification date" name="matcher_notification_date" key="matcher_notification_date">
            <DatePicker.RangePicker format={DATE_FORMAT} style={{ width: '100%' }} />
          </Form.Item>,
          <Form.Item label="Status" name="matcher_register_status">
            <Select
              options={[
                {
                  value: 'pending',
                  label: (
                    <Tag color="grey">
                      <ProgressClockIcon /> Pending
                    </Tag>
                  ),
                },
                {
                  value: 'matched_warning',
                  label: (
                    <Tag color="orange">
                      <AlertCircleIcon /> Matched
                    </Tag>
                  ),
                },
                {
                  value: 'matched_success',
                  label: (
                    <Tag color="green">
                      <CheckCircleIcon /> Matched
                    </Tag>
                  ),
                },
                {
                  value: 'closed_warning',
                  label: (
                    <Tag color="orange">
                      <AlertCircleIcon /> Closed
                    </Tag>
                  ),
                },
                {
                  value: 'closed_success',
                  label: (
                    <Tag color="green">
                      <CheckCircleIcon /> Closed
                    </Tag>
                  ),
                },
              ]}
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              showSearch
            />
          </Form.Item>,
          <Form.Item label="Payment method" name="matcher_payment_method" key="matcher_payment_method">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              showSearch
              filterOption={searchByProp('label')}
              loading={loadingPaymentMethods}
            >
              {partnerPaymentMethods
                ?.flatMap((p) => p.payment_methods)
                // dedupe
                ?.filter((pm, index, list) => list.findIndex((elem) => pm.api_name === elem.api_name) === index)
                .map((pm) => (
                  <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                    <Space>
                      {pm.imgUrl && <img src={pm.imgUrl} alt="" style={{ height: 22 }} />}
                      {pm.name}
                    </Space>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>,
          ...(ModeInternal
            ? [
              <Form.Item
                label="Program managers"
                name={MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER}
                key={MatcherCashierFieldsEnum.MATCHER_PROGRAM_MANAGER}
              >
                <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear
                  showSearch
                  filterOption={searchByProp('label')}
                  loading={loadingProgramManagers}
                >
                  {programManagers.map((pm) => (
                    <Select.Option key={pm.id} value={pm.id} label={pm.name}>
                      <Space>
                        <ProgramManagerAvatar programManager={pm} />
                        {pm.name}
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>,
            ]
            : []),
          ...(ModeInternal || ModeProgramManager
            ? [
                <Form.Item
                  label="Company"
                  name={MatcherCashierFieldsEnum.MATCHER_COMPANY}
                  key={MatcherCashierFieldsEnum.MATCHER_COMPANY}
                >
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    allowClear
                    showSearch
                    filterOption={searchByProp('label')}
                    loading={loadingMerchantCompanies}
                  >
                    {merchantCompanies.map((mc) => (
                      <Select.Option key={mc.id} value={mc.id} label={mc.name}>
                        <Space>
                          {mc.theme.icon && <img src={mc.theme.icon} alt="" style={{ height: 22 }} />}
                          {mc.name}
                        </Space>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>,
              ]
            : []),
          <Form.Item label="Store" name="matcher_store" key="matcher_store">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              showSearch
              filterOption={searchByProp('label')}
              loading={loadingMerchantAccounts}
            >
              {merchantAccounts.map((ma) => (
                <Select.Option key={ma.id} value={ma.id} label={ma.name}>
                  <Space>
                    {ma.company?.theme.icon && <img src={ma.company?.theme.icon} alt="" style={{ height: 22 }} />}
                    {ma.name}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>,
          <Form.Item label="Payin partner" name="matcher_payin_partner" key="matcher_payin_partner">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              showSearch
              filterOption={searchByProp('label')}
              loading={loadingPartners}
            >
              {partners
                ?.filter((partner) => partner.type === 'payin')
                .map((partner) => (
                  <Select.Option key={partner.api_name} value={partner.api_name} label={partner.name}>
                    <Space>
                      {partner.company.theme.icon && (
                        <img src={partner.company.theme.icon} alt="" style={{ height: 22 }} />
                      )}
                      {partner.name}
                    </Space>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>,
          <Form.Item label="Cashier partner" name="matcher_cashier" key="matcher_cashier">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              showSearch
              filterOption={searchByProp('label')}
              loading={loadingPartners}
            >
              {partners
                ?.filter((partner) => partner.type === 'cashier')
                .map((partner) => (
                  <Select.Option key={partner.api_name} value={partner.api_name} label={partner.name}>
                    <Space>
                      {partner.company.theme.icon && (
                        <img src={partner.company.theme.icon} alt="" style={{ height: 22 }} />
                      )}
                      {partner.name}
                    </Space>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>,
        ].reduce((acc, Component) => {
          const fieldName = Component.props.name;
          const isOnPage = MatcherCashierFields[fieldName]?.filterOptions?.pages?.includes(page.name);
          const hasValue = !!searchFilters[fieldName];
          if (isOnPage) {
            return [...acc, Component];
          }
          if (hasValue) {
            return [
              ...acc,
              React.cloneElement(Component, {
                tooltip: {
                  title: <span>Not applicable in Summary mode.</span>,
                  icon: <ExclamationCircleFilled style={{ color: 'var(--ant-error-color)' }} />,
                },
              }),
            ];
          }
          return acc;
        }, [])}
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            SEARCH
          </Button>
        </Form.Item>
      </Form>
    </Block>
  );
};

const SearchDrawer = ({ open, onClose, onSearch, searchFilters, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Search"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <SearchDrawerContent onClose={onClose} onSearch={onSearch} searchFilters={searchFilters} />
  </Drawer>
);

export default SearchDrawer;
