import React, { useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { applyTheme, defaultTheme } from '../util/theme';
import { useProgramManagerId } from './app.context';
import AppLoading from '../AppLoading';
import domain from '../constants/domain';

const programManagerDomainQuery = gql`
  query ProgramManagerDomain($domain: String!) {
    programManagerDomain(domain: $domain) {
      id
      displayName
      website
      merchantDomain
      adminDomain
      alternativeAdminDomain
      alternativeMerchantDomain
      theme {
        favicon
        icon
        logo
        background
        primaryColor
        secondaryColor
        loader {
          img
          type
          animation
        }
      }
      loginMethod
      sso {
        config {
          tenantId
          providerId
        }
      }
    }
  }
`;

export const ProgramManagerContext = React.createContext(undefined);

export const useProgramManager = () => useContext(ProgramManagerContext);

const ProgramManagerProvider = ({ children }) => {
  const [programManagerId, setProgramManagerId] = useProgramManagerId();

  const { data } = useQuery(programManagerDomainQuery, {
    // override Authorization header to always request as unlogged user
    context: {
      headers: {
        Authorization: undefined,
      },
    },
    variables: { domain },
    skip: !domain,
    onCompleted: (res) => {
      setProgramManagerId(res.programManagerDomain?.id);
      document.title = res.programManagerDomain?.displayName;
      if (res.programManagerDomain?.theme?.favicon) {
        const favicon = document.getElementById('favicon');
        favicon.href = res.programManagerDomain?.theme?.favicon;
      }
      applyTheme({
        primaryColor: res.programManagerDomain?.theme.primaryColor || defaultTheme.primaryColor,
        secondaryColor: res.programManagerDomain?.theme.secondaryColor || defaultTheme.secondaryColor,
        loader: res.programManagerDomain?.theme?.loader,
      });
    },
  });

  useEffect(() => {
    if (!domain) {
      document.title = 'NORBr';
      applyTheme();
    }
  }, []);

  if (domain && !programManagerId) return <AppLoading />;

  return <ProgramManagerContext.Provider value={data?.programManagerDomain}>{children}</ProgramManagerContext.Provider>;
};

export default ProgramManagerProvider;
