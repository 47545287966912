import React, { useContext, useEffect, useState } from 'react';

export const AppContext = React.createContext(undefined);

export const useToken = () => useContext(AppContext).token;
export const useProgramManagerId = () => useContext(AppContext).programManagerId;
export const useCompany = () => useContext(AppContext).company;
export const useLogout = () => useContext(AppContext).logout;

// get token, first in url (ConnectAs), then in local storage
const getLocalToken = () => new URLSearchParams(window.location.search).get('token') || localStorage.getItem('token');
// set / remove token in local storage
const setLocalToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};

const AppProvider = ({ children }) => {
  const [token, setToken] = useState(getLocalToken());

  useEffect(() => {
    setLocalToken(token);
  }, [token]);

  const [programManagerId, setProgramManagerId] = useState();
  const [company, setCompany] = useState();

  const logout = () => {
    setToken(undefined);
    setProgramManagerId(undefined);
    setCompany(undefined);
  };

  return (
    <AppContext.Provider
      value={{
        logout,
        token: [token, setToken],
        programManagerId: [programManagerId, setProgramManagerId],
        company: [company, setCompany],
      }}
    >
      {children(token)}
    </AppContext.Provider>
  );
};

export default AppProvider;
