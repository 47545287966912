import React, { useState } from 'react';
import { Button, Image, message, Space, Upload } from 'antd';
import { CameraOutlined, EyeOutlined } from '@ant-design/icons';
import TrashCanIcon from "@2fd/ant-design-icons/lib/TrashCan";
import ImgCrop from 'antd-img-crop';
import styles from './Uploader.module.scss';

const CropImgUploader = ({ value, onChange, label, info, aspect }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Space>
      <ImgCrop
        aspect={aspect}
        beforeCrop={(file) => {
          if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            message.error(`${file.name} is not a png/jpeg file`);
          }
          return file.type === 'image/png' || file.type === 'image/jpeg';
        }}
      >
        <Upload
          listType="picture-card"
          className={styles.upload}
          multiple={false}
          showUploadList={false}
          customRequest={({ file }) => onChange(file)}
          accept="image/png,image/jpeg"
        >
          {value ? (
            [
              <img src={value} alt="avatar" className={styles.img} />,
              <Space size={4} className={styles.btnContainer}>
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<TrashCanIcon />}
                  onClick={(e) => {
                    onChange(null);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<EyeOutlined />}
                  onClick={(e) => {
                    setVisible(true);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </Space>,
            ]
          ) : (
            <CameraOutlined style={{ fontSize: 'large', color: 'grey' }} />
          )}
        </Upload>
      </ImgCrop>
      <div>
        <div>{label}</div>
        {info ? <div className={styles.info}>{info}</div> : <div className={styles.info}>PNG or JPEG</div>}
      </div>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={value}
        preview={{
          visible,
          src: value,
          onVisibleChange: setVisible,
        }}
      />
    </Space>
  );
};

export default CropImgUploader;
