import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import { Button, Typography } from 'antd';
import classnames from 'classnames';
import { useMutation } from '@apollo/client';
import { SSO_SIGN_IN } from './query';
import { useProgramManager } from '../../../../contexts/programManager.context';
import styles from '../../AppUnlogged.module.scss';
import { useToken } from '../../../../contexts/app.context';
import Loader from '../../../../Layout/Routes/Common/Loader/Loader';
import useNavigateWithSearch from '../../../../util/navigate';

const LoginSso = ({ redirectAfter }) => {
  const programManager = useProgramManager();
  const [, setToken] = useToken();
  const navigate = useNavigateWithSearch();

  const [ssoSignIn, { loading: ssoLoading, error: ssoError }] = useMutation(SSO_SIGN_IN);

  const app = initializeApp({
    apiKey: programManager ? process.env.REACT_APP_FIREBASE_API_KEY : 'AIzaSyBmq818RzCE1Yj6EGn27ZXlNKGnbJG6dCM',
    authDomain: programManager ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN : 'norbr-ciam-internal.firebaseapp.com',
  }, 'login');

  const auth = getAuth(app);

  auth.tenantId = programManager?.sso.config.tenantId ?? 'norbr-qb888';
  const provider = new SAMLAuthProvider(programManager?.sso.config.providerId ?? 'saml.norbr');

  auth.useDeviceLanguage();

  if (ssoLoading) {
    return <Loader />
  }

  return (
    <>
      <Button
        type="primary"
        className={classnames(styles.submitBtn, styles.sso)}
        disabled={ssoLoading}
        onClick={() => signInWithPopup(auth, provider).then(value =>
          ssoSignIn({
            variables: {
              token: value.user.accessToken,
            }
          }).then(({ data }) => {
            setToken(data.ssoSignIn);
            navigate(redirectAfter || `/brainpower/dashboard`);
          }).catch((e) => console.error(e))
        ).catch(e =>
          // TODO : handle sso error here
          console.error(e)
        )}
      >
        Sign in
      </Button>
      {!!ssoError && (
        <Typography.Text id="error-message" className={styles.error} type="danger">
          {ssoError.message}
        </Typography.Text>
      )}
    </>
  );
};

export default LoginSso;