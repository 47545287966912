import React, { useCallback, useState } from 'react';
import { Button, Divider, Drawer, Input, List, message, Space, Tag } from 'antd';
import { SearchOutlined, StarFilled } from '@ant-design/icons';
import CogIcon from '@2fd/ant-design-icons/lib/Cog';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import { useSet } from 'react-use';
import VirtualList from 'rc-virtual-list';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { compareByProp, reorder } from '../../../../../util/array';
import useRandom from '../../../../../hooks/useRandom';
import { search } from '../../../../../util/string';
import { Block } from '../../../Common/Sider';
import SortableList from '../../../Common/SortableList/SortableList';
import SortableItem from '../../../Common/SortableList/SortableItem';
import { updateSortInSearchParams } from '../../hooks/useSort';
import useColumnSet, { updateColumnSetInSearchParams } from '../../hooks/useColumnSet';
import { useBrainpowerViewSettings } from '../../hooks/useBrainpowerSettings';
import { updateTargetEntityInSearchParams } from '../../hooks/useTargetEntity';
import { updateTableDisplayInSearchParams } from '../../hooks/useTableDisplay';
import usePage, { PAGE } from '../../hooks/usePage';
import { TableDisplay } from '../constants/tableDisplays';
import targetEntities, { TargetEntity } from '../constants/targetEntities';
import AddableItem from './Item/AddableItem';
import Item from './Item/Item';
import SavedViewEditDrawer from './SavedViewEditDrawer/SavedViewEditDrawer';
import SavedViewSaveDrawer from './SavedViewSaveDrawer/SavedViewSaveDrawer';
import styles from './SetUpDrawer.module.scss';
import DrawerListItem from '../DrawerListItem/DrawerListItem';

const { CheckableTag } = Tag;

const SetUpContent = ({ containerId }) => {
  const page = usePage();
  const [settings, updateSettings] = useBrainpowerViewSettings();

  const { favorite = 'default', views = [] } = settings;

  const [key, refreshKey] = useRandom();

  const [columnSet, setColumnSet] = useColumnSet();

  const handleSort = useCallback(
    (sourceIndex, destinationIndex) => {
      // reorder columns
      const newColumnSet = reorder(columnSet, sourceIndex, destinationIndex);
      setColumnSet(newColumnSet);
    },
    [columnSet, setColumnSet],
  );

  const addColumn = useCallback(
    (fieldKey) => {
      setColumnSet([
        ...columnSet,
        {
          field: fieldKey,
          label: page.fields[fieldKey].label.toUpperCase(),
          ...page.fields[fieldKey].defaultSettings,
        },
      ]);
      message.success(
        <span>
          <b>{page.fields[fieldKey].label}</b> column added to the right of the table.
        </span>,
      );
    },
    [columnSet, setColumnSet],
  );

  const removeColumn = useCallback(
    (fieldKey) => {
      setColumnSet(columnSet.filter((c) => c.field !== fieldKey));
    },
    [columnSet, setColumnSet],
  );

  const [openColumn, setOpenColumn] = useState();
  const handleToggleOpen = useCallback(
    (fieldKey) => setOpenColumn(openColumn === fieldKey ? null : fieldKey),
    [openColumn, setOpenColumn],
  );

  const handleColumnChange = useCallback(
    (column) => {
      setColumnSet(columnSet.map((c) => (c.field === column.field ? column : c)));
    },
    [columnSet, setColumnSet],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const applyView = (view) => () => {
    updateSettings({
      favorite,
      views: views.map((s) =>
        s._id === view._id
          ? {
              ...s,
              lastUse: moment().toISOString(),
            }
          : s,
      ),
    });
    const newSearchParams = new URLSearchParams(searchParams);
    updateColumnSetInSearchParams(newSearchParams, view.columnSet ?? [], page.prefix);
    updateSortInSearchParams(newSearchParams, view.sort ?? [], page.prefix);
    updateTableDisplayInSearchParams(newSearchParams, view.display ?? TableDisplay.RICH, page.prefix);
    updateTargetEntityInSearchParams(newSearchParams, view.entity ?? TargetEntity.ORDER, page.prefix);
    setSearchParams(newSearchParams);
    refreshKey();
  };

  const [editView, setEditView] = useState();
  const [saveView, setSaveView] = useState();
  const [searchInput, setSearchInput] = useState();

  const [searchEntitySet, { toggle, has }] = useSet(new Set(page.displayEntities));

  return [
    <Block key="saved-views" title="Saved views" description="Click to apply view" collapsable>
      <List className={styles.list} bordered={false}>
        {[page.defaultView, ...views]
          .sort((a, b) => {
            switch (favorite) {
              case a._id:
                return -1;
              case b._id:
                return 1;
              default:
                return b.lastUse > a.lastUse ? 1 : -1;
            }
          }) // pinned first then sorted by last use
          .map((view) => (
            <DrawerListItem
              key={view._id}
              label={view.label}
              extra={[
                <Button
                  key="edit"
                  type="text"
                  icon={<CogIcon />}
                  title="Edit saved view"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditView(view);
                  }}
                />,
              ]}
              onClick={applyView(view)}
              pinned={favorite === view._id}
            />
          ))}
      </List>
      <Button className={styles.addViewBtn} block icon={<StarFilled />} onClick={() => setSaveView(true)}>
        SAVE VIEW
      </Button>
    </Block>,
    <Block
      key={`set-up-form-${key}`}
      title="Display"
      description="Drag to sort columns, click to edit settings"
      collapsable
    >
      <div className={styles.subHeader}>
        <span>Active columns</span>
        <Tag>{columnSet.length}</Tag>
      </div>
      <SortableList droppableId="columns" type="columns" onSort={handleSort}>
        {columnSet.map((column, index) => (
          <SortableItem key={`column-${column.field}`} id={`column-${column.field}`} index={index}>
            <Item
              column={column}
              field={page.fields[column.field]}
              open={openColumn === column.field}
              toggleOpen={handleToggleOpen}
              onRemove={removeColumn}
              onChange={handleColumnChange}
            />
          </SortableItem>
        ))}
      </SortableList>
    </Block>,
    <Block key={`set-up-available-${key}`} title="Available columns" description="Click to add column" collapsable>
      <div style={{ padding: '0 16px 16px 16px' }}>
        <Input
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search a column ..."
          allowClear
        />
      </div>
      <Space style={{ marginBottom: 16 }}>
        <span style={{ color: 'grey' }}>Category</span>
        <div>
          {page.displayEntities.map((entity) => (
            <CheckableTag key={entity} checked={has(entity)} onChange={() => toggle(entity)}>
              <Space size={2}>
                {targetEntities[entity].icon()}
                {targetEntities[entity].label}
              </Space>
            </CheckableTag>
          ))}
        </div>
      </Space>

      <Divider style={{ margin: '0 0 4px 0' }}/>
      <VirtualList
        data={
          Object.values(page.fields)
            .filter(
              (field) =>
                !columnSet.find((c) => c.field === field.key) && // remove selected
                (search(searchInput, field.label) || search(searchInput, field.key)) && // filter by search
                (searchEntitySet.has(field.entity) || // filter by entity
                  // (exceptions on CheckoutList should have pm, company, ma, base on order path)
                  (page.name === PAGE.CHECKOUT_LIST &&
                    [Fields.PROGRAM_MANAGER, Fields.COMPANY, Fields.MERCHANT_ACCOUNT].includes(field.key))),
            )
            .sort(compareByProp('label'))
            .concat(null) // Fix - increase the scroll area height to handle an opened item
        }
        height={window.innerHeight - 362}
        itemHeight={60}
        itemKey="key"
        style={{ margin: '0 -4px' }}
      >
        {(field) =>
          field === null ? (
            <div style={{ height: 160 }} /> // Fix - increase the scroll area height to handle an opened item
          ) : (
            <AddableItem
              key={field.key}
              field={field}
              onAdd={addColumn}
              open={openColumn === field.key}
              toggleOpen={handleToggleOpen}
            />
          )
        }
      </VirtualList>
    </Block>,
    <SavedViewEditDrawer
      key="saved-view-edit-drawer"
      view={editView}
      open={!!editView}
      onClose={() => setEditView(undefined)}
      onBack={() => setEditView(undefined)}
      containerId={containerId}
    />,
    <SavedViewSaveDrawer
      key="saved-view-save-drawer"
      open={saveView}
      onClose={() => setSaveView(false)}
      onBack={() => setSaveView(false)}
      containerId={containerId}
    />,
  ];
};

const SetUpDrawer = ({ open, onClose, containerId }) => (
  <Drawer
    width={470}
    open={open}
    getContainer={`#${containerId}`}
    style={{ position: 'absolute', zIndex: 50 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title="Display"
    closable
    closeIcon={<WindowCloseIcon />}
    mask={false}
    push={false}
  >
    <SetUpContent onClose={onClose} containerId={containerId} />
  </Drawer>
);

export default SetUpDrawer;
