import React from 'react';
import moment from 'moment-timezone';
import { useLazyQuery, useQuery as useApolloQuery } from '@apollo/client';
import { Space, Timeline } from 'antd';
import { useParams } from 'react-router-dom';
import styles from './Timeline.module.scss';
import { compareByProp } from '../../../../../util/array';
import TRANSACTION_STATUSES from '../../../../../constants/TRANSACTION_STATUSES';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';
import usePaymentMethods from '../../../../../hooks/common/usePaymentMethods';
import TooltipWithCopyBtn from '../../../Common/TableCell/TooltipWihCopyBtn/TooltipWithCopyBtn';
import Routing from '../../../../../assets/icons/customs/Routing';
import TimelineSkeleton from './TimelineSkeleton';
import { orderTimelineQuery, activityTimelineQuery } from './query';

const ItemDot = ({ icon: Icon, color }) => (
  <div className={styles.dot} style={{ backgroundColor: color }}>
    <Icon className={styles.dotIcon} />
  </div>
);

const ItemContent = ({ date, title, info }) => (
  <div className={styles.itemContent}>
    <div className={styles.itemTitle}>{moment(date).format(DATETIME_FORMAT)}</div>
    <div className={styles.itemCard}>
      <div className={styles.itemCardTitle}>{title}</div>
      <div className={styles.itemCardInfo}>{info}</div>
    </div>
  </div>
);

const HeadItem = ({ index, title, text, info, color }) => (
  <div className={styles.block}>
    <div className={styles.blockDot} style={{ backgroundColor: color }}>
      <span>{index}</span>
    </div>
    <div className={styles.blockCard} style={{ borderColor: color }}>
      <div className={styles.blockCardTitle}>
        <TooltipWithCopyBtn value={title} trunc={false} />
      </div>
      <div className={styles.blockCardText}>{text}</div>
      <div className={styles.blockCardInfo}>{info}</div>
    </div>
  </div>
);

const StatusItem = ({ status, date, info }) => {
  if (!date || !status) return null;

  return (
    <Timeline.Item
      dot={<ItemDot icon={TRANSACTION_STATUSES[status].icon} color={TRANSACTION_STATUSES[status].color} />}
    >
      <ItemContent date={date} title={TRANSACTION_STATUSES[status].label} info={info} />
    </Timeline.Item>
  );
};

const RouteItem = ({ transaction, programManagerName }) => {
  if (!transaction.createdAt || !transaction.payin_partner) return null;
  return (
    <Timeline.Item dot={<ItemDot icon={Routing} color="black" />}>
      <ItemContent
        date={transaction.createdAt}
        title={`Routed to ${transaction.payin_partner}`}
        info={`By ${programManagerName}`}
      />
    </Timeline.Item>
  );
};

const OrderTimeline = () => {
  const { orderId } = useParams();

  const [fetchInsights, { data: { activity: orderInsights } = { activity: null } }] = useLazyQuery(
    activityTimelineQuery,
    {
      variables: {
        orderId,
      },
    },
  );

  const { data: { order: orderStack } = { order: null } } = useApolloQuery(orderTimelineQuery, {
    variables: {
      orderId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => !data.order && fetchInsights(),
  });

  const paymentMethods = usePaymentMethods();

  const order = orderStack ?? orderInsights;

  if (!order) return <TimelineSkeleton />;

  return (
    <div className={styles.root}>
      {[...(order?.transactions ?? [])]
        .sort(compareByProp('order_transaction_number', 'asc'))
        .map((trx, index) => (
          <div key={`transaction-${trx.id}`}>
            <HeadItem
              index={index + 1}
              title={trx.id}
              text={
                <Space>
                  <img
                    src={paymentMethods[trx.payment_method_name]?.imgUrl}
                    style={{ height: 23 }}
                    alt={`img-${trx.payment_method_name}`}
                  />
                  {trx.account_number}
                </Space>
              }
              info={trx.payin_partner}
              color={TRANSACTION_STATUSES[trx.status]?.color}
            />
            <Timeline className={styles.timeline}>
              {trx.status_history
                .slice(1) // skip status created
                .map((item, idx) => (
                  <StatusItem
                    key={`${item.status}_${idx + 1}`}
                    status={item.status}
                    date={item.date}
                    info={trx.payin_partner}
                  />
                ))
                .reverse()}
              <RouteItem transaction={trx} programManagerName={order.program_manager?.displayName} />
              <StatusItem status="created" date={trx.createdAt} info={trx.id} />
            </Timeline>
          </div>
        ))
        .reverse()}
    </div>
  );
};

export default OrderTimeline;
