import { useEffect, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import fieldList from 'norbr-shared-lib/constants/order/fields/list';
import useSearchFilters from '../../hooks/useSearchFilters';
import usePagination from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { TargetEntity } from '../../Common/constants/targetEntities';

export const matcherOrderListQuery = gql`
  query MatcherOrders($offset: Int, $limit: Int, $filters: [SearchFilter], $sort: [SearchSort]) {
    matcherOrders(offset: $offset, limit: $limit, filters: $filters, sort: $sort)
  }
`;

export const matcherOrdersCountQuery = gql`
  query MatcherOrdersCount($filters: [SearchFilter]) {
    matcherOrdersCount(filters: $filters)
  }
`;

const useMatcherOrderListQuery = () => {
  const { offset, limit } = usePagination();
  const { list } = useSearchFilters();
  const [sort] = useSort();

  const [fetch, { data, previousData, loading, error }] = useLazyQuery(matcherOrderListQuery, {
    variables: {
      offset,
      limit,
      filters: list,
      sort,
    },
  });

  const [fetchCount, { data: count, previousData: previousCount, loading: loadingCount, error: errorCount }] =
    useLazyQuery(matcherOrdersCountQuery, {
      variables: {
        filters: list,
      },
    });

  const result = useMemo(
    () =>
      ((loading ? previousData?.matcherOrders : data?.matcherOrders) ?? []).map(({ transactions, ...order }) => ({
        ...transactions?.[0],
        ...order,
        children: transactions?.map((transaction) => ({
          ...order,
          ...transaction,
          // Force transaction value for field with entity order but path to transactions
          ...fieldList
            .filter((field) => field.entity === TargetEntity.ORDER && field.path.indexOf('transactions.') === 0)
            .reduce(
              (memo, field) => ({
                ...memo,
                [field.key]: transaction[field.key],
              }),
              {},
            ),
          // Fields order with displayed values from transactions
          [Fields.ORDER_ID]: transaction[Fields.TRANSACTION_ID],
          [Fields.ORDER_CREATION_DATE]: transaction[Fields.TRANSACTION_CREATION_DATE],
          [Fields.ORDER_AMOUNT]: transaction[Fields.TRANSACTION_AMOUNT],
          [Fields.ORDER_IS_RECONCILED]: transaction[Fields.IS_RECONCILED],
          [Fields.ORDER_RECONCILED_AMOUNT]: transaction[Fields.RECONCILED_AMOUNT],
          [Fields.ORDER_UNRECONCILED_AMOUNT]: transaction[Fields.UNRECONCILED_AMOUNT],
          parent_id: order[Fields.ORDER_ID], // used to open drawer
        })),
      })),
    [data, loading, previousData],
  );

  const total = useMemo(
    () => (loadingCount ? previousCount?.matcherOrdersCount : count?.matcherOrdersCount) ?? 0,
    [count, loading, previousCount],
  );

  useEffect(() => {
    fetch();
    fetchCount();
  }, []);

  return { result, total, loading, error, loadingCount };
};

export default useMatcherOrderListQuery;
