import React from 'react';
import { Button, Form, Input, Modal, Result, Select } from 'antd';
import { useMutation, useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import RECONCIALITION_REASON_LIST from 'norbr-shared-lib/constants/transaction/reconciliationReasons/list';
import validationAnimation from '../../../../../assets/icons/used/000-validation-green.json';
import { reconcileMutation } from './query';

const ReconciliationModal = ({ reconciliationCandidate, onCancel, onOk }) => {
  const { transactionId } = useParams();

  const client = useApolloClient();
  const [reconcile, { called, error, loading }] = useMutation(reconcileMutation, { errorPolicy: 'all' });

  const [form] = Form.useForm();

  const submit = ({ reason, note }) => {
    reconcile({
      variables: {
        type: 'manual',
        transactionId,
        pspTransactionId: reconciliationCandidate?.psp_transaction_id,
        reason,
        note,
      },
    });
  };

  // Refetch activities to get the reconciled information
  const onFinish = async () => {
    await client
      .refetchQueries({
        include: ['MatcherOrders', 'ActivityMatcher'],
      })
      .then(() => onOk());
  };

  if (error) {
    return (
      <Modal destroyOnClose open onCancel={onCancel} footer={false}>
        <Result
          status="error"
          title="An error occurred"
          subTitle={error.message}
        />
      </Modal>
    );
  }

  if (called && !loading) {
    return (
      <Modal destroyOnClose open onCancel={onFinish} footer={false}>
        <Result
          icon={
            <Lottie
              loop={false}
              animationData={validationAnimation}
              play
              style={{ width: 150, height: 150, margin: 'auto' }}
            />
          }
          status="success"
          title="Matched"
          subTitle={
            <div>
              Transactions <b>{transactionId}</b> and <b>{reconciliationCandidate?.psp_transaction_id}</b> have been
              successfully matched.
              <br />
              <br />
              Please note that it might take a few minutes to be updated in the Merchant Console.
            </div>
          }
        />
      </Modal>
    );
  }

  return (
    <Modal
      title="Sync details"
      open={!!reconciliationCandidate}
      footer={
        <div style={{ display: 'flex' }}>
          <Button style={{ width: '100%' }} onClick={onCancel} disabled={loading}>
            CANCEL
          </Button>
          <Button type="primary" style={{ width: '100%' }} onClick={form.submit} loading={loading}>
            SAVE
          </Button>
        </div>
      }
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" onFinish={submit}>
        <Form.Item name="reason" label="Match reason">
          <Select options={RECONCIALITION_REASON_LIST.map(({ id, label }) => ({ value: id, label }))} />
        </Form.Item>
        <Form.Item name="note" label="Notes">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReconciliationModal;
