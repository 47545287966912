import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Space, Layout, Avatar } from 'antd';
import BellIcon from '@2fd/ant-design-icons/lib/Bell';
import SlashForwardIcon from '@2fd/ant-design-icons/lib/SlashForward';
import { useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import ForwardburgerIcon from '@2fd/ant-design-icons/lib/Forwardburger';
import BackburgerIcon from '@2fd/ant-design-icons/lib/Backburger';
import { useDebounce } from 'react-use';
import { useQuery } from '@apollo/client';
import { ApartmentOutlined } from '@ant-design/icons';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import { useLayoutMenu } from '../../../layout.context';
import { reorder } from '../../../../util/array';
import { Link } from '../../../../util/navigate';
import { formatEndUnderline } from '../Common/utils';
import useBrainpowerSettings from '../hooks/useBrainpowerSettings';
import TileBuilder from './DataTiles/TileBuilder';
import brainpowerStyles from '../Brainpower.module.scss';
import styles from './NotificationDetail.module.scss';
import { ModeInternal, ModeProgramManager } from '../../../../constants/mode';
import { notificationQuery } from './query';

const grid = 36;

const defaultLayout = ['notification-status', 'call-list', 'request-body', 'webhook'];

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
  ...(draggableStyle.transform
    ? {
        transform: `translate(0px${draggableStyle.transform.slice(
          draggableStyle.transform.indexOf(','),
          draggableStyle.transform.length,
        )}`,
      }
    : {}),
});

const NotificationDetail = () => {
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();

  const [settings, update] = useBrainpowerSettings('notificationDetail', {
    layout: defaultLayout,
  });

  const { notificationId } = useParams();

  const { data } = useQuery(notificationQuery, {
    variables: { notificationId },
    fetchPolicy: 'cache-and-network',
  });

  const [layout, setLayout] = useState([...new Set([...settings.layout, ...defaultLayout])]); // dedupe array union

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(layout, result.source.index, result.destination.index);
    setLayout(items);
  };

  useDebounce(() => layout !== settings.layout && update({ layout }), 400, [layout]);
  
  return (
    <Layout className={brainpowerStyles.root}>
      <PageHeader
        ghost={false}
        className={brainpowerStyles.pageHeader}
        backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
        onBack={toggleMenu}
        title={
          <Space>
            <BellIcon />
            <Link style={{ color: 'inherit' }} to="/brainpower/notifications">
              {formatEndUnderline('Notifications')}
            </Link>
            <SlashForwardIcon />
            <span style={{ fontSize: 16 }}>{notificationId}</span>
          </Space>
        }
        extra={[
          (ModeInternal || ModeProgramManager) && data?.notification?.company && (
            <Link to={`/companies/${data?.notification?.company.id}`}>
              <Avatar
                shape="circle"
                title={data?.notification?.company.name}
                src={data?.notification?.company.theme.icon}
                icon={<DomainIcon />}
                style={{ backgroundColor: data?.notification?.company.theme.secondaryColor }}
              />
            </Link>
          ),
          ModeInternal && data?.notification?.programManager && (
            <Link to={`/program-managers/${data.notification.programManager.id}`}>
              <Avatar
                shape="square"
                title={data.notification.programManager.name}
                src={data.notification.programManager.theme.icon}
                icon={<ApartmentOutlined />}
                style={{ backgroundColor: data.notification.programManager.theme.secondaryColor }}
              />
            </Link>
          ),
        ]}
      />
      <div className={styles.body}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {({ droppableProps, innerRef, placeholder }) => (
              <div {...droppableProps} ref={innerRef}>
                {layout.map((name, index) => (
                  <Draggable key={name} draggableId={name} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <TileBuilder key={name} name={name} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Layout>
  );
};

export default NotificationDetail;
