import React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Badge, Menu } from 'antd';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import BellIcon from '@2fd/ant-design-icons/lib/Bell';
import ClipboardTextIcon from '@2fd/ant-design-icons/lib/ClipboardText';
import CurrencyEurIcon from '@2fd/ant-design-icons/lib/CurrencyEur';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import PollIcon from '@2fd/ant-design-icons/lib/Poll';
import PuzzleIcon from '@2fd/ant-design-icons/lib/Puzzle';
import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import SourceBranchIcon from '@2fd/ant-design-icons/lib/SourceBranch';
import ViewListOutlineIcon from '@2fd/ant-design-icons/lib/ViewListOutline';
import SwapVerticalCircleIcon from '@2fd/ant-design-icons/lib/SwapVerticalCircle';
import CartIcon from '@2fd/ant-design-icons/lib/Cart';
import TableEyeIcon from '@2fd/ant-design-icons/lib/TableEye';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import { useCan } from '../../../contexts/ability.context';
import { useMe } from '../../../contexts/me.context';
import { useMessages } from '../../../contexts/messages/messages.context';
import useNavigateWithSearch from '../../../util/navigate';
import { useLayoutMenu } from '../../layout.context';
import styles from '../Menu.module.scss';

const MerchantMenu = () => {
  const me = useMe();
  const { messagesByType } = useMessages();
  const can = useCan();
  const location = useLocation();
  const navigate = useNavigateWithSearch();
  const [isMenuCollapsed] = useLayoutMenu();

  const handleClick = ({ key, domEvent }) => {
    if (domEvent.ctrlKey || domEvent.metaKey) {
      window.open(key + location.search);
    } else {
      navigate(key);
    }
  };

  const [, module, page] = location.pathname.split('/');

  const items = [
    can('read', 'brainpower') && {
      className: styles.itemGroup,
      label: 'Brainpower',
      type: 'group',
      children: [
        {
          key: '/brainpower/dashboard',
          icon: (
            <Badge
              size="small"
              count={messagesByType('dashboard_shared').length}
              overflowCount={9}
              className={styles.exportBadge}
              style={{
                top: 20,
                right: 12,
              }}
            >
              <PollIcon className={classnames(styles.exportIcon, 'ant-menu-item-icon')} />
            </Badge>
          ),
          label: 'Dashboard',
        },
        can('read', 'brainpower') && {
          key: '/brainpower/overview',
          icon: <TableEyeIcon />,
          label: 'Overview',
        },
        can('read', 'checkout') && {
          key: '/brainpower/checkouts',
          icon: <CartIcon />,
          label: 'Checkouts',
        },
        can('read', 'order') && {
          key: '/brainpower/orders',
          icon: <ShoppingIcon />,
          label: 'Orders',
        },
        can('read', 'order') && {
          key: '/brainpower/transactions',
          icon: <BookmarkIcon />,
          label: 'Transactions',
        },
        can('read', 'matcher') && {
          key: '/brainpower/matcher',
          icon: <SwapVerticalCircleIcon />,
          label: 'Matcher',
          children: [
            {
              label: 'Cashier',
              key: '/brainpower/matcher-cashier-summary',
            },
            {
              label: 'Payment partner',
              key: '/brainpower/matcher-payment-partner',
            },
            {
              label: 'Matcher engine',
              key: '/brainpower/matcher-engine',
            },
          ]
        },
        can('read', 'financial-operation') && {
          key: '/brainpower/financial-operations',
          icon: <CurrencyEurIcon />,
          label: 'Finance',
        },
        can('read', 'notification') && {
          key: '/brainpower/notifications',
          icon: <BellIcon />,
          label: 'Notifications',
        },
        can('read', 'report') && {
          key: '/brainpower/exports',
          icon: (
            <Badge
              size="small"
              count={messagesByType('report_completed').length}
              overflowCount={9}
              className={styles.exportBadge}
              style={{
                top: 20,
                right: 12,
              }}
            >
              <InboxArrowDownIcon className={classnames(styles.exportIcon, 'ant-menu-item-icon')} />
            </Badge>
          ),
          label: 'Exports',
          id: 'export',
        },
      ],
    },
    (can('read', 'api_notifications') ||
      can('read', 'payment_networks') ||
      can('read', 'fallback-settings') ||
      can('read', 'list')) && {
      className: styles.itemGroup,
      label: 'Integration',
      type: 'group',
      children: [
        can('read', 'api_notifications') && {
          key: '/router/notifications',
          icon: <BellIcon />,
          label: 'API & notifications',
        },
        can('read', 'payment_networks') && {
          key: '/router/payment-network',
          icon: <PuzzleIcon />,
          label: 'Payment network',
        },
        can('read', 'fallback-settings') && {
          key: '/router/optimizer',
          icon: <SourceBranchIcon />,
          label: 'Optimizer',
        },
        can('read', 'list') && {
          key: '/router/lists',
          icon: <ClipboardTextIcon />,
          label: 'Lists',
        },
      ],
    },
    (can('read', 'user') || can('read', 'company-login')) && {
      type: 'group',
      label: 'Administration',
      children: [
        (can('read', 'user') || can('read', 'company-login')) && {
          key: '/teams/users',
          icon: <AccountMultipleIcon />,
          label: 'Teams',
          children: [
            ...(can('read', 'user') ? [{
              label: 'Teams',
              key: '/teams/users',
            }] : []),
            ...(can('read', 'company-login') ? [{
              label: 'SSO',
              key: '/sso',
            }] : []),
          ]
        },
        can('read', 'log') && {
          key: '/logs',
          icon: <ViewListOutlineIcon />,
          label: 'Logs',
        },
      ],
    },
  ];

  return [
    !isMenuCollapsed && me?.companies?.[0]?.company.theme?.logo && (
      <img
        className={styles.companyLogo}
        key="company-logo"
        alt="Company logo"
        src={me?.companies?.[0]?.company.theme?.logo}
      />
    ),
    !isMenuCollapsed && me?.companies?.[0]?.company.theme?.banner && (
      <img
        className={styles.companyLogo}
        key="company-banner"
        alt="Company banner"
        src={me?.companies?.[0]?.company.theme?.banner}
      />
    ),
    isMenuCollapsed && me?.companies?.[0]?.company.theme?.icon && (
      <img
        className={styles.companyIcon}
        key="company-icon"
        alt="Company icon"
        src={me?.companies?.[0]?.company.theme?.icon}
      />
    ),
    <Menu
      key="merchant-menu"
      theme="dark"
      mode="inline"
      selectedKeys={[`/${module}`, `/${module}/${page}`]}
      onClick={handleClick}
      className={styles.menu}
      items={items}
    />,
  ];
};

export default MerchantMenu;
