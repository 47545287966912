import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import { useCan } from '../../../../../contexts/ability.context';
import Column from '../../../Common/NavigationContainer/Column/Column';
import SelectElem from '../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import ImgUploaderElem from '../../../Common/NavigationContainer/Column/ImgUploaderElem/ImgUploaderElem';
import { useMerchantAccount } from '../../merchantAccount.context';
import {
  merchantAccountHostedPageConfigurationLogoUpload,
  merchantAccountHostedPageConfigurationMutation,
  merchantAccountHostedPageConfigurationQuery,
} from './query';
import ColorPickerElem from '../../../Common/NavigationContainer/Column/ColorPickerElem/ColorPickerElem';

const HostedPageSettings = ({ ...props }) => {
  const { selectedMerchantAccount } = useMerchantAccount();
  const can = useCan();

  const query = useQuery(merchantAccountHostedPageConfigurationQuery, {
    variables: { id: selectedMerchantAccount },
  });

  const [update] = useMutation(merchantAccountHostedPageConfigurationMutation);

  const hostedPageConfiguration = query.data?.merchantAccount?.hostedPageConfiguration || {};
  // default color to company or programManager primaryColor
  const defaultColor =
    query.data?.merchantAccount.company.theme.primaryColor ??
    query.data?.merchantAccount.programManager.theme.primaryColor ??
    '#00a8a8';

  const updateHostedPageConfiguration = (input) =>
    update({
      variables: {
        id: selectedMerchantAccount,
        hostedPageConfigurationInput: input,
      },
      optimisticResponse: {
        updateMerchantAccountHostedPageConfiguration: {
          id: selectedMerchantAccount,
          __typename: 'MerchantAccount',
          hostedPageConfiguration: {
            ...hostedPageConfiguration,
            ...input,
          },
        },
      },
    });

  const hostedFields = hostedPageConfiguration.hosted_fields ?? {};

  const updateHostedFields = (input) =>
    update({
      variables: {
        id: selectedMerchantAccount,
        hostedFieldsInput: input,
      },
      optimisticResponse: {
        updateMerchantAccountHostedPageConfiguration: {
          id: selectedMerchantAccount,
          __typename: 'MerchantAccount',
          hostedPageConfiguration: {
            ...hostedPageConfiguration,
            hosted_fields: {
              ...hostedFields,
              ...input,
            },
          },
        },
      },
    });

  const [uploadLogo] = useMutation(merchantAccountHostedPageConfigurationLogoUpload, {
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('An error occurred, please try again later.');
    },
  });

  const handleUploadIcon = (file) => {
    uploadLogo({ variables: { id: selectedMerchantAccount, file } });
  };

  const locked = !can('update', 'hosted-page-settings');

  return (
    <Column id="hostedPageSettings" {...props}>
      <SelectElem
        id="status"
        title="Status"
        options={[
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
        ]}
        value={hostedPageConfiguration.status ?? 'inactive'}
        handleChange={(value) => {
          if (value !== hostedPageConfiguration.status) {
            updateHostedPageConfiguration({ status: value });
          }
        }}
        allowClear={false}
        locked={locked}
      />
      <ImgUploaderElem
        id="logo"
        title="Logo"
        info="Add logo to the hosted page"
        value={hostedPageConfiguration.logo}
        handleChange={handleUploadIcon}
        locked={locked}
      />
      <ColorPickerElem
        id="primary_color"
        title="Primary color"
        value={hostedPageConfiguration.primary_color ?? defaultColor}
        handleChange={(value) => {
          if (value !== hostedPageConfiguration.primary_color) {
            updateHostedPageConfiguration({ primary_color: value });
          }
        }}
        locked={locked}
      />
      <SelectElem
        id="show_pci_logo"
        title="Show PCI logo"
        options={[
          { id: 'true', name: 'True' },
          { id: 'false', name: 'False' },
        ]}
        value={hostedPageConfiguration.show_pci_logo ? 'true' : 'false'}
        handleChange={(value) => {
          if ((value === 'true') !== hostedPageConfiguration.show_pci_logo) {
            updateHostedPageConfiguration({ show_pci_logo: value === 'true' });
          }
        }}
        defaultValue="true"
        allowClear={false}
        locked={locked}
      />
      <SelectElem
        id="display_buttons"
        title="Display pay button by payment type"
        options={[
          { id: 'true', name: 'True' },
          { id: 'false', name: 'False' },
        ]}
        value={hostedFields.display_buttons ? 'true' : 'false'}
        handleChange={(value) => {
          if ((value === 'true') !== hostedFields.display_buttons) {
            updateHostedFields({ display_buttons: value === 'true' });
          }
        }}
        defaultValue="true"
        allowClear={false}
        locked={locked}
      />
      <SelectElem
        id="display_cardholder"
        title="Display cardholder field"
        options={[
          { id: 'true', name: 'True' },
          { id: 'false', name: 'False' },
        ]}
        value={hostedFields.display_cardholder ? 'true' : 'false'}
        handleChange={(value) => {
          if ((value === 'true') !== hostedFields.display_cardholder) {
            updateHostedFields({ display_cardholder: value === 'true' });
          }
        }}
        defaultValue="true"
        allowClear={false}
        locked={locked}
      />
      <SelectElem
        id="display_save"
        title="Display save card option"
        options={[
          { id: 'true', name: 'True' },
          { id: 'false', name: 'False' },
        ]}
        value={hostedFields.display_save ? 'true' : 'false'}
        handleChange={(value) => {
          if ((value === 'true') !== hostedFields.display_save) {
            updateHostedFields({ display_save: value === 'true' });
          }
        }}
        defaultValue="false"
        allowClear={false}
        locked={locked}
      />
    </Column>
  );
};

export default HostedPageSettings;
