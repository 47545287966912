import React from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Menu } from 'antd';
import {
  ApartmentOutlined,
  DeploymentUnitOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ShoppingIcon from '@2fd/ant-design-icons/lib/Shopping';
import PollIcon from '@2fd/ant-design-icons/lib/Poll';
import CurrencyEurIcon from '@2fd/ant-design-icons/lib/CurrencyEur';
import BellIcon from '@2fd/ant-design-icons/lib/Bell';
import InboxArrowDownIcon from '@2fd/ant-design-icons/lib/InboxArrowDown';
import classnames from 'classnames';
import CartIcon from '@2fd/ant-design-icons/lib/Cart';
import TableEyeIcon from '@2fd/ant-design-icons/lib/TableEye';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import SwapVerticalCircleIcon from '@2fd/ant-design-icons/lib/SwapVerticalCircle';
import ViewListOutlineIcon from '@2fd/ant-design-icons/lib/ViewListOutline';
import ViewCarouselIcon from '@2fd/ant-design-icons/lib/ViewCarousel';
import DomainIcon from '@2fd/ant-design-icons/lib/Domain';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import { useCan } from '../../../contexts/ability.context';
import { useProgramManager } from '../../../contexts/programManager.context';
import useNavigateWithSearch from '../../../util/navigate';
import styles from '../Menu.module.scss';
import { useMessages } from '../../../contexts/messages/messages.context';

const AdminMenu = () => {
  const can = useCan();
  const { messagesByType } = useMessages();
  const navigate = useNavigateWithSearch();
  const location = useLocation();
  const programManager = useProgramManager();

  const handleClick = ({ key, domEvent }) => {
    if (domEvent.ctrlKey || domEvent.metaKey) {
      window.open(key);
    } else {
      navigate(key);
    }
  };

  const [, module] = location.pathname.split('/');

  return (
    <Menu
      mode="inline"
      theme="dark"
      selectedKeys={[`/${module}`]}
      onClick={handleClick}
      className={styles.menu}
      items={[
        {
          type: 'group',
          label: 'Brainpower',
          children: [
            {
              key: '/brainpower/dashboard',
              icon: <PollIcon />,
              label: 'Dashboard',
            },
            can('read', 'brainpower') && {
              key: '/brainpower/overview',
              icon: <TableEyeIcon />,
              label: 'Overview',
            },
            can('read', 'checkout') && {
              key: '/brainpower/checkouts',
              icon: <CartIcon />,
              label: 'Checkouts',
            },
            can('read', 'order') && {
              key: '/brainpower/orders',
              icon: <ShoppingIcon />,
              label: 'Orders',
            },
            can('read', 'order') && {
              key: '/brainpower/transactions',
              icon: <BookmarkIcon />,
              label: 'Transactions',
            },
            can('read', 'matcher') && {
              key: '/brainpower/matcher',
              icon: <SwapVerticalCircleIcon />,
              label: 'Matcher',
              children: [
                {
                  label: 'Cashier',
                  key: '/brainpower/matcher-cashier-summary',
                },
                {
                  label: 'Payment partner',
                  key: '/brainpower/matcher-payment-partner',
                },
                {
                  label: 'Matcher engine',
                  key: '/brainpower/matcher-engine',
                },
              ]
            },
            can('read', 'financial-operation') && {
              key: '/brainpower/financial-operations',
              icon: <CurrencyEurIcon />,
              label: 'Finance',
            },
            can('read', 'notification') && {
              key: '/brainpower/notifications',
              icon: <BellIcon />,
              label: 'Notifications',
            },
            can('read', 'report') && {
              key: '/brainpower/exports',
              icon: (
                <Badge
                  size="small"
                  count={messagesByType('report_completed').length}
                  overflowCount={9}
                  className={styles.exportBadge}
                  style={{
                    top: 20,
                    right: 12,
                  }}
                >
                  <InboxArrowDownIcon className={classnames(styles.exportIcon, 'ant-menu-item-icon')} />
                </Badge>
              ),
              label: 'Exports',
              id: 'export',
            },
          ],
        },
        ...(can('read', 'company') ||
        can('read', 'merchant_account') ||
        can('read', 'user') ||
        can('update', 'access-control') ||
        can('read', 'log')
          ? [
              {
                type: 'group',
                label: 'Merchants',
                children: [
                  ...(can('read', 'company') ? [{ key: 'companies', icon: <DomainIcon />, label: 'Companies' }] : []),
                  ...(can('read', 'merchant_account')
                    ? [{ key: 'merchant-accounts', icon: <ViewCarouselIcon />, label: 'Accounts' }]
                    : []),
                  ...(can('read', 'user') ? [{ key: 'users', icon: <AccountMultipleIcon />, label: 'Users' }] : []),
                  ...(can('update', 'access-control')
                    ? [{ key: 'access-control-list', icon: <SafetyCertificateOutlined />, label: 'Access rights' }]
                    : []),
                  ...(can('read', 'log') ? [{ key: '/logs', icon: <ViewListOutlineIcon />, label: 'Logs' }] : []),
                ],
              },
            ]
          : []),
        ...(!programManager && can('read', 'partner')
          ? [
              {
                type: 'group',
                label: 'Partners',
                children: [
                  { key: 'partners', icon: <DeploymentUnitOutlined />, label: 'Partners' },
                  ...(can('read', 'partner-route')
                    ? [{ key: 'partner-routes', icon: <NodeIndexOutlined />, label: 'Routes' }]
                    : []),
                ],
              },
            ]
          : []),
        ...(!programManager && can('read', 'program-manager')
          ? [
              {
                type: 'group',
                label: 'Program Managers',
                children: [
                  ...(can('read', 'program-manager')
                    ? [{ key: 'program-managers', icon: <ApartmentOutlined />, label: 'Program Managers' }]
                    : []),
                  ...(can('read', 'program-manager-user')
                    ? [{ key: 'program-users', icon: <AccountMultipleIcon />, label: 'PM users' }]
                    : []),
                  ...(can('read', 'program-manager-access-control')
                    ? [{ key: 'program-access-control-list', icon: <SafetyCertificateOutlined />, label: 'PM rights' }]
                    : []),
                ],
              },
            ]
          : []),
        ...(!programManager
          ? [
              {
                type: 'group',
                label: 'NORBr',
                children: [
                  ...(can('read', 'internal_user')
                    ? [{ key: 'internal-users', icon: <AccountMultipleIcon />, label: 'Internal users' }]
                    : []),
                  ...(can('update', 'internal-access-control')
                    ? [
                        {
                          key: 'internal-access-control-list',
                          icon: <SafetyCertificateOutlined />,
                          label: 'Internal rights',
                        },
                      ]
                    : []),
                  ...(can('read', 'announcement')
                    ? [{ key: 'announcements', icon: <NotificationOutlined />, label: 'Announcements' }]
                    : []),
                ],
              },
            ]
          : []),
        ...(programManager
          ? [
              {
                type: 'group',
                label: programManager.displayName,
                children: [
                  ...(can('read', 'program-manager')
                    ? [{ key: 'program-settings', icon: <ApartmentOutlined />, label: 'Settings' }]
                    : []),
                  ...(can('read', 'program-manager-active-partner')
                    ? [{ key: 'program-partners', icon: <DeploymentUnitOutlined />, label: 'Partners' }]
                    : []),
                  ...(can('read', 'program-manager-user')
                    ? [{ key: 'program-users', icon: <UserOutlined />, label: 'Internal users' }]
                    : []),
                  ...(can('read', 'program-manager-access-control')
                    ? [
                        {
                          key: 'program-access-overview',
                          icon: <SafetyCertificateOutlined />,
                          label: 'Internal rights',
                        },
                      ]
                    : []),
                  ...(can('read', 'announcement')
                    ? [{ key: 'announcements', icon: <NotificationOutlined />, label: 'Announcements' }]
                    : []),
                ],
              },
            ]
          : []),
      ]}
    />
  );
};

export default AdminMenu;
