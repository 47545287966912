import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Switch, Tooltip, message } from 'antd';
import { MerchantContractContext } from '../../../MerchantContractDrawer';
import { useMerchantAccount } from '../../../../../../merchantAccount.context';
import { ContractEditionContext } from '../../../../../PaymentNetwork';
import styles from './MerchantContractStatus.module.scss';
import HandleRoutesModal from '../../../HandleRoutesModal/HandleRoutesModal';
import { getValueByPath } from '../../../../../../../../../util/object';

const { Item } = Form;

const MerchantContractStatus = () => {
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { merchantAccount } = useMerchantAccount();

  const { isEdited } = useContext(ContractEditionContext);

  const [pmIssues, setPmIssues] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const updateContractStatus = useCallback(
    (force = false) =>
      updateMerchantContract({
        variables: {
          input: {
            status: 'inactive',
          },
          force,
        },
      }),
    [merchantContract],
  );

  const invalidConfigurationKeys = useMemo(
    () =>
      Object.keys(merchantContract?.partner?.partner_data_configuration || {}).filter(
        (config) =>
          // a configuration key is invalid if :
          // required relatively to contract source
          merchantContract.partner.partner_data_configuration?.[config]?.sources?.[merchantContract.source] ===
            'required' &&
          // & not specific to paymentMethods (not considered as required)
          !merchantContract.partner.partner_data_configuration?.[config]?.paymentMethods?.length &&
          // & required for payfac and payfac or required for merchant and merchant (not payfac)
          ((merchantAccount.programManager.payfac &&
            merchantContract.partner.partner_data_configuration?.[config]?.payfac) ||
            (!merchantAccount.programManager.payfac &&
              merchantContract.partner.partner_data_configuration?.[config]?.merchant)) &&
          // & value is not defined or empty string
          (!merchantContract.configuration?.[config] || merchantContract.configuration?.[config]?.length === 0),
      ),
    [merchantContract],
  );

  const invalidMerchantAttributeConfigurationKeys = useMemo(() => {
    if (!merchantAccount.programManager.payfac) return [];
    return Object.keys(merchantContract?.partner?.merchant_attribute_configuration || {}).filter((key) => {
      const config = merchantContract.partner.merchant_attribute_configuration?.[key];
      if (!config) return false;
      if (config.sources?.[merchantContract.source] !== 'required') return false;
      switch (config.attribute) {
        case 'partner_id_string':
        case 'partner_id_generated':
          return !(merchantAccount.partnerData?.[merchantContract.partner.api_name]?.[key]?.length > 0);
        default: // attributes (ex : company.mainContact.email)
          // eslint-disable-next-line no-case-declarations
          const [entity, ...path] = config.attribute.split('.');
          if (entity === 'company') return !(getValueByPath(merchantAccount.company, path.join('.'))?.length > 0);
          if (entity === 'merchantAccount') return !(getValueByPath(merchantAccount, path.join('.'))?.length > 0);
          return false;
      }
    });
  }, [merchantAccount, merchantContract]);

  const messages = [
    ...(invalidConfigurationKeys.length > 0
      ? [
          <div>
            Please fill the required connection settings before activating the contract (
            {invalidConfigurationKeys.join(', ')}).
          </div>,
        ]
      : []),
    ...(invalidMerchantAttributeConfigurationKeys.length > 0
      ? [
          <div>
            Please fill the required company and merchant account configuration before activating the contract (
            {invalidMerchantAttributeConfigurationKeys.join(', ')}).
          </div>,
        ]
      : []),
    ...(merchantContract?.type === 'cashier' && !merchantContract.payin_contracts?.length
      ? [<div>Please select a payment contract before activating the contract.</div>]
      : []),
    ...(merchantContract?.type !== 'cashier' &&
    (!merchantContract?.settlement_currency || !merchantContract?.channels?.length)
      ? [<div>Please select a settlement currency and sales channels before activating the contract.</div>]
      : []),
  ].filter((m) => m);

  return (
    <Tooltip title={merchantContract?.status !== 'active' && messages.length > 0 && <div>{messages}</div>}>
      <Item label="Status">
        <Switch
          className={styles.switch}
          disabled={
            !isEdited ||
            !merchantContract ||
            (merchantContract?.status !== 'active' &&
              (invalidConfigurationKeys.length > 0 ||
                invalidMerchantAttributeConfigurationKeys.length > 0 ||
                (merchantContract?.type !== 'cashier'
                  ? !merchantContract.settlement_currency || !merchantContract.channels?.length
                  : !merchantContract.payin_contracts?.length)))
          }
          checked={merchantContract?.status === 'active'}
          onChange={(value) => {
            setIsLoading(true);
            updateMerchantContract({
              variables: {
                input: {
                  status: value ? 'active' : 'inactive',
                },
              },
            })
              .catch((e) => {
                const error = e.graphQLErrors.find((ee) => ee.extensions.code === '409');
                if (error.extensions.type === 'cashier') {
                  message.error(
                    `This contract is used by cashier contract${error?.extensions?.issues.length > 1 ? 's' : ''}: ${error?.extensions?.issues.map((issue) => (issue.name ? issue.name : issue.id)).join(', ')}`,
                  );
                } else {
                  setPmIssues(error?.extensions?.issues);
                }
              })
              .then(() => setIsLoading(false));
          }}
          loading={isLoading}
        />
        {merchantContract?.status === 'active' ? 'Active' : 'Inactive'}
        <HandleRoutesModal
          issues={pmIssues}
          onCancel={() => setPmIssues(null)}
          onConfirm={async () => {
            await updateContractStatus(true);
            setPmIssues(null);
          }}
        />
      </Item>
    </Tooltip>
  );
};

export default MerchantContractStatus;
