import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { Avatar, Descriptions, Divider, Space, Tag, Typography } from 'antd';
import CheckCircleIcon from '@2fd/ant-design-icons/lib/CheckCircle';
import RECONCILIATION_TYPES from 'norbr-shared-lib/constants/transaction/reconciliationTypes';
import RECONCILIATION_REASONS from 'norbr-shared-lib/constants/transaction/reconciliationReasons';
import WITHDRAW_REASONS from 'norbr-shared-lib/constants/transaction/withdrawReasons';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import usePaymentMethods from '../../../../../hooks/common/usePaymentMethods';
import { DATETIME_FORMAT } from '../../../../../constants/DATES';
import { amountFormatter } from '../../../../../util/formatter';
import { initials } from '../../../../../util/string';
import AmountFormats from '../../../../../constants/amountFormats';
import fields from '../../Common/constants/fields';
import styles from './MatcherDrawer.module.scss';
import useFieldAsyncOption from '../../hooks/useFieldAsyncOption';
import MATCH_LATER_REASONS from 'norbr-shared-lib/constants/transaction/matchLaterReasons';
import {useCan} from '../../../../../contexts/ability.context';

const MatcherOrderDescription = ({ order }) => {
  const { transactionId } = useParams();
  const can = useCan();

  const transaction = order?.transactions.find((trx) => trx.id === transactionId);

  const paymentMethods = usePaymentMethods();
  const cashierPartner = useFieldAsyncOption(fields[Fields.CASHIER_PARTNER], transaction?.cashier_partner_api_name);

  if (!order) return null;

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 20,
        backgroundColor: 'white',
        boxShadow: '0 1px 4px 0 rgba(23, 23, 23, 0.35)',
        padding: '8px 12px',
        margin: '0 8px',
        borderRadius: 6,
      }}
    >
      {order.is_reconciled && (
        <div style={{ textAlign: 'center', marginBottom: 12 }}>
          <Tag className={styles.tagCell} icon={<CheckCircleIcon />}>
            <div style={{ backgroundColor: '#00cc7e' }} />
            <span>Order matched</span>
          </Tag>
        </div>
      )}
      <Descriptions
        column={2}
        size="small"
        colon={false}
        labelStyle={{ color: 'lightgrey' }}
        className={styles.descriptions}
      >
        <Descriptions.Item label="Order">
          <Typography.Text ellipsis copyable>
            {order.order_id}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Date">{moment(order.createdAt).format(DATETIME_FORMAT)}</Descriptions.Item>
        <Descriptions.Item label="Cashier">
          {cashierPartner.option ? (
            <Space>
              <Avatar
                src={cashierPartner.option.img}
                shape="square"
                size={28}
                title={cashierPartner.option.label}
                style={{ backgroundColor: cashierPartner.option.color }}
                icon={<DeploymentUnitOutlined />}
              />
              {cashierPartner.option.label}
            </Space>
          ) : (
            <Typography.Text ellipsis>{transaction.cashier_partner_api_name ?? 'n/a'}</Typography.Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="File name">
          <Typography.Text
            ellipsis
            style={{
              width: 300,
            }}
            copyable
          >
            {transaction.pos_file_name ?? 'n/a'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Store">
          {/*To change for real store name later*/}
          <Typography.Text ellipsis>{order.merchant_account.name ?? 'n/a'}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Receipt #">
          <Typography.Text ellipsis copyable>
            {order.order_merchant_id ?? 'n/a'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Order amount">
          {amountFormatter(order.amount, order.currency, AmountFormats.FR_CURRENCY)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Unmatched amount"
          contentStyle={{ color: order.unreconciled_amount === 0 ? '#00cc7e' : '#fa9502' }}
        >
          {amountFormatter(order.unreconciled_amount, order.currency, AmountFormats.FR_CURRENCY)}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions
        column={2}
        size="small"
        colon={false}
        labelStyle={{ color: 'lightgrey' }}
        className={styles.descriptions}
      >
        <Descriptions.Item label="Transaction">
          <Typography.Text ellipsis copyable>
            {transaction.id}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="PSP transaction ID">
          <Typography.Text ellipsis copyable>
            {transaction.psp_transaction_id}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Terminal ID">
          <Typography.Text ellipsis copyable>
            {transaction.merchant_terminal_id ?? 'n/a'}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Date">{moment(transaction.createdAt).format(DATETIME_FORMAT)}</Descriptions.Item>
        <Descriptions.Item label="Payment method">
          <Space>
            <img
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                height: 24,
              }}
              alt=""
              src={paymentMethods[transaction.payment_method_name]?.imgUrl}
            />
            <Typography.Text ellipsis>{paymentMethods[transaction.payment_method_name]?.name}</Typography.Text>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          {amountFormatter(transaction.amount, order.currency, AmountFormats.FR_CURRENCY)}
        </Descriptions.Item>
      </Descriptions>
      {transaction.is_reconciled && [
        <Divider key="reconciliation-divider" />,
        <Descriptions
          key="reconciliation-informations"
          column={2}
          size="small"
          colon={false}
          labelStyle={{ color: 'lightgrey' }}
          className={styles.descriptions}
        >
          <Descriptions.Item label="Match type">
            {RECONCILIATION_TYPES[transaction.reconciliationType].label}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {moment(transaction.reconciliationDate).format(DATETIME_FORMAT)}
          </Descriptions.Item>
          {transaction.reconciliationReason && (
            <Descriptions.Item label="Reason">
              {{
                ...RECONCILIATION_REASONS,
                ...WITHDRAW_REASONS,
              }[transaction.reconciliationReason].label}
            </Descriptions.Item>
          )}
          {can('read', 'matcher-withdraw') && transaction.withdrawReason && (
            <Descriptions.Item label="Withdraw reason">
              {{
                ...RECONCILIATION_REASONS,
                ...WITHDRAW_REASONS,
              }[transaction.withdrawReason].label}
            </Descriptions.Item>
          )}
          {transaction.reconciliationAuthor && (
            <Descriptions.Item label="Author">
              <Space>
                <Avatar
                  src={transaction.reconciliationAuthor.avatar}
                  size="small"
                  title={transaction.reconciliationAuthor.full_name}
                >
                  {initials(transaction.reconciliationAuthor.full_name)}
                </Avatar>
                {transaction.reconciliationAuthor.full_name}
              </Space>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Notes">
            {transaction.reconciliationNote}
          </Descriptions.Item>
        </Descriptions>,
      ]}
      {transaction.reconciliationType === 'pending' && [
        <Divider key="reconciliation-divider" />,
        <Descriptions
          key="reconciliation-informations"
          column={2}
          size="small"
          colon={false}
          labelStyle={{ color: 'lightgrey' }}
          className={styles.descriptions}
        >
          <Descriptions.Item label="Match type">
            Pending
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {moment(transaction.reconciliationDate).format(DATETIME_FORMAT)}
          </Descriptions.Item>
          {transaction.reconciliationReason && (
            <Descriptions.Item label="Reason">
              {MATCH_LATER_REASONS[transaction.reconciliationReason].label}
            </Descriptions.Item>
          )}
          {transaction.reconciliationAuthor && (
            <Descriptions.Item label="Author">
              <Space>
                <Avatar
                  src={transaction.reconciliationAuthor.avatar}
                  size="small"
                  title={transaction.reconciliationAuthor.full_name}
                >
                  {initials(transaction.reconciliationAuthor.full_name)}
                </Avatar>
                {transaction.reconciliationAuthor.full_name}
              </Space>
            </Descriptions.Item>
          )}
          {(!transaction.is_withdraw || can('read', 'matcher-withdraw')) && (
              <Descriptions.Item label="Notes">
                {transaction.reconciliationNote}
              </Descriptions.Item>
          )}
        </Descriptions>,
      ]}
    </div>
  );
};

export default MatcherOrderDescription;
