import React, { useState } from 'react';
import { Avatar, Button, Drawer, Form, Input, message, Select, Table, Typography } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBoundary from '../../../../Common/ErrorBoundary/ErrorBoundary';
import { Block } from '../../../../Common/Sider';
import { useDashboards } from '../../dashboard.context';
import { useCan } from '../../../../../../contexts/ability.context';
import { initials } from '../../../../../../util/string';
import styles from '../SetUpDrawer.module.scss';
import { useMe } from '../../../../../../contexts/me.context';
import { teamUsersQuery } from './query';

const shareDashboardToUsersMutation = gql`
  mutation ShareDashboardToUsersMutation($dashboard: DashboardInput!, $users: [ID!]!) {
    shareDashboardToUsers(dashboard: $dashboard, users: $users)
  }
`;

const DashboardShareContent = ({ dashboard, onClose }) => {
  const me = useMe();
  const can = useCan();

  const { data, loading } = useQuery(teamUsersQuery);

  const {
    companyDashboards,
    addCompanyDashboard,
    updateCompanyDashboard,
    setSelectedDashboardId,
    refetchCompanyDashboards,
  } = useDashboards();

  const companyDashboardSharedByUser = companyDashboards?.filter((d) => d.createdBy?.id === me.id);

  const [form] = Form.useForm();
  const dashboardId = Form.useWatch('dashboard', form);
  const labelValue = Form.useWatch('label', form);

  const handleFinish = () => {
    // update existing saved view
    if (dashboardId) {
      updateCompanyDashboard({
        id: dashboardId,
        layout: dashboard.layout,
        widgets: dashboard.widgets,
      }).then(() => {
        message.success(`${dashboard.name} successfully updated`);
        setSelectedDashboardId(dashboardId);
        onClose();
      });
    }
    // save new view
    else {
      addCompanyDashboard({
        ...dashboard,
        name: labelValue,
      }).then((res) => {
        message.success(`${dashboard.name} successfully shared`);
        const newDashboard = res?.data.addCompanyDashboard.dashboards.pop();
        refetchCompanyDashboards().then(() => setSelectedDashboardId(newDashboard.id));
        onClose();
      });
    }
  };

  const [shareDashboardToUsers] = useMutation(shareDashboardToUsersMutation);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleClickShareToUsers = () => {
    shareDashboardToUsers({
      variables: {
        dashboard: {
          name: dashboard.name,
          layout: dashboard.layout,
          widgets: dashboard.widgets,
        },
        users: selectedUsers,
      },
    }).then(() => {
      message.success(`${dashboard.name} successfully shared`);
      onClose();
    });
  };

  return (
    <ErrorBoundary>
      {can('create', 'company-dashboard') && (
        <Block
          key="add-company-dashboard"
          title="Share as company's dashboard"
          description="The dashboard will be available to all company's users."
        >
          <Form
            key="add-company-dashboard-form"
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={dashboard}
            onFinish={handleFinish}
          >
            {companyDashboardSharedByUser.length ? (
              <Form.Item name="dashboard" label="Update a company dashboard">
                <Select
                  disabled={!!labelValue}
                  options={companyDashboardSharedByUser.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                  onChange={(value) =>
                    form.setFieldsValue({
                      dashboard: value,
                      label: '',
                    })
                  }
                  onClear={() => form.setFieldValue('dashboard', null)}
                  allowClear
                />
              </Form.Item>
            ) : null}
            <Form.Item name="label" label="New shared dashboard">
              <Input disabled={!!dashboardId} />
            </Form.Item>
            <Form.Item shouldUpdate style={{ marginTop: 40 }}>
              {() => (
                <Button block type="primary" htmlType="submit" disabled={!labelValue && !dashboardId}>
                  SAVE
                </Button>
              )}
            </Form.Item>
          </Form>
        </Block>
      )}
      {can('share', 'dashboard') && (
        <Block
          key="share-dashboard"
          title="Share to the team"
          description="A copy of the dashboard will appear on selected users' dashboards."
        >
          <Typography.Text className={styles.description}>
            {selectedUsers.length > 0 ? `Selected ${selectedUsers.length} users.` : 'Select users to share.'}
          </Typography.Text>
          <Table
            loading={loading}
            dataSource={data?.teamUsers}
            columns={[
              {
                dataIndex: 'avatar',
                width: 32,
                render: (avatar, user) => <Avatar src={avatar}>{initials(user?.full_name)}</Avatar>,
              },
              { dataIndex: 'full_name' },
            ]}
            rowSelection={{
              selectedRowKeys: selectedUsers,
              onChange: (selectedRowKeys) => setSelectedUsers(selectedRowKeys),
            }}
            rowKey={(user) => user.id}
            size="small"
            showHeader={false}
            bordered={false}
            pagination={false}
          />
          <Button type="primary" onClick={handleClickShareToUsers} style={{ marginTop: 12, width: '100%' }}>
            SHARE
          </Button>
        </Block>
      )}
    </ErrorBoundary>
  );
};

const DashboardShareDrawer = ({ open, onClose, onBack, dashboard }) => (
  <Drawer
    width={470}
    open={open}
    getContainer="#dashboard"
    style={{ position: 'absolute', zIndex: 51 }}
    bodyStyle={{ padding: 0 }}
    onClose={onClose}
    destroyOnClose
    title={`Share ${dashboard?.name}`}
    closable
    closeIcon={<WindowCloseIcon />}
    extra={<ArrowLeftIcon onClick={onBack} />}
    mask={false}
    push={false}
    autoFocus={false}
  >
    <DashboardShareContent dashboard={dashboard} onClose={onBack} />
  </Drawer>
);

export default DashboardShareDrawer;
