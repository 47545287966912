import React from 'react';
import { Link } from '../../../util/navigate';
import { useProgramManager } from '../../../contexts/programManager.context';
import logoNorbr from '../../../assets/images/logo/norbr-white.svg';
import logoN from '../../../assets/images/logo/n-white.svg';
import { useLayoutMenu } from '../../layout.context';
import styles from './TopLogo.module.scss';

const TopLogo = () => {
  const programManager = useProgramManager();
  const [isMenuCollapsed] = useLayoutMenu();
  return (
    <Link className={styles.root} to="/home">
      {isMenuCollapsed ? (
        <img src={programManager ? programManager?.theme?.icon : logoN} alt="company-icon1" />
      ) : (
        <img src={programManager ? programManager?.theme?.logo : logoNorbr} alt="logo" />
      )}
    </Link>
  );
};

export default TopLogo;
