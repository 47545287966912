import React, {useState} from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockFilled, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {initializeApp} from 'firebase/app';
import {getAuth, SAMLAuthProvider, signInWithPopup} from 'firebase/auth';
import {useLazyQuery, useMutation} from '@apollo/client';
import styles from '../../AppUnlogged.module.scss';
import { SIGN_IN, SSO_SIGN_IN, getIsSsoQuery } from './query';
import { useToken } from '../../../../contexts/app.context';
import useNavigateWithSearch from '../../../../util/navigate';

const LoginForm = ({ redirectAfter }) => {
  const [, setToken] = useToken();
  const navigate = useNavigateWithSearch();

  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  }, 'login');

  const auth = getAuth(app);

  const [emailChecked, setEmailChecked] = useState(false);

  const [getIsSso, { loading: loadingGetIsSso }] = useLazyQuery(getIsSsoQuery);

  const [signIn, { loading, error }] = useMutation(SIGN_IN);

  const [ssoSignIn, { loading: ssoLoading, error: ssoError }] = useMutation(SSO_SIGN_IN);

  const onFinish = ({ email, password }) => {
    if (!emailChecked) {
      getIsSso({
        variables: {
          email,
        }
      }).then(({ data: { getIsSso: ssoConfig } }) => {
        if (ssoConfig.tenantId) {
          auth.tenantId = ssoConfig.tenantId;
          const provider = new SAMLAuthProvider(ssoConfig.providerId);
          auth.useDeviceLanguage();
          signInWithPopup(auth, provider).then(value => ssoSignIn({
            variables: {
              token: value.user.accessToken,
              tenantId: ssoConfig.tenantId,
            }
          }).then(({ data }) => {
            setToken(data.ssoSignIn);
            navigate(redirectAfter || `/brainpower/dashboard`);
          }).catch((e) => console.error(e)))
        } else {
          setEmailChecked(true)
        }
      })
    } else {
      signIn({ variables: { email: email.toLowerCase(), password } })
        .then(({ data }) => {
          setToken(data.signIn);
          navigate(redirectAfter || `/brainpower/dashboard`);
        })
        // eslint-disable-next-line no-console
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <Form name="normal_login" className={styles.form} initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[{ required: true, message: '* required' }]}
        validateStatus={!!error && 'error'}
      >
        <Input prefix={<UserOutlined />} placeholder="Email" type="email" />
      </Form.Item>
      {emailChecked ? (
        <>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '* required' }]}
            validateStatus={!!error && 'error'}
          >
            <Input.Password prefix={<LockFilled />} type="password" placeholder="Password" />
          </Form.Item>

          <Link id="forgot-password" className={styles.forgotPassword} to="/recover-password">
            Forgot password ?
          </Link>
        </>
      ) : (
        <div className={styles.placeHolder} />
      )}

      {(!!error || !!ssoError) && (
        <Typography.Text id="error-message" className={styles.error} type="danger">
          {(error ?? ssoError).message}
        </Typography.Text>
      )}
      {emailChecked ? (
        <Form.Item>
          <Button id="sign-in" type="primary" htmlType="submit" className={styles.submitBtn} loading={loading}>
            Sign in
          </Button>
        </Form.Item>
      ) : (
        <Form.Item>
          <Button
            id="continue"
            type="primary"
            className={styles.submitBtn}
            loading={loadingGetIsSso || ssoLoading}
            htmlType="submit"
          >
            Continue
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default LoginForm;