import React, { useMemo } from 'react';
import { Row } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import moment from 'moment-timezone';
import Kpi from '../Kpi/Kpi';
import KpiSkeleton from '../Kpi/KpiSkeleton';
import { brainpowerQuery } from '../Common/query';
import useSearchFilters from '../../../hooks/useSearchFilters';
import { DATETIME2_FORMAT } from '../../../../../../constants/DATES';
import { getPreviousDateRange, periodTypeToCustom } from '../../../../../../util/date';
import useBaseCurrency from '../../../hooks/useBaseCurrency';

const KpiGroup = () => {
  const [baseCurrency] = useBaseCurrency();

  const { dateFilter, list } = useSearchFilters();

  const parameters = {
    filters: list.map((filter) => {
      if (filter.key === dateFilter.key) {
        return {
          dates: filter.value.period
            ? periodTypeToCustom(filter.value.period)
            : {
              from: moment(filter.value.from, DATETIME2_FORMAT).startOf('minute'),
              to: moment(filter.value.to, DATETIME2_FORMAT).endOf('minute'),
              ...getPreviousDateRange(
                moment(filter.value.from, DATETIME2_FORMAT),
                moment(filter.value.to, DATETIME2_FORMAT),
              ),
            },
        };
      }
      return {
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      };
    }),
    indicators: [],
    dimensions: [],
    currency: baseCurrency,
  };

  const { data, loading, error } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: 'kpis',
      parameters,
    },
  });

  const kpis = useMemo(() => {
    if (data?.brainpower?.data) {
      const kpi = data.brainpower.data.reduce(
        (acc, curr) => ({
          ...acc,
          [Object.keys(curr)[0]]: Object.values(curr)[0],
        }),
        {},
      );
      return [
        {
          label: 'authorized_turnover',
          turnover: kpi.authorized_turnover,
          orders: kpi.authorized_orders,
          variation: kpi.authorized_turnover_variation,
          icon: 'coins-outline-edited',
        },
        {
          label: 'success_rate',
          rate: kpi.success_rate,
          variation: kpi.success_rate_variation,
          icon: 'like-thumb-up-outline-edited',
        },
        {
          label: 'captured_turnover',
          turnover: kpi.captured_turnover,
          orders: kpi.captured_orders,
          variation: kpi.captured_turnover_variation,
          icon: 'money-bag-euro-outline-edited',
        },
        {
          label: 'average_basket',
          turnover: kpi.average_basket,
          variation: kpi.average_basket_variation,
          icon: 'basket-trolley-shopping-card-outline-edited',
        },
        {
          label: 'refunded_amount',
          amount: kpi.refunded_amount,
          orders: kpi.refunded_orders,
          variation: kpi.refunded_amount_variation,
          icon: 'complaint-outline-edited',
        },
        {
          label: 'chargeback_amount',
          amount: kpi.chargeback_amount,
          orders: kpi.chargeback_orders,
          variation: kpi.chargeback_amount_variation,
          icon: 'thumb-down-outline-edited',
        },
      ];
    }
    return [
      {
        label: 'authorized_turnover',
        icon: 'coins-outline-edited',
      },
      {
        label: 'success_rate',
        icon: 'like-thumb-up-outline-edited',
      },
      {
        label: 'captured_turnover',
        icon: 'money-bag-euro-outline-edited',
      },
      {
        label: 'average_basket',
        icon: 'basket-trolley-shopping-card-outline-edited',
      },
      {
        label: 'refunded_amount',
        icon: 'complaint-outline-edited',
      },
      {
        label: 'chargeback_amount',
        icon: 'thumb-down-outline-edited',
      },
    ];
  }, [data]);

  return (
    <Row gutter={24}>
      {loading && kpis.map((v) => <KpiSkeleton key={v.label} />)}
      {error && kpis.map((v) => <KpiSkeleton key={v.label} />)}
      {!loading && !error && kpis.map((props) => <Kpi currency={baseCurrency} key={props.label} {...props} />)}
    </Row>
  );
};

export default KpiGroup;
