import { gql } from '@apollo/client';

export const activityMatcherQuery = gql`
  query ActivityMatcher($orderId: ID!) {
    matcherActivity(id: $orderId) {
      _id
      id
      order_merchant_id
      amount
      currency
      is_reconciled
      reconciled_amount
      unreconciled_amount
      createdAt
      order_id
      transactions {
        id
        is_reconciled
        reconciled_amount
        unreconciled_amount
        order_id
        psp_transaction_id
        payment_method_name
        payin_partner_api_name
        createdAt
        amount
        currency
        cashier_partner_api_name
        pos_file_id
        pos_file_name
        pos_file_date
        reconciliationType
        reconciliationDate
        reconciliationAuthor {
          id
          full_name
          email
          avatar
        }
        reconciliationReason
        withdrawReason
        reconciliationNote
        merchant_terminal_id
        is_withdraw
      }
      merchant_account {
        id
        name
      }
    }
  }
`;

export const reconciliationCandidatesQuery = gql`
  query ReconciliationCandidates($transactionId: ID!, $offset: Int, $limit: Int) {
    reconciliationCandidates(id: $transactionId, offset: $offset, limit: $limit) {
      psp_transaction_id
      action_date
      paymentMethod {
        api_name
      }
      amount
      currency {
        id
      }
      partner {
        api_name
      }
      merchant_account {
        id
      }
      score
    }
  }
`;

export const reconcileMutation = gql`
  mutation ReconcileMutation(
    $transactionId: ID!
    $pspTransactionId: ID
    $reason: ReconciliationReason
    $note: String,
    $type: ReconciliationType!
  ) {
    reconcile(transactionId: $transactionId, pspTransactionId: $pspTransactionId, reason: $reason, note: $note, type: $type)
  }
`;
