import React, { useContext } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isModeMerchant } from '../constants/mode';
import { useCompany } from './app.context';
import AppLoading from '../AppLoading';

const userMeQuery = gql`
  query UserMe {
    me {
      type
      status
      id
      full_name
      first_name
      last_name
      avatar
      email
      theme {
        primaryColor
        secondaryColor
      }
      companies {
        isGdpr
        aclProfile {
          id
          name
          roles {
            name
            privileges {
              id
              action
              subject
            }
          }
        }
        company {
          id
          name
          useCheckout
          theme {
            favicon
            logo
            banner
            icon
            primaryColor
            secondaryColor
          }
          ... on MerchantCompany {
            merchantAccountCount
          }
        }
        ... on MerchantCompanyUser {
          authorizedMerchantAccounts {
            id
            name
          }
        }
        createdAt
      }
      ... on InternalUser {
        aclProfile {
          id
          name
          roles {
            name
            privileges {
              id
              action
              subject
            }
          }
        }
        isGdpr
      }
      ... on ProgramManagerUser {
        aclProfile {
          id
          name
          roles {
            name
            privileges {
              id
              action
              subject
            }
          }
        }
        isGdpr
      }
      baseCurrency
      baseGranularity
      lastConnection
      previousConnection
      notePad {
        title
        content
        createdAt
      }
      brainpower {
        dashboard {
          favorite
          dashboards {
            id
            name
            layout
            widgets
            createdBy {
              id
              full_name
              avatar
            }
          }
        }
        orderList {
          views
          favorite
        }
        transactionList {
          views
          favorite
        }
        matcher {
          views
          favorite
        }
        matcherCashierSummary {
          views
          favorite
        }
        matcherCashierDetails {
          views
          favorite
        }
        matcherPaymentPartner {
          views
          favorite
        }
        overview {
          views
          favorite
        }
        financialOperationList {
          views
          favorite
        }
        checkoutList {
          views
          favorite
        }
        notificationList {
          views
          favorite
        }
        activitySearch {
          searches
          favorite
        }
        notificationSearch {
          searches
          favorite
        }
        notificationDetail {
          layout
        }
        logList {
          views
          favorite
        }
        logSearch {
          searches
          favorite
        }
        merchantAccountList {
          views
          favorite
        }
        merchantAccountSearch {
          searches
          favorite
        }
        merchantCompanyList {
          views
          favorite
        }
        merchantCompanySearch {
          searches
          favorite
        }
        merchantUserList {
          views
          favorite
        }
        merchantUserSearch {
          searches
          favorite
        }
        programManagerUserList {
          views
          favorite
        }
        programManagerUserSearch {
          searches
          favorite
        }
        internalUserList {
          views
          favorite
        }
        internalUserSearch {
          searches
          favorite
        }
      }
      sftpConnections {
        _id
        host
        port
        dirname
        username
        password
        sshAlgorithm
      }
      tourMessages
    }
  }
`;

// default filters values are set here
export const MeContext = React.createContext(undefined);

export const useMe = () => useContext(MeContext);
export const useGdpr = () => {
  const me = useMe();
  return isModeMerchant ? me.companies[0]?.isGdpr : me.isGdpr;
};

const TourMessagesMutation = gql`
  mutation TourMessagesMutation($input: UserUpdateInput!) {
    updateMe(input: $input) {
      __typename
      id
      tourMessages
    }
  }
`;

export const useTourMessage = (key) => {
  const me = useMe();
  const [update] = useMutation(TourMessagesMutation);
  const set = () => update({ variables: { input: { tourMessages: [...me.tourMessages, key] } } });
  const unset = () => update({ variables: { input: { tourMessages: me.tourMessages.filter((k) => k !== key) } } });
  return [!me.tourMessages?.includes(key), set, unset];
};

const MeProvider = (props) => {
  const { children } = props;
  const [, setCompany] = useCompany();

  const { data } = useQuery(userMeQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res && isModeMerchant) {
        setCompany(res?.me.companies[0]?.company.id);
      }
    },
  });

  if (!data) return <AppLoading />;

  return <MeContext.Provider value={data?.me}>{children}</MeContext.Provider>;
};

export default MeProvider;
