const AmountFormats = {
  FR_CURRENCY: 'fr-currency',
  FR: 'fr',
  US_CURRENCY: 'us-currency',
  US: 'us',
};

export const amountFormats = {
  [AmountFormats.FR_CURRENCY]: {
    value: AmountFormats.FR_CURRENCY,
    label: '12 345,67 €',
    locale: 'fr-FR',
    currency: true,
  },
  [AmountFormats.FR]: { value: AmountFormats.FR, label: '12 345,67', locale: 'fr-FR', currency: false },
  [AmountFormats.US_CURRENCY]: {
    value: AmountFormats.US_CURRENCY,
    label: '€12,345.67',
    locale: 'en-US',
    currency: true,
  },
  [AmountFormats.US]: { value: AmountFormats.US, label: '12,345.67', locale: 'en-US', currency: false },
};

export const amountFormatList = Object.values(amountFormats);

export default AmountFormats;
