import React from 'react';
import { useQuery } from '@apollo/client';
import { rulesNameQuery } from './query';
import {Link} from '../../../../../../util/navigate';

const UpdateRuleOrder = ({log}) => {
  const {data, loading} = useQuery(rulesNameQuery, { variables: { id: log.log_merchant_account_id }});

  return (
    <>
      <div>
        Rules order changed for merchantAccount: <Link to={`/router/payment-network?maccount=${log.log_entity_id}&pn=routingMatrix`}>{log.log_entity_id}</Link>
      </div>
      <div>
        Previous sort:
        <br/>
        {loading ? (
          <div>loading</div>
        ) : log.log_update.old
          .filter(ruleId => data.merchantAccount.rules?.find(ruleElem => ruleElem._id === ruleId))
          .map((ruleId) => (
            <Link to={`/router/payment-network/rule/${ruleId}`}>
              {data.merchantAccount.rules?.find(ruleElem => ruleElem._id === ruleId)?.name ?? 'Not named rule'}
            </Link>
          )).reduce((prev, curr) => [prev, ', ', curr])
        }
      </div>
      <div>
        New sort:
        <br/>
        {loading ? (
            <div>loading</div>
          ) : log.log_update.new
          .filter(ruleId => data.merchantAccount.rules?.find(ruleElem => ruleElem._id === ruleId))
          .map((ruleId) => (
            <Link to={`/router/payment-network/rule/${ruleId}`}>
              {data.merchantAccount.rules?.find(ruleElem => ruleElem._id === ruleId)?.name ?? 'Not named rule'}
            </Link>
          )).reduce((prev, curr) => [prev, ', ', curr])
        }
      </div>
    </>
  );
};

export default UpdateRuleOrder;