import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Layout,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import ReactHeight from 'react-height';
import classnames from 'classnames';
import FeatureSearchIcon from '@2fd/ant-design-icons/lib/FeatureSearch';
import { FunctionOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import RECONCILIATION_TYPES from 'norbr-shared-lib/constants/transaction/reconciliationTypes';
import RECONCILIATION_REASONS from 'norbr-shared-lib/constants/transaction/reconciliationReasons';
import MATCH_LATER_REASONS from 'norbr-shared-lib/constants/transaction/matchLaterReasons';
import WITHDRAW_REASONS from 'norbr-shared-lib/constants/transaction/withdrawReasons';
import PAYMENT_CHANNELS from 'norbr-shared-lib/constants/paymentChannels';
import moment from 'moment-timezone';
import BookmarkIcon from '@2fd/ant-design-icons/lib/Bookmark';
import Fields from 'norbr-shared-lib/constants/order/fields/enum';
import OpenInNewIcon from '@2fd/ant-design-icons/lib/OpenInNew';
import ChevronDownIcon from '@2fd/ant-design-icons/lib/ChevronDown';
import ChevronRightIcon from '@2fd/ant-design-icons/lib/ChevronRight';
import { useGdpr } from '../../../../../../../contexts/me.context';
import useDrawer from '../../../../Common/useDrawer';
import Drawers from '../../../../Common/constants/drawers';
import usePagination from '../../../../hooks/usePagination';
import SearchFilterTags from '../../SearchFilterTags/SearchFilterTags';
import { buildColumnProps } from '../../../../Common/AdvancedTable/buildColumns';
import useSort from '../../../../hooks/useSort';
import { fieldTypes } from '../../../../Common/constants/fields';
import { closeRegisterMutation, registerDetailsQuery, registersQuery } from '../../query';
import { MatcherCashierFields, MatcherCashierFieldsEnum } from '../../fields';
import { amountFormatter } from '../../../../../../../util/formatter';
import Loader from '../../../../../Common/Loader/Loader';
import usePage from '../../../../hooks/usePage';
import useNavigateWithSearch from '../../../../../../../util/navigate';
import { encodeFilter } from '../../../../hooks/useSearchFilters';
import { DATE_FORMAT, DATETIME2_FORMAT } from '../../../../../../../constants/DATES';
import CloseRegisterModal from './CloseRegisterModal/CloseRegisterModal';
import { iconByChannel } from '../../../../../../../constants/PAYMENT_CHANNELS';
import useColumnSet from '../../../../hooks/useColumnSet';
import useTableDisplay from '../../../../hooks/useTableDisplay';
import { list as indicatorList } from '../../../../Dashboard/constants/indicators';
import { TableDisplay } from '../../../../Common/constants/tableDisplays';
import styles from './MatcherCashierTable.module.scss';
import { useCan } from '../../../../../../../contexts/ability.context';
import useModal from 'antd/lib/modal/useModal';
import {useCompany} from '../../../../../../../contexts/app.context';
import { merchantAccountsQuery } from '../../SearchDrawer/query';

/**
 * Build AntD Table columns from columnSet and context
 */
const useColumns = () => {
  const page = usePage();
  const [columnSet] = useColumnSet();
  const [tableDisplay] = useTableDisplay();
  const isGdpr = useGdpr();
  const can = useCan();

  return columnSet
    .filter((c) => page.fields[c.field] && !page.fields[c.field]?.isForbidden?.(can))
    .map((c, index) => {
      const field = {
        ...page.fields[c.field],
        ...fieldTypes[page.fields[c.field].type],
      };

      return {
        key: c.field,
        dataIndex: c.field,
        title: indicatorList.includes(c.field) ? (
          <Space>
            <FunctionOutlined />
            {c.label}
          </Space>
        ) : (
          c.label
        ),
        format: c.format,
        ellipsis: true,
        width: Math.max(c.label.length) * 14 + 30,
        sorter: undefined,
        sortOrder: undefined,
        sortDirections: undefined,
        showSorterTooltip: false,
        fixed: index === 0 ? 'left' : false,
        ...buildColumnProps(field, c, isGdpr, tableDisplay === TableDisplay.TEXT),
      };
    });
};

const renderOpenIn = (_, row, can) => {
  const date = moment(
    row[MatcherCashierFieldsEnum.MATCHER_ORDER_DATE] ?? row.parent[MatcherCashierFieldsEnum.MATCHER_ORDER_DATE],
    'YYYY-MM-DD',
  );
  const notificationDate = moment(row[MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE], 'YYYY-MM-DD');
  return (
    <Button
      icon={<OpenInNewIcon />}
      shape="circle"
      type="link"
      title="Open in Matcher engine"
      style={{ backgroundColor: '#7f7f7f', color: 'white' }}
      onClick={() => {
        // TODO - temp checkout from overview -> redirect to new tab on order list, with filter on checkout_id and dateFilter = checkout_creation_date 1 day
        const queryString = new URLSearchParams({
          activityFilters: encodeFilter({
            dateFilter: {
              key: Fields.ORDER_CREATION_DATE,
              value: {
                from: date.startOf('day').format(DATETIME2_FORMAT),
                to: date.endOf('day').format(DATETIME2_FORMAT),
              },
            },
            searchFilters: {
              [Fields.MERCHANT_ACCOUNT]: {
                value: [
                  row[MatcherCashierFieldsEnum.MATCHER_STORE] ?? row.parent[MatcherCashierFieldsEnum.MATCHER_STORE],
                ],
                excluded: false,
              },
              ...(row.__typename === 'RegisterDetails'
                ? {
                    ...{
                      [Fields.RECONCILIATION_TYPE]: {
                        value: [row[MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE] ?? '__NULL__'],
                        excluded: false,
                      },
                    },
                    ...(row[MatcherCashierFieldsEnum.MATCHER_NOTIFICATION_DATE]
                      ? {
                          [Fields.PSP_TRANSACTION_DATE]: {
                            value: {
                              from: notificationDate.startOf('day').format(DATETIME2_FORMAT),
                              to: notificationDate.endOf('day').format(DATETIME2_FORMAT),
                            },
                          },
                        }
                      : {
                          [Fields.PSP_TRANSACTION_DATE]: {
                            value: '__NULL__',
                          },
                        }),
                    ...(row[MatcherCashierFieldsEnum.MATCHER_REASON]
                      ? {
                          [Fields.RECONCILIATION_REASON]: {
                            value: [row[MatcherCashierFieldsEnum.MATCHER_REASON]],
                            excluded: false,
                          },
                        }
                      : {}),
                    ...(!can('read', 'matcher-withdraw') ? {} : row[MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON]
                      ? {
                          [Fields.WITHDRAW_REASON]: {
                            value: [row[MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON]],
                            excluded: false,
                          },
                        }
                      : {
                        [Fields.WITHDRAW_REASON]: {
                          value: ['__NULL__'],
                          excluded: false,
                        },
                      }),
                  }
                : {}),
            },
          }),
        }).toString();
        window.open(`${window.location.origin}/brainpower/matcher-engine?${queryString}`, '_blank');
      }}
    />
  );
};

const MatcherCashierSummaryTable = (props) => {
  const { searchFilters, onSearch } = props;
  const currentPage = usePage();
  const navigate = useNavigateWithSearch();
  const can = useCan();

  const { data } = useQuery(merchantAccountsQuery);

  const [, setDrawer] = useDrawer();

  const [modal, contextHolder] = useModal();

  const [height, setHeight] = useState();

  const [tableData, setTableData] = useState([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [toCloseRegister, setToCloseRegister] = useState();

  const { page, pageSize, setPage } = usePagination();
  const [sort, setSort] = useSort();

  const searchFilterList = useMemo(
    () => Object.keys(searchFilters).reduce((memo, key) => [...memo, { key, value: searchFilters[key] }], []),
    [searchFilters],
  );

  const {
    data: {
      registers: { data: registersData, total: registersTotal } = {
        data: [],
        total: 0,
      },
    } = {
      registers: {},
      matcherOverview: {},
    },
    loading,
    error,
    refetch,
  } = useQuery(registersQuery, {
    variables: {
      filters: searchFilterList
        .filter(
          (searchFilter) =>
            searchFilter.value &&
            MatcherCashierFields[searchFilter.key]?.filterOptions?.pages?.includes(currentPage.name),
        )
        .map((searchFilter) => {
          if (MatcherCashierFields[searchFilter.key].type === 'date') {
            return {
              key: searchFilter.key,
              value: [moment(searchFilter.value[0], DATETIME2_FORMAT), moment(searchFilter.value[1], DATETIME2_FORMAT)],
            };
          }
          return searchFilter;
        }),
      sort,
      limit: pageSize,
      offset: pageSize * (page - 1),
    },
  });

  const [getRegisterDetails, { loading: loadingDetails }] = useLazyQuery(registerDetailsQuery, {
    variables: {
      sort,
    },
    onCompleted: (data) =>
      setTableData(
        tableData.map((row) => {
          if (
            row._id.matcher_order_date === data.register._id.matcher_order_date &&
            row._id.merchantAccount === data.register._id.merchantAccount
          ) {
            return {
              ...row,
              details: data.register.details,
            };
          }
          return row;
        }),
      ),
  });

  const [closeRegister, { loading: loadingClose }] = useMutation(closeRegisterMutation);

  useEffect(() => {
    if (registersData) {
      setExpandedRowKeys([]);
      setTableData(registersData);
    }
  }, [registersData]);

  const columns = useColumns();

  const rowColors = (row, isRatio = false) => {
    let color;
    let background;
    if (row.matcher_unmatched_transactions === 0) {
      // Green row shown differently on registerRow and registerDetailsRow
      if (row.__typename === 'RegisterDetails' || !isRatio) {
        color = '#55C985';
        background = 'rgba(85, 201, 133, .1)';
      } else {
        color = '#FFFFFF';
        background = 'rgba(85, 201, 133, 1)';
      }
    } else if (row.matcher_unclosable_transactions === 0 || row.matcher_reconciliation_type === 'pending') {
      // Orange row
      color = '#FA9502';
      background = 'rgba(250, 149, 2, .1)';
    } else {
      // Red row
      color = '#FF4F4F';
      background = 'rgba(255, 79, 79, .1)';
    }
    return {
      background,
      color,
    };
  };

  const customColumns = columns.map((c) => {
    switch (c.key) {
      case MatcherCashierFieldsEnum.MATCHER_MATCHED_TRANSACTIONS:
        return {
          ...c,
          render: (_, row) => {
            const { color, background: backgroundColor } = rowColors(row, true);
            return (
              <span
                style={{
                  color,
                  backgroundColor,
                  borderRadius: 4,
                  padding: 4,
                }}
              >
                <Space>
                  <BookmarkIcon />
                  {row.matcher_matched_transactions} / {row.matcher_total_transactions}
                </Space>
              </span>
            );
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_AMOUNT_MATCHED:
        return {
          ...c,
          render: (_, row) => {
            const { color, background: backgroundColor } = rowColors(row, true);
            return (
              <span
                style={{
                  color,
                  backgroundColor,
                  borderRadius: 6,
                  padding: 4,
                }}
              >
                <Space>
                  <BookmarkIcon />
                  {amountFormatter(row.matcher_matched_amount, row.currency, c.format)} /{' '}
                  {amountFormatter(row.matcher_total_amount, row.currency, c.format)}
                </Space>
              </span>
            );
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_RECONCILIATION_TYPE:
        return {
          ...c,
          render: (value) => RECONCILIATION_TYPES[value]?.label,
        };
      case MatcherCashierFieldsEnum.MATCHER_REASON:
      case MatcherCashierFieldsEnum.MATCHER_WITHDRAW_REASON:
        return {
          ...c,
          render: (value) =>
            ({
              ...MATCH_LATER_REASONS,
              ...RECONCILIATION_REASONS,
              ...WITHDRAW_REASONS,
            })[value]?.label,
        };
      case MatcherCashierFieldsEnum.MATCHER_PAYMENT_CHANNEL:
        return {
          ...c,
          render: (value) => {
            const Icon = iconByChannel[value];
            return (
              <Space>
                {Icon && (
                  <span>
                    <Icon />
                  </span>
                )}
                <span>{PAYMENT_CHANNELS[value]?.label}</span>
              </Space>
            );
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_UNMATCHED_AMOUNT:
        return {
          ...c,
          render: (value, row) => {
            const { color } = rowColors(row);
            return <span style={{ color }}>{amountFormatter(value, row.currency)}</span>;
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_AMOUNT_GAP:
        return {
          ...c,
          render: (value, row) => {
            const { color } = rowColors(row);
            return <span style={{ color }}>{amountFormatter(value, row.currency)}</span>;
          },
        };
      case MatcherCashierFieldsEnum.MATCHER_UNMATCHED_TRANSACTIONS:
        return {
          ...c,
          render: (value, row) => {
            const { color } = rowColors(row);
            return <span style={{ color }}>{value}</span>;
          },
        };
      default:
        return c;
    }
  });

  const handleTableChange = (_p, _f, sorter) => {
    // handle single sort, default from favorite view
    if (Array.isArray(sorter)) {
      setSort([{ field: sorter[1].field, sortOrder: sorter[1].order }]);
    } else if (sorter.order) {
      setSort([{ field: sorter.field, sortOrder: sorter.order }]);
    } else {
      setSort([]);
    }
  };

  const total = registersTotal;

  return (
    <>
      <Layout>
        <Layout.Header className={styles.layoutHeader}>
          <Row gutter={24} style={{ position: 'relative' }}>
            <Col>
              <Space>
                Results
                <Tag style={{ color: 'darkgrey' }}>{total}</Tag>
              </Space>
            </Col>
            <Col flex="1" style={{ position: 'initial' }}>
              <SearchFilterTags list={searchFilterList} onSearch={onSearch} />
            </Col>
            <Col>
              <Radio.Group
                defaultValue="summary"
                buttonStyle="solid"
                className={styles.radio}
                value={currentPage.path}
                onChange={(e) => navigate(e.target.value)}
              >
                <Radio.Button value="/brainpower/matcher-cashier-summary">Summary</Radio.Button>
                <Radio.Button value="/brainpower/matcher-cashier-details">Details</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Layout.Header>
        <Layout
          id="matcher-cashier-summary-table"
          className={styles.layoutContent}
          style={{ backgroundColor: 'white', boxShadow: '1px 1px 4px #d0d0d0', zIndex: 20 }}
        >
          <Layout.Content>
            <ReactHeight onHeightReady={setHeight} style={{ height: '100%' }}>
              {height ? (
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty
                      className={styles.empty}
                      style={{ height: height - 74 }}
                      image={loading ? null : undefined}
                      description={(() => {
                        if (loading) {
                          return null;
                        }
                        if (error) {
                          return (
                            <Typography.Text type="danger">An error occurred, please contact support.</Typography.Text>
                          );
                        }
                        return [
                          <div key="no-results-found">No results found</div>,
                          <Button
                            key="adjust-filters"
                            type="link"
                            icon={<FeatureSearchIcon style={{ fontSize: 18 }} />}
                            onClick={() => setDrawer(Drawers.SEARCH)}
                          >
                            Adjust filters
                          </Button>,
                        ];
                      })()}
                    />
                  )}
                >
                  <Table
                    className={classnames(styles.table)}
                    rowClassName={styles.tableRow}
                    columns={[
                      {
                        key: 'action',
                        title: 'ACTION',
                        align: 'center',
                        fixed: true,
                        render: (row) => {
                          if (row.__typename === 'Register') {
                            switch (row.matcher_register_status) {
                              case 'matched_warning':
                              case 'matched_success':
                                return (
                                  <>
                                    <Button
                                      onClick={() => {
                                        modal.confirm({
                                          title: `Are you sure you want to close the register?`,
                                          content: `You are about to close the register for the store ${data?.merchantAccounts?.find(ma => row.matcher_store === ma.id)?.name} on ${row._id.matcher_order_date}`,
                                          okText: 'CLOSE REGISTER',
                                          cancelText: 'CANCEL',
                                          onOk: () => {
                                            closeRegister({
                                              variables: {
                                                merchantAccount: row._id.merchantAccount,
                                                date: row._id.matcher_order_date,
                                              },
                                            }).then(() => {
                                              refetch();
                                              setToCloseRegister(row);
                                            });
                                          },
                                          width: 500,
                                        })
                                      }}
                                      loading={loadingClose}
                                      disabled={loadingClose}
                                      block
                                      type="primary"
                                      style={{
                                        borderRadius: 6,
                                        border: 'none',
                                        backgroundColor: 'var(--ant-primary-color)',
                                        color: 'var(--primaryColorContrast)',
                                      }}
                                    >
                                      CLOSE REGISTER
                                    </Button>
                                  </>
                                );
                              case 'closed_warning':
                              case 'closed_success':
                                return (
                                  <Button
                                    style={{
                                      borderRadius: 6,
                                      border: 'none',
                                      color: 'var(--ant-primary-color)',
                                      backgroundColor: 'var(--ant-primary-color-active-deprecated-d-02)',
                                    }}
                                    block
                                    disabled
                                    type="primary"
                                  >
                                    CLOSED
                                  </Button>
                                );
                              case 'pending':
                              default:
                                return (
                                  <Button
                                    style={{
                                      borderRadius: 6,
                                      border: 'none',
                                      color: 'white',
                                      backgroundColor: '#e7e7e7',
                                    }}
                                    block
                                    type="primary"
                                    disabled
                                  >
                                    CLOSE REGISTER
                                  </Button>
                                );
                            }
                          }
                          return null;
                        },
                      },
                      {
                        key: 'openIn',
                        title: 'OPEN IN',
                        align: 'center',
                        width: 80,
                        fixed: true,
                        render: (_, row) => renderOpenIn(_, row, can),
                      },
                      ...customColumns,
                    ]}
                    dataSource={tableData.map((row, index) => ({
                      ...row,
                      key: `${row._id.matcher_order_date}-${row._id.merchantAccount}-${index}`,
                      children:
                        row.details?.map((details) => ({
                          ...details,
                          parent: row,
                        })) ?? false,
                    }))}
                    loading={loading && { size: 'large' }}
                    size="small"
                    scroll={{ x: 'max-content', y: height - 96 }} // available height - head row height
                    pagination={false}
                    onChange={handleTableChange}
                    expandable={{
                      expandedRowClassName: (row) =>
                        classnames(styles.loadingRow, {
                          [styles.isLoading]: !row.children.length && loadingDetails,
                        }),
                      expandedRowRender: () => <Loader />,
                      rowExpandable: (record) => record.__typename === 'Register',
                      onExpand: (expanded, record) => {
                        if (expanded) {
                          setExpandedRowKeys([...expandedRowKeys, record.key]);
                          getRegisterDetails({
                            variables: {
                              date: record._id.matcher_order_date,
                              merchantAccount: record._id.merchantAccount,
                            },
                          });
                        } else {
                          setExpandedRowKeys(expandedRowKeys.filter((key) => record.key !== key));
                        }
                      },
                      expandedRowKeys,
                      fixed: 'left',
                      expandIcon: ({ expanded, onExpand, record }) => {
                        if (record.__typename !== 'Register') return null;
                        return expanded ? (
                          <ChevronDownIcon
                            style={{ fontSize: 24, color: '#7f7f7f' }}
                            onClick={(e) => onExpand(record, e)}
                          />
                        ) : (
                          <ChevronRightIcon
                            style={{ fontSize: 24, color: '#7f7f7f' }}
                            onClick={(e) => onExpand(record, e)}
                          />
                        );
                      },
                    }}
                  />
                </ConfigProvider>
              ) : (
                <div />
              )}
            </ReactHeight>
          </Layout.Content>
          <Layout.Footer className={styles.layoutFooter}>
            <Row gutter={24} justify="space-between">
              <Col>
                <Space>
                  <Pagination
                    className={styles.pagination}
                    total={total}
                    current={page}
                    pageSize={pageSize}
                    onChange={setPage}
                    showSizeChanger={false}
                    showLessItems
                    size="small"
                  />
                  <Select
                    value={pageSize}
                    onSelect={(value) => setPage(1, value)}
                    options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
                    bordered={false}
                  />
                </Space>
              </Col>
            </Row>
          </Layout.Footer>
        </Layout>
      </Layout>
      {toCloseRegister && (
        <CloseRegisterModal
          visible={toCloseRegister}
          onClose={() => setToCloseRegister()}
          loading={loadingClose}
          register={toCloseRegister}
        />
      )}
      {contextHolder}
    </>
  );
};

export default MatcherCashierSummaryTable;
